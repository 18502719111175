import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { 
    FormGroup,
    FormControl,
    ControlLabel
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import "./CreaTipoEvaluacion.css";

export default function CreaTipoEvaluacion() {
    const history = useHistory();
    const [nombre, setNombre] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return nombre.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const payload = { nombre, activo: true };
            await API.createTipoEvaluacion(payload);
            alert("Tipo de Evaluación creada exitosamente");
            history.push("/tipoEvaluacion");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }


    return (
        <div>
        <form onSubmit={handleSubmit}>
        <FormGroup controlId="nombre">
        <ControlLabel>Nombre</ControlLabel>
        <FormControl 
            autoFocus
            type="text"
            autoComplete="off"
            value={nombre}
            onChange={ (e)=> setNombre(e.target.value) }
        />
        </FormGroup>
        <LoaderButton
            block
            type="submit"
            bsSize="large"
            className="button-orange"
            isLoading={isLoading}
            disabled={!validateForm()}
        >
        Create
        </LoaderButton>
        </form>
        </div>
    );
}