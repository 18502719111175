import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, Form, Col, PageHeader, FormControl, ControlLabel } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { formatDate } from "../../libs/dateLib";
import API from "../../api";
import "./Reverso.css";


export default function Reverso() {
    const history = useHistory();
    const [ orden, setOrden ] = useState("");
    const [ fecha, setFecha ] = useState("");
    const [ transactionId, setTransactionId ] = useState("");
    const [ authorizationCode, setAuthorizationCode ] = useState("");
    const [ amount, setAmount ] = useState("");
    const [ estado, setEstado ] = useState("");
    const [ password, setPassword ] = useState("");
    const { token } = useAppContext();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);
    
    function validateForm() {
        return (orden && password);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        
        setIsLoading(true);
        try {
            const payload = { order: orden, password };
            const result = await API.refund(payload, { headers: { "Authorization": token  }});
            
            if (result.data.data.estado === "refunded") {
                alert(result.data.message);
                history.push("/");
            } else {
                alert(`${result.data.message}. Posee ${result.data.data.intentos} intentos disponibles, de ser necesario comuníquese con Soporte Técnico de Paymentez.`)
            }
        } catch(e) {
            onError(e);
            setPassword("");
        }
        setIsLoading(false);
    }

    async function handleBuscar(event) {
        event.preventDefault();

        setIsLoadingData(true);
        try {
            const payment = await API.getPaymentByOrder(orden, { headers: { "Authorization": token  }});
            setTransactionId(payment.data.data.transaction.id);
            setAuthorizationCode(payment.data.data.transaction.authorization_code);
            setAmount(`${payment.data.data.transaction.amount.toFixed(2)}`);
            setFecha(formatDate(payment.data.data.fechaCreacion));
            setEstado(payment.data.data.estado.toUpperCase())
        } catch(e) {
            onError(e);
            setTransactionId("");
            setAuthorizationCode("");
            setAmount("");
            setFecha("");
            setPassword("");
        }
        setIsLoadingData(false);
    }

    return (
        <div className="">
            <PageHeader>Devoluciones</PageHeader>
            <Form onSubmit={handleSubmit} horizontal>
            <FormGroup controlId="order">
                <Col componentClass={ControlLabel} sm={2}>
                No. de Orden
                </Col>
                <Col sm={4}>
                <FormControl
                    autoFocus
                    type="text"
                    autoComplete="off"
                    value={orden}
                    onChange={ e => setOrden(e.target.value)}
                >
                </FormControl>
                </Col>
                <Col sm={2}>
                <LoaderButton
                    block
                    onClick={handleBuscar}
                    isLoading={isLoadingData}
                >
                    Buscar transacción
                </LoaderButton>
                </Col>
            </FormGroup>

            <FormGroup controlId="transaccion">
            <Col componentClass={ControlLabel} sm={2}>
            ID de Transacción
            </Col>
            <Col sm={4}>
            <FormControl.Static>{transactionId}</FormControl.Static>
            </Col>
            {' '}
            <Col componentClass={ControlLabel} sm={2}>
            Fecha de transacción
            </Col>
            <Col sm={4}>
            <FormControl.Static>{fecha}</FormControl.Static>
            </Col>
            </FormGroup>

            <FormGroup controlId="autorizacion">
            <Col componentClass={ControlLabel} sm={2}>
            Codigo de Autorización
            </Col>
            <Col sm={4}>
            <FormControl.Static>{authorizationCode}</FormControl.Static>
            </Col>
            {' '}
            <Col componentClass={ControlLabel} sm={2}>
            Valor a devolver
            </Col>
            <Col sm={4}>
            <FormControl.Static>$ {amount}</FormControl.Static>
            </Col>
            </FormGroup>

            <FormGroup controlId="estado">
            <Col componentClass={ControlLabel} sm={2}>
            Estado de Transacción
            </Col>
            <Col sm={4}>
            <FormControl.Static>{estado}</FormControl.Static>
            </Col>
            </FormGroup>

            <FormGroup controlId="password">
                <Col componentClass={ControlLabel} sm={2}>
                Contraseña
                </Col>
                <Col sm={4}>
                <FormControl
                    type="password"
                    autoComplete="off"
                    value={password}
                    onChange={ e => setPassword(e.target.value)}
                >
                </FormControl>
                </Col>
            </FormGroup>

            <LoaderButton
                block
                bsSize="large"
                className="button-orange"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Devolver
            </LoaderButton>
            </Form>
        </div>
    )
}