import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import "./CreaNivelAcuerdo.css";

export default function CreaNivelAcuerdo() {
    const history = useHistory();
    const [ nombre, setNombre ] = useState("");
    const [ peso, setPeso ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);

    function validateForm() {
        return (nombre.length > 0 && peso >= 0);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (peso === "") {
            alert("Ingrese un valor numérico el peso");
            return;
        }

        setIsLoading(true);
        try {
            const payload = { nombre, peso, activo: true };
            const result = await API.createNivelAcuerdo(payload);
            alert(result.data.message);
            history.push("/nivelAcuerdo");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }

    return (
        <div className="NewNivelAcuerdo">
        <form onSubmit={handleSubmit}>
        <FormGroup controlId="nombre">
        <ControlLabel>Nombre</ControlLabel>
        <FormControl 
            autoFocus
            type="text"
            autoComplete="off"
            value={nombre}
            onChange={ e => setNombre(e.target.value) }
        />
        </FormGroup>
        <FormGroup controlId="peso">
        <ControlLabel>Peso</ControlLabel>
        <FormControl
            type="number"
            step="1"
            min="0"
            max="5"
            value={peso}
            onChange= { e => setPeso(e.target.value) }
        />
        </FormGroup>
        <LoaderButton 
            block
            type="submit"
            bsSize="large"
            bsStyle="primary"
            isLoading={isLoading}
            disabled={!validateForm()}
        >
            Guardar
        </LoaderButton>
        </form>
        </div>
    );
}
