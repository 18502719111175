import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { useAppContext } from "../../libs/contextLib";
import { globalVars } from "../../constants";
import API from "../../api";
import "./CreaNivelHabilidad.css";

export default function CreaNivelHabilidad() {
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [tipoHabilidad, setTipoHabilidad] = useState("");
  const [desdePuntos, setDesdePuntos] = useState(0);
  const [hastaPuntos, setHastaPuntos] = useState(0);
  const [peso, setPeso] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [tipoHabilidades, setTipoHabilidades] = useState([]);
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    async function onLoad() {
      try {
        const tipoHabilidades = await API.getTipoHabilidadByTipoEvaluacion(
          globalVars.EVALUACION_PM_ID
        );
        setTipoHabilidades(tipoHabilidades.data.data);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [isAuthenticated]);

  function validateForm() {
    return nombre.length > 0 && tipoHabilidad;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (desdePuntos === "" || hastaPuntos === "" || peso === "") {
      alert("Ingrese un valor para los campos numéricos");
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        nombre,
        descripcion,
        desdePuntos,
        hastaPuntos,
        tipoHabilidad,
        peso,
        activo: true,
      };
      await API.createNivelHabilidad(payload);
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="NewNivelHabilidad">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="tipoHabilidad">
          <ControlLabel>Tipo de Habilidad</ControlLabel>
          <FormControl
            autoFocus
            componentClass="select"
            value={tipoHabilidad}
            onChange={(e) => setTipoHabilidad(e.target.value)}
          >
            <option value="">Elija un tipo de habilidad</option>
            {tipoHabilidades.map((tipoHabilidad) => {
              return (
                <option key={tipoHabilidad._id} value={tipoHabilidad._id}>
                  {tipoHabilidad.nombre}
                </option>
              );
            })}
          </FormControl>
        </FormGroup>
        <FormGroup controlId="nombre">
          <ControlLabel>Nombre</ControlLabel>
          <FormControl
            type="text"
            autoComplete="off"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="descripcion">
          <ControlLabel>Descripcion</ControlLabel>
          <FormControl
            componentClass="textarea"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="peso">
          <ControlLabel>Peso</ControlLabel>
          <InputGroup>
            <FormControl
              type="number"
              step="1"
              min="0"
              max="5"
              value={peso}
              onChange={(e) => setPeso(e.target.value)}
            />
            <InputGroup.Addon>1 al 5</InputGroup.Addon>
          </InputGroup>
        </FormGroup>
        <FormGroup controlId="desdePuntos">
          <ControlLabel>Desde</ControlLabel>
          <InputGroup>
            <FormControl
              type="number"
              step="1"
              min="0"
              max="100"
              value={desdePuntos}
              onChange={(e) => setDesdePuntos(e.target.value)}
            />
            <InputGroup.Addon>Puntos</InputGroup.Addon>
          </InputGroup>
        </FormGroup>
        <FormGroup controlId="hastaPuntos">
          <ControlLabel>Hasta</ControlLabel>
          <InputGroup>
            <FormControl
              type="number"
              step="1"
              min="0"
              max="100"
              value={hastaPuntos}
              onChange={(e) => setHastaPuntos(e.target.value)}
            />
            <InputGroup.Addon>Puntos</InputGroup.Addon>
          </InputGroup>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          className="button-orange"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Guardar
        </LoaderButton>
      </form>
    </div>
  );
}
