import axios from "axios";
import { globalVars } from "../constants";

const baseApi = axios.create({
  baseURL: globalVars.API,
});

//Tipo de Evaluacion APIs
export const createTipoEvaluacion = (payload) =>
  baseApi.post(`/tipoEvaluacion`, payload);
export const getTipoEvaluacionActivo = () =>
  baseApi.get(`/tipoEvaluacionActivo`);
export const deleteTipoEvaluacion = (id) =>
  baseApi.delete(`/tipoEvaluacion/${id}`);
export const updateTipoEvaluacion = (id, payload) =>
  baseApi.put(`/tipoEvaluacion/${id}`, payload);
export const getTipoEvaluacionById = (id) =>
  baseApi.get(`/tipoEvaluacion/${id}`);
export const deactivateTipoEvaluacion = (id) =>
  baseApi.put(`/tipoEvaluacion/desactivar/${id}`);

//Nivel de Acuerdo APIs
export const createNivelAcuerdo = (payload) =>
  baseApi.post(`/nivelAcuerdo`, payload);
export const getNivelAcuerdoActivo = () => baseApi.get(`/nivelAcuerdoActivo`);
export const deleteNivelAcuerdo = (id) => baseApi.delete(`/nivelAcuerdo/${id}`);
export const updateNivelAcuerdo = (id, payload) =>
  baseApi.put(`nivelAcuerdo/${id}`, payload);
export const getNivelAcuerdoById = (id) => baseApi.get(`/nivelAcuerdo/${id}`);
export const deactivateNivelAcuerdo = (id) =>
  baseApi.put(`/nivelAcuerdo/desactivar/${id}`);

//Tipo de Habilidad APIs
export const createTipoHabilidad = (payload) =>
  baseApi.post(`/tipoHabilidad`, payload);
export const getTipoHabilidadActivo = () => baseApi.get(`/tipoHabilidadActivo`);
export const deleteTipoHabilidad = (id) =>
  baseApi.delete(`/tipoHabilidad/${id}`);
export const updateTipoHabilidad = (id, payload) =>
  baseApi.put(`/tipoHabilidad/${id}`, payload);
export const getTipoHabilidadById = (id) => baseApi.get(`/tipoHabilidad/${id}`);
export const deactivateTipoHabilidad = (id) =>
  baseApi.put(`/tipoHabilidad/desactivar/${id}`);
export const getTipoHabilidadByTipoEvaluacion = (id) =>
  baseApi.get(`/tipoHabilidad/tipoEvaluacion/${id}`);

//Tipo de Nivel de Madurez APIs
export const createNivelMadurez = (payload) =>
  baseApi.post(`/nivelMadurez`, payload);
export const getNivelMadurezActivo = () => baseApi.get(`/nivelMadurezActivo`);
export const deleteNivelMadurez = (id) => baseApi.delete(`/nivelMadurez/${id}`);
export const updateNivelMadurez = (id, payload) =>
  baseApi.put(`/nivelMadurez/${id}`, payload);
export const getNivelMadurezById = (id) => baseApi.get(`/nivelMadurez/${id}`);
export const deactivateNivelMadurez = (id) =>
  baseApi.put(`/nivelMadurez/desactivar/${id}`);
export const getNivelMadurezByTipoEvaluacion = (id) =>
  baseApi.get(`/nivelMadurez/tipoEvaluacion/${id}`);

//Semaforo APIs
export const createSemaforo = (payload) => baseApi.post(`/semaforo`, payload);
export const getSemaforoActivo = () => baseApi.get(`/semaforoActivo`);
export const deleteSemaforo = (id) => baseApi.delete(`/semaforo/${id}`);
export const updateSemaforo = (id, payload) =>
  baseApi.put(`/semaforo/${id}`, payload);
export const getSemaforoById = (id) => baseApi.get(`/semaforo/${id}`);
export const deactivateSemaforo = (id) =>
  baseApi.put(`/semaforo/desactivar/${id}`);
export const getSemaforoByTipoEvaluacion = (id) =>
  baseApi.get(`/semaforo/tipoEvaluacion/${id}`);

//Criterio APIs
export const createCriterio = (payload) => baseApi.post(`/criterio`, payload);
export const getCriterioActivo = () => baseApi.get(`/criterioActivo`);
export const deleteCriterio = (id) => baseApi.delete(`/criterio/${id}`);
export const updateCriterio = (id, payload) =>
  baseApi.put(`/criterio/${id}`, payload);
export const getCriterioById = (id) => baseApi.get(`/criterio/${id}`);
export const deactivateCriterio = (id) =>
  baseApi.put(`/criterio/desactivar/${id}`);
export const getCriterioByTipoHabilidad = (id) =>
  baseApi.get(`/criterio/tipoHabilidad/${id}`);
export const getCriterioAgrupado = (id) =>
  baseApi.get(`/criterioAgregado/${id}`);

//Nivel de Habilidad APIs
export const createNivelHabilidad = (payload) =>
  baseApi.post(`/nivelHabilidad`, payload);
export const getNivelHabilidadActivo = () =>
  baseApi.get(`/nivelHabilidadActivo`);
export const deteleNivelHabilidad = (id) =>
  baseApi.delete(`/nivelHabilidad/${id}`);
export const updateNivelHabilidad = (id, payload) =>
  baseApi.put(`/nivelHabilidad/${id}`, payload);
export const getNivelHabilidadById = (id) =>
  baseApi.get(`/nivelHabilidad/${id}`);
export const deactivateNivelHabilidad = (id) =>
  baseApi.put(`/nivelHabilidad/desactivar/${id}`);
export const getNivelHabilidadByTipoHabilidad = (id) =>
  baseApi.get(`/nivelHabilidad/tipoHabilidad/${id}`);

//Producto
export const createProducto = (payload) => baseApi.post(`/producto`, payload);
export const getProductoById = (id) => baseApi.get(`/producto/${id}`);
export const getProductoByUrl = (url) => baseApi.get(`/producto/url/${url}`);
export const getProductoActivo = () => baseApi.get(`/productoActivo`);
export const updateProducto = (id, payload) =>
  baseApi.put(`/producto/${id}`, payload);
export const deactivateProducto = (id) =>
  baseApi.put(`/producto/desactivar/${id}`);
export const getProductoByTipoProductoId = (id) =>
  baseApi.get(`/producto/tipoProducto/${id}`);

//Persona
export const createPersona = (payload) => baseApi.post(`/persona`, payload);
export const verifyEmail = (payload) =>
  baseApi.post(`/persona/verifyEmail`, payload);
export const getPersonaById = (id) => baseApi.get(`/persona/${id}`);
export const login = (payload, authorization) =>
  baseApi.post(`/login`, payload, authorization);
export const confirmPersona = (payload) =>
  baseApi.post(`/persona/confirmPersona`, payload);
export const updatePassword = (payload) =>
  baseApi.post(`/persona/updatePassword`, payload);

//Billing
export const billing = (payload) => baseApi.post(`/billing`, payload);

//Evaluacion
export const saveEvaluacion = (payload, headers) =>
  baseApi.post(`/evaluacion`, payload, headers);
export const saveMadurezCronograma = (payload, headers) =>
  baseApi.post(`/madurezCronograma`, payload, headers);
export const saveSaludProyecto = (payload, headers) =>
  baseApi.post(`/saludProyecto`, payload, headers);
export const savePerfilPMConductual = (payload, headers) =>
  baseApi.post(`/perfilPMConductual`, payload, headers);
export const saveLiderazoTareaPersona = (payload, headers) =>
  baseApi.post(`/liderazgoTaraeaPersona`, payload, headers);
export const saveLiderazgo = (payload, headers) =>
  baseApi.post(`/liderazgo`, payload, headers);
export const savePracticaGerencial = (payload, headers) =>
  baseApi.post(`/practicaGerencial`, payload, headers);
export const saveEstiloResolucionProblemas = (payload, headers) =>
  baseApi.post(`/resolucionProblemas`, payload, headers);

export const getPermission = (payload, headers) =>
  baseApi.post(`/permission`, payload, headers);

//Compra
export const saveCompra = (payload, headers) =>
  baseApi.post(`/compra`, payload, headers);

//Descuentos
export const getCodigoDescuento = (headers) =>
  baseApi.get(`/descuento/generate`, headers);
export const saveDescuentoNewUser = (payload, headers) =>
  baseApi.put(`/descuento/saveCodeNewUser`, payload, headers);
export const aplicarDescuento = (payload, headers) =>
  baseApi.post(`/descuento/aplicar`, payload, headers);

//Certificados
export const subirCertificados = (payload, headers) =>
  baseApi.post(`/certificados`, payload, headers);
export const getCertificadosSubidos = () => baseApi.get(`/certificadoSubido`);
export const getCertificadoById = (id) => baseApi.get(`/certificados/${id}`);
export const getCertificadoByDetalleId = (id, payload, headers) =>
  baseApi.put(`/certificados/detalle/${id}`, payload, headers);
export const desactivateCertificadoById = (id, headers) =>
  baseApi.delete(`/certificados/${id}/desactivar`, {}, headers);

//Curso
export const createCurso = (payload) => baseApi.post(`/curso`, payload);
export const getCursoActivo = () => baseApi.get(`/cursoActivo`);
export const getCursoById = (id) => baseApi.get(`/curso/${id}`);
export const updateCurso = (id, payload) =>
  baseApi.put(`/curso/${id}`, payload);
export const deactivateCurso = (id) => baseApi.put(`/curso/desactivar/${id}`);

//Tipo de Curso
export const getTipoCursoActivo = () => baseApi.get(`/tipoCursoActivo`);

//Tipo de Producto
export const getTipoProductoActivo = () => baseApi.get(`/tipoProductoActivo`);

//Consultas
export const getPersonasByHerramienta = (id) =>
  baseApi.get(`/persona/herramienta/${id}`);
export const getRespuestasEnBruto = (payload) =>
  baseApi.post(`/herramientas/respuestas`, payload);
export const getRespuestasEnBruto2 = (payload) =>
  baseApi.post(`/herramientas/respuestas2`, payload);
export const getUsuariosPorLibro = (payload) =>
  baseApi.post(`/herramientas/usuariosPorLibro`, payload);

//Notificaciones
export const enviarCertificado = (id, payload, headers) =>
  baseApi.put(`/notificaciones/certificados/${id}`, payload, headers);
// "Content-Type": "multipart/form-data"

//Paymentez
export const generateOrder = (payload, headers) =>
  baseApi.post(`/payment/generarOrden`, payload, headers);
export const checkout = (id, payload, headers) =>
  baseApi.put(`/payment/checkout/${id}`, payload, headers);
export const getPaymentByOrder = (order, headers) =>
  baseApi.get(`/payment/${order}`, headers);
export const refund = (payload, headers) =>
  baseApi.post(`payment/refund`, payload, headers);
export const compra = (id, payload, headers) =>
  baseApi.put(`/payment/compra/${id}`, payload, headers);
export const compraSinBotonPago = (id, payload, headers) =>
  baseApi.put(`/payment/compraSinBotonPago/${id}`, payload, headers);

//Movimiento
export const darDeBaja = (payload, headers) =>
  baseApi.post(`/movimiento/baja`, payload, headers);

const api = {
  createTipoEvaluacion,
  getTipoEvaluacionActivo,
  deleteTipoEvaluacion,
  updateTipoEvaluacion,
  getTipoEvaluacionById,
  deactivateTipoEvaluacion,
  createNivelAcuerdo,
  getNivelAcuerdoActivo,
  deleteNivelAcuerdo,
  updateNivelAcuerdo,
  getNivelAcuerdoById,
  deactivateNivelAcuerdo,
  createTipoHabilidad,
  getTipoHabilidadActivo,
  deleteTipoHabilidad,
  updateTipoHabilidad,
  getTipoHabilidadById,
  deactivateTipoHabilidad,
  getTipoHabilidadByTipoEvaluacion,
  createNivelMadurez,
  getNivelMadurezActivo,
  deleteNivelMadurez,
  updateNivelMadurez,
  getNivelMadurezById,
  deactivateNivelMadurez,
  getNivelMadurezByTipoEvaluacion,
  createSemaforo,
  getSemaforoActivo,
  deleteSemaforo,
  updateSemaforo,
  getSemaforoById,
  deactivateSemaforo,
  getSemaforoByTipoEvaluacion,
  createCriterio,
  getCriterioActivo,
  deleteCriterio,
  updateCriterio,
  getCriterioById,
  deactivateCriterio,
  getCriterioByTipoHabilidad,
  createNivelHabilidad,
  getNivelHabilidadActivo,
  deteleNivelHabilidad,
  updateNivelHabilidad,
  getNivelHabilidadById,
  deactivateNivelHabilidad,
  getNivelHabilidadByTipoHabilidad,
  createPersona,
  verifyEmail,
  billing,
  getCriterioAgrupado,
  getPersonaById,
  saveEvaluacion,
  saveMadurezCronograma,
  saveSaludProyecto,
  savePerfilPMConductual,
  saveLiderazoTareaPersona,
  saveLiderazgo,
  savePracticaGerencial,
  saveEstiloResolucionProblemas,
  saveCompra,
  getPermission,
  login,
  confirmPersona,
  updatePassword,
  getCodigoDescuento,
  saveDescuentoNewUser,
  aplicarDescuento,
  getPersonasByHerramienta,
  createCurso,
  getCursoActivo,
  getCursoById,
  updateCurso,
  deactivateCurso,
  subirCertificados,
  getCertificadosSubidos,
  getCertificadoById,
  getCertificadoByDetalleId,
  getTipoCursoActivo,
  getRespuestasEnBruto,
  getRespuestasEnBruto2,
  enviarCertificado,
  generateOrder,
  checkout,
  getPaymentByOrder,
  refund,
  createProducto,
  getProductoById,
  getProductoByUrl,
  getProductoActivo,
  updateProducto,
  deactivateProducto,
  getTipoProductoActivo,
  darDeBaja,
  compra,
  desactivateCertificadoById,
  compraSinBotonPago,
  getProductoByTipoProductoId,
  getUsuariosPorLibro,
};

export default api;
