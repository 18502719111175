import React, { useState, useEffect } from "react";
import { useHistory, useParams} from "react-router-dom";
import { 
    PageHeader,
    HelpBlock,
    FormGroup,
    FormControl,
    ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { Auth } from "aws-amplify";
import { onError} from "../libs/errorLib";
import API from "../api";
import "./Signup.css"

export default function Signup(){
    const [fields, handleFieldChange] = useFormFields({
        nombre: "",
        apellido: "",
        tipoDocumento: "C",
        direccion: "",
        confirmationCode: "",
    });
    const history = useHistory();
    const { email: emailFromLogin } = useParams();
    
    const [newUser, setNewUser] = useState(null);
    const { userHasAuthenticated, setToken, setRole, setHerramientas } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [ emailDisponible, setEmailDisponible] = useState(null);
    const [ email, setEmail ] = useState("");
    const [ emailMsj, setEmailMsj ] = useState("Un código de confirmación será enviado a su email.");

    const [ password, setPassword ] = useState("");
    const [ passwordValido, setPasswordValido ] = useState(null);

    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ passwordIguales, setPasswordIguales] = useState(null);
    const [ passwordMsj, setPasswordMsj ] = useState("");

    const [ documento, setDocumento ] = useState("");
    const [ documentoValido, setDocumentoValido ] = useState(null);
    const [ documentoMsj, setDocumentoMsj ] = useState("");


    useEffect(() => {
        async function onLoad() {
            try {
                if (emailFromLogin !== undefined) {
                    setNewUser({ username: emailFromLogin});
                    setEmail(emailFromLogin);
                    //await Auth.resendSignUp(emailFromLogin);
                }
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, [emailFromLogin]);

    function validateForm() {
        return (
            fields.nombre.length > 0 &&
            fields.tipoDocumento &&
            email.length > 0 &&
            password.length > 0 &&
            password === confirmPassword &&
            emailDisponible === "success" &&
            passwordValido === "success" &&
            documentoValido === "success"
        );
    } 

    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        
        let resendCode = false;
        const user = { username: email, password: password };
        try {
            const newUser = await Auth.signUp(user);
            const payload = {   awsUsername: newUser.userSub, nombre: fields.nombre, apellido: fields.apellido,
                                tipoDocumento: fields.tipoDocumento, documento: documento, direccion: fields.direccion,
                                correo: email, password };
            await API.createPersona(payload);
            setNewUser(newUser);
            setIsLoading(false);
        } catch(e) {
            if (e.code === 'UsernameExistsException') {
                resendCode = true;
            } else {
                onError(e);
            }
            setIsLoading(false);
        }

        if (resendCode) {
            try {
                const resend = await Auth.resendSignUp(email);
                setNewUser(resend);
            } catch(e) {
                onError(e);
            }
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        let confirmed = false;
        try {
            await Auth.confirmSignUp(email, fields.confirmationCode);
            await API.confirmPersona({ correo: email});
            confirmed = true;

            const result = await Auth.signIn(email, password);
            const loginResult = await API.login({ }, {
                auth: {
                    username: result.username,
                    password: result.signInUserSession.accessToken.jwtToken
                }
            });
            setToken(loginResult.data.token);
            setRole(loginResult.data.role);
            setHerramientas(loginResult.data.herramientas);
            userHasAuthenticated(true);
            history.push("/");
        } catch(e) {
            setIsLoading(false);
            if (e.code === "NotAuthorizedException") { //Cuando usuario no confirmado intenta registrase por segunda vez, con otra contraseña
                history.push("/login");
            } else if (e.code === "ExpiredCodeException" && confirmed) { //Cliente acabo de activarse, ocurrio una exception y y trata de ingresar el codigo nuevamente
                history.push("/login");
            } else if (e.code === "InvalidParameterException" && confirmed) { //Cliente acabo de activarse, pero la contraseña es vacia o no coincide
                history.push("/login");
            } else if (e.code === "CodeMismatchException") { //Cuando el codigo no es valido
                alert("El código ingresado no es el correcto.")
            }
            
        }
    }

    async function validateEmail(event) {
        const email = event.target.value;
        setEmail(email);
        try {
            const disponible = await API.verifyEmail({email});
            if (disponible.data.available) {
                setEmailDisponible('success');
                setEmailMsj("El email se encuentra disponible");
            } else if (!disponible.data.available) {
                setEmailDisponible('error');
                setEmailMsj("El email no se encuentra disponible");
            }
        } catch(e) { 
            setEmailDisponible(null);
            setEmailMsj("Un código de confirmación será enviado a su email.");
        }
    }

    function validatePassword(event){
        const letterNumber = /\d/;
        const password = event.target.value;
        setPassword(password);

        if (!password.length) {
            setPasswordValido(null);
            return;
        }
        if(!password.match(letterNumber)) {
            setPasswordValido('error');
            return;
        }

        password.length < 8 ? setPasswordValido('error')
                            : password.length >=8 ? setPasswordValido('success') : setPasswordValido(null);

    }

    function validateConfirmPassword(event) {
        const confirm = event.target.value;
        setConfirmPassword(confirm);
        
        if (password === confirm) {
            setPasswordMsj("");
            setPasswordIguales('success');
        } else {
            setPasswordMsj("Las contraseñas no coinciden");
            setPasswordIguales('error');
        }
    }

    function validateDocumento(event) {
        const cedula = event.target.value;
        setDocumento(cedula);

        if (fields.tipoDocumento === "C") {
            if(cedula.length === 10){
                //Obtenemos el digito de la region que sonlos dos primeros digitos
                let digito_region = cedula.substring(0,2);
                
                //Pregunto si la region existe ecuador se divide en 24 regiones
                if( digito_region >= 1 && digito_region <=24 ){
                    
                    // Extraigo el ultimo digito
                    let ultimo_digito   = cedula.substring(9,10);
        
                    //Agrupo todos los pares y los sumo
                    let pares = parseInt(cedula.substring(1,2)) + parseInt(cedula.substring(3,4)) + parseInt(cedula.substring(5,6)) + parseInt(cedula.substring(7,8));
        
                    //Agrupo los impares, los multiplico por un factor de 2, si la resultante es > que 9 le restamos el 9 a la resultante
                    let numero1 = cedula.substring(0,1);
                    numero1 = (numero1 * 2);
                    if( numero1 > 9 ){ numero1 = (numero1 - 9); }
        
                    let numero3 = cedula.substring(2,3);
                    numero3 = (numero3 * 2);
                    if( numero3 > 9 ){ numero3 = (numero3 - 9); }
        
                    let numero5 = cedula.substring(4,5);
                    numero5 = (numero5 * 2);
                    if( numero5 > 9 ){ numero5 = (numero5 - 9); }
        
                    let numero7 = cedula.substring(6,7);
                    numero7 = (numero7 * 2);
                    if( numero7 > 9 ){ numero7 = (numero7 - 9); }
        
                    let numero9 = cedula.substring(8,9);
                    numero9 = (numero9 * 2);
                    if( numero9 > 9 ){ numero9 = (numero9 - 9); }
        
                    let impares = numero1 + numero3 + numero5 + numero7 + numero9;
        
                    //Suma total
                    let suma_total = (pares + impares);
        
                    //extraemos el primero digito
                    let primer_digito_suma = String(suma_total).substring(0,1);
        
                    //Obtenemos la decena inmediata
                    let decena = (parseInt(primer_digito_suma) + 1)  * 10;
        
                    //Obtenemos la resta de la decena inmediata - la suma_total esto nos da el digito validador
                    let digito_validador = decena - suma_total;
        
                    //Si el digito validador es = a 10 toma el valor de 0
                    if(digito_validador === 10)
                    digito_validador = 0;
        
                    //Validamos que el digito validador sea igual al de la cedula
                    if(digito_validador == ultimo_digito){
                    setDocumentoValido("success");
                    setDocumentoMsj("");
                    }else{
                    setDocumentoValido("error");
                    setDocumentoMsj("Número de cédula inválida");
                    }
                    
                }else{
                    // imprimimos en consola si la region no pertenece
                    setDocumentoValido("error");
                    setDocumentoMsj("Número de cédula inválida");
                }
                }else{
                //imprimimos en consola si la cedula tiene mas o menos de 10 digitos
                setDocumentoValido("error");
                setDocumentoMsj("La cédula tiene menos o más de 10 dígitos");
                }   
        } else {
            setDocumentoValido("success");
            setDocumentoMsj("");
        }
    }

    

    function renderForm() {
        return (
            <form onSubmit={handleSubmit}>
            <PageHeader>Crea tu usuario</PageHeader>
            <FormGroup controlId="nombre" bsSize="large">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl
                autoFocus
                type="text"
                autoComplete="off"
                value={fields.nombre}
                onChange={handleFieldChange}
            />
            </FormGroup>

            <FormGroup controlId="apellido" bsSize="large">
            <ControlLabel>Apellido</ControlLabel>
            <FormControl
                type="text"
                autoComplete="off"
                value={fields.apellido}
                onChange={handleFieldChange}
            />
            </FormGroup>

            <FormGroup controlId="tipoDocumento">
            <ControlLabel>Tipo de Documento</ControlLabel>
            <FormControl
                componentClass="select"
                placeholder="select"
                value={fields.tipoDocumento}
                onChange={handleFieldChange}
            >
                <option value="C">Cédula</option>
                <option value="P">Pasaporte</option>
            </FormControl>
            </FormGroup>

            <FormGroup controlId="documento" bsSize="large" validationState={documentoValido}>
            <ControlLabel>No. de Identificación</ControlLabel>
            <FormControl
                type="text"
                autoComplete="off"
                value={documento}
                onChange={validateDocumento}
            />
            <FormControl.Feedback />
            <HelpBlock>{documentoMsj}</HelpBlock>
            </FormGroup>

            <FormGroup controlId="direccion" bsSize="large">
            <ControlLabel>Dirección</ControlLabel>
            <FormControl
                componentClass="textarea"
                value={fields.direccion}
                onChange={handleFieldChange}
            />
            </FormGroup>

            <hr />
            <FormGroup controlId="email" bsSize="large" validationState={emailDisponible}>
            <ControlLabel>Email</ControlLabel>
            <FormControl
                type="email"
                autoComplete="off"
                value={email}
                onChange={validateEmail}
            />
            <FormControl.Feedback />
            <HelpBlock>{emailMsj}</HelpBlock>
            </FormGroup>

            <FormGroup controlId="password" bsSize="large" validationState={passwordValido}>
            <ControlLabel>Contraseña</ControlLabel>
            <FormControl
                type="password"
                value={password}
                onChange={validatePassword}
            />
            <FormControl.Feedback />
            <HelpBlock>La clave debe tener mínimo 8 caracteres y 1 número.</HelpBlock>
            </FormGroup>
           
            <FormGroup controlId="confirmPassword" bsSize="large" validationState={passwordIguales}>
            <ControlLabel>Confirme su Contraseña</ControlLabel>
            <FormControl
                type="password"
                value={confirmPassword}
                onChange={validateConfirmPassword}
            />
            <FormControl.Feedback />
            <HelpBlock>{passwordMsj}</HelpBlock>
            </FormGroup>
            <LoaderButton
                block
                className="button-orange"
                type="submit"
                bsSize="large"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Registarme
            </LoaderButton>
            </form>
        );
    }

    function renderConfirmationForm() {
        return (
            
            <form onSubmit={handleConfirmationSubmit}>
            <FormGroup controlId="confirmationCode" bsSize="large">
            <ControlLabel>Código de confirmación</ControlLabel>
            <FormControl
                autoFocus
                type="tel"
                onChange={handleFieldChange}
                value={fields.confirmationCode}
            />
            <HelpBlock>Revise la recepción del código en su correo.</HelpBlock>
            </FormGroup>
            <LoaderButton
                block
                className="button-orange"
                type="submit"
                bsSize="large"
                isLoading={isLoading}
                disabled={!validateConfirmationForm()}
            >
                Confirmar
            </LoaderButton>
            </form>
        );
    }


    return (
        <div className="Signup">
        {newUser === null 
            ? renderForm()
            : renderConfirmationForm() }
        </div>
    )





}