import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import "./ConsultaNivelAcuerdos";

export default function ConsultaNivelAcuerdos() {
    const [ nivelAcuerdos, setNivelAcuerdos ] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [ isLoading, setIsLoading ] = useState(true);
    const history = useHistory();

    useEffect(()=> { 
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }

            try {
                const result = await API.getNivelAcuerdoActivo();
                setNivelAcuerdos(result.data.data);
            } catch(e) {
                onError(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated]);

    function renderNivelAcuerdosList(nivelAcuerdos) {
        return [{}].concat(nivelAcuerdos).map((nivelAcuerdo, i) =>
            i !== 0 ? (
                <LinkContainer key={nivelAcuerdo._id} to={`/nivelAcuerdo/${nivelAcuerdo._id}`}>
                <ListGroupItem header={nivelAcuerdo.nombre}>
                    {"Creado: " + new Date(nivelAcuerdo.createdAt).toLocaleString()}
                </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to="/nivelAcuerdo/new">
                <ListGroupItem>
                    <h4>
                    <b>{"\uFF0B"}</b> Crear nivel de acuerdo
                    </h4>
                </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    function renderNivelAcuerdos() {
        return (
            <div className="nivelAcuerdos">
            <PageHeader>Niveles de Acuerdo</PageHeader>
            <ListGroup>
            {!isLoading && renderNivelAcuerdosList(nivelAcuerdos)}
            </ListGroup>
            </div>
        );
    }

    return (
        <div className="nivelAcuerdos">
        {isAuthenticated ? renderNivelAcuerdos() : history.push("/") }
        </div>
    );


}