import React, { useState, useEffect } from "react";
import { PageHeader, FormGroup, FormControl, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import DatePicker from "react-date-picker";
import "./RespuestasBruto.css";

import ReactExport from "react-export-excel";

export default function RespuetasBruto() {
  const history = useHistory();
  const [tipoEvaluaciones, setTipoEvaluaciones] = useState([]);
  const [tipoEvaluacion, setTipoEvaluacion] = useState("");
  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(new Date());
  const { isAuthenticated } = useAppContext();
  const [isLoadingTipoEvaluacion, setIsLoadingTipoEvaluacion] = useState(false);
  const [isLoadingRespuesta, setIsLoadingRespuesta] = useState(false);

  const [isLoadedRespuesta, setIsLoadedRespuesta] = useState(false);
  const [dataset, setDataSet] = useState([]);
  const [tipoEvaluacionNombre, setTipoEvaluacionNombre] = useState("");
  const [columns, setColumns] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      setIsLoadingTipoEvaluacion(true);
      try {
        const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
        setTipoEvaluaciones(tipoEvaluaciones.data.data);
      } catch (e) {
        onError(e);
      }
      setIsLoadingTipoEvaluacion(false);
    }

    onLoad();
  }, [isAuthenticated]);

  async function handleSelectTipoEvaluacion(event) {
    const tipoEvaluacionId = event.target.value;

    let tipoEvaluacionNombre;
    for (let i = 0; i < tipoEvaluaciones.length; i++) {
      const element = tipoEvaluaciones[i];
      if (element._id === tipoEvaluacionId) {
        tipoEvaluacionNombre = element.nombre;
        break;
      }
    }
    setTipoEvaluacion(tipoEvaluacionId);
    setTipoEvaluacionNombre(tipoEvaluacionNombre);
    setIsLoadedRespuesta(false);
  }

  async function handleDatePickerDesde(event) {
    setDesde(event);
    setIsLoadedRespuesta(false);
  }

  async function handleDatePickerHasta(event) {
    setHasta(event);
    setIsLoadedRespuesta(false);
  }

  function validateForm() {
    return tipoEvaluacion && desde !== null && hasta !== null;
  }

  async function consultar(event) {
    event.preventDefault();
    setIsLoadingRespuesta(true);
    try {
      console.log(desde);
      const payload = {
        tipoEvaluacion,
        desde:
          desde.getFullYear() +
          "-" +
          (desde.getMonth() + 1) +
          "-" +
          desde.getDate(),
        hasta:
          hasta.getFullYear() +
          "-" +
          (hasta.getMonth() + 1) +
          "-" +
          hasta.getDate(),
      };
      const respuestas = await API.getRespuestasEnBruto(payload);
      setColumns(respuestas.data.data[0]._id);

      const rows = respuestas.data.data[0].respuestas;
      const dataset = rows.map((row) => row.evaluacionDetalle);
      setDataSet(dataset);
      setIsLoadedRespuesta(true);
    } catch (e) {
      setColumns([]);
      setDataSet([]);
    }
    setIsLoadingRespuesta(false);
  }

  async function consultar2(event) {
    event.preventDefault();
    setIsLoadingRespuesta(true);
    try {
      console.log(desde);
      const payload = {
        tipoEvaluacion,
        desde:
          desde.getFullYear() +
          "-" +
          (desde.getMonth() + 1) +
          "-" +
          desde.getDate(),
        hasta:
          hasta.getFullYear() +
          "-" +
          (hasta.getMonth() + 1) +
          "-" +
          hasta.getDate(),
      };
      const respuestas = await API.getRespuestasEnBruto2(payload);
      const dataset = respuestas.data.data[0].respuestas;

      const columns = respuestas.data.data[0].columns;
      columns.splice(0, 0, { _id: "_id", descripcion: "Pregunta" });
      setColumns(columns);

      // Otras lineas agregadas al final de cada reporte
      const otros = respuestas.data.data[0].otros;
      const lineaEmpresa = { _id: "Empresa" };
      const lineaCargo = { _id: "Cargo" };
      const lineaProfesion = { _id: "Profesion" };
      const lineaPais = { _id: "Pais" };
      const lineaGenero = { _id: "Genero" };

      otros.map((item) => {
        const { _id, empresa, cargo, profesion, pais, genero } = item;
        lineaEmpresa[_id] = empresa;
        lineaCargo[_id] = cargo;
        lineaProfesion[_id] = profesion;
        lineaPais[_id] = pais;
        lineaGenero[_id] = genero;
        return "";
      });

      dataset.push(lineaEmpresa);
      dataset.push(lineaCargo);
      dataset.push(lineaProfesion);
      dataset.push(lineaPais);
      dataset.push(lineaGenero);

      setDataSet(dataset);
      setIsLoadedRespuesta(true);
    } catch (e) {
      setColumns([]);
      setDataSet([]);
    }
    setIsLoadingRespuesta(false);
  }

  function renderForm() {
    return (
      <div className="Respuestas">
        <PageHeader>Exportar datos de Evaluaciones</PageHeader>
        <FormGroup>
          <FormControl
            componentClass="select"
            onChange={handleSelectTipoEvaluacion}
          >
            <option value="0">Elija un tipo de evaluación</option>
            {!isLoadingTipoEvaluacion &&
              tipoEvaluaciones.map((tipoEvaluacion) => {
                return (
                  <option key={tipoEvaluacion._id} value={tipoEvaluacion._id}>
                    {tipoEvaluacion.nombre}
                  </option>
                );
              })}
          </FormControl>
        </FormGroup>

        <FormGroup controlId="desde">
          <Col sm={2}>
            <DatePicker
              locale="es"
              onChange={handleDatePickerDesde}
              value={desde}
            />
          </Col>
          <Col sm={2}>
            <DatePicker
              locale="es"
              onChange={handleDatePickerHasta}
              value={hasta}
            />
          </Col>
          <Col sm={3}>
            <LoaderButton
              block
              className="button-orange"
              onClick={consultar2}
              isLoading={isLoadingRespuesta}
              disabled={!validateForm()}
            >
              Cargar Datos
            </LoaderButton>
          </Col>
        </FormGroup>

        {isLoadedRespuesta && (
          <ExcelFile
            element={
              <LoaderButton className="button-orange">Descargar</LoaderButton>
            }
          >
            <ExcelSheet data={dataset} name={tipoEvaluacionNombre}>
              {columns.map((column) => {
                return (
                  <ExcelColumn
                    key={column._id}
                    label={column.descripcion}
                    value={column._id}
                  />
                );
              })}
            </ExcelSheet>
          </ExcelFile>
        )}
      </div>
    );
  }

  return <div>{isAuthenticated ? renderForm() : history.push("/")}</div>;
}
