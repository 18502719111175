import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, ListGroup, ListGroupItem, FormGroup, FormControl } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import { LinkContainer } from "react-router-bootstrap";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import { globalVars } from "../../constants";
import "./ConsultaNivelesHabilidad.css";

export default function ConsultaNivelesHabilidad() {
    const [ tipoHabilidades, setTipoHabilidades ] = useState([]);
    const [ nivelesHabilidad, setNivelesHabilidad ] = useState([]);
    const [ isLoadingTipoHabilidad, setIsLoadingTipoHabilidad ] = useState(true);
    const [ isLoadingNivelHabilidad, setIsLoadingNivelHabilidad ] = useState(true);
    const { isAuthenticated } = useAppContext();
    const history = useHistory();

    useEffect(()=> {
        if (!isAuthenticated) {
            return;
        }

        async function onLoad() {
            try {
                const tipoHabilidades = await API.getTipoHabilidadByTipoEvaluacion(globalVars.EVALUACION_PM_ID);
                setTipoHabilidades(tipoHabilidades.data.data);
            } catch(e) {
                onError(e);
            }
            setIsLoadingTipoHabilidad(false);
            setIsLoadingNivelHabilidad(false);
        }

        onLoad();
    }, [isAuthenticated]);

    async function handleSelectTipoHabilidad(event) {
        const tipoHabilidad = event.target.value;

        setIsLoadingNivelHabilidad(true);
        try {
            const nivelesHabilidad = await API.getNivelHabilidadByTipoHabilidad(tipoHabilidad);
            setNivelesHabilidad(nivelesHabilidad.data.data);
        } catch(e) {
            setNivelesHabilidad([]);
        }
        setIsLoadingNivelHabilidad(false);
    }

    function renderNivelHabilidadList(nivelesHabilidad) {
        return [{}].concat(nivelesHabilidad).map((nivelHabilidad, i) => 
            i !== 0 ? (
                <LinkContainer key={nivelHabilidad._id} to={`/nivelHabilidad/${nivelHabilidad._id}`}>
                    <ListGroupItem header={nivelHabilidad.nombre}>
                        Desde: {nivelHabilidad.desdePuntos} Hasta: {nivelHabilidad.hastaPuntos}
                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to="/nivelHabilidad/new">
                    <ListGroupItem>
                        <h4>
                        <b>{"\uFF0B"}</b> Crear nivel Habilidad
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    function renderNivelHabilidad() {
        return (
            <div className="NivelesHabilidad">
            <PageHeader>Niveles de Habilidad</PageHeader>
            <FormGroup controlId="tipoHabilidad">
            <FormControl
                componentClass="select"
                placeholder="select"
                onChange={handleSelectTipoHabilidad}
            >
                <option key="0">Elija un tipo de habilidad</option>
                {!isLoadingTipoHabilidad && tipoHabilidades.map(tipoHabilidad => {
                    return (
                        <option key={tipoHabilidad._id} value={tipoHabilidad._id}>
                            {tipoHabilidad.nombre}
                        </option>
                    );
                })}
            </FormControl>
            </FormGroup>
            <ListGroup>
            {!isLoadingNivelHabilidad && renderNivelHabilidadList(nivelesHabilidad)}
            </ListGroup>
            </div>
        );
    }

    return (
        <div>
        {isAuthenticated ? renderNivelHabilidad() : history.push("/") }
        </div>
    );
}
