import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
    HelpBlock, FormGroup, Glyphicon, FormControl,
    ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import API from "../api";
import "./ResetPassword.css";

export default function ResetPassword() {
    const [fields, handleFieldChange] = useFormFields({
        code: "",
        email: ""
    });

    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    const [password, setPassword] = useState("");
    const [ passwordValido, setPasswordValido ] = useState(null);

    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ passwordIguales, setPasswordIguales] = useState(null);
    const [ passwordMsj, setPasswordMsj ] = useState("");

    function validateCodeForm() {
        return fields.email.length > 0;
    }

    function validateResetForm() {
        return (
            fields.code.length > 0 &&
            password.length > 0 &&
            password === confirmPassword  &&
            passwordValido === "success"
        );
    }

    function validatePassword(event){
        const letterNumber = /\d/;
        const password = event.target.value;
        setPassword(password);

        if (!password.length) {
            setPasswordValido(null);
            return;
        }
        if(!password.match(letterNumber)) {
            setPasswordValido('error');
            return;
        }

        password.length < 8 ? setPasswordValido('error')
                            : password.length >=8 ? setPasswordValido('success') : setPasswordValido(null);
    }

    function validateConfirmPassword(event) {
        const confirm = event.target.value;
        setConfirmPassword(confirm);
        
        if (password === confirm) {
            setPasswordMsj("");
            setPasswordIguales('success');
        } else {
            setPasswordMsj("Las contraseñas no coinciden");
            setPasswordIguales('error');
        }
        
    }

    async function handleSendCodeClick(event) {
        event.preventDefault();
        setIsSendingCode(true);

        try {
            await Auth.forgotPassword(fields.email);
            setCodeSent(true);
        } catch(error) {
            onError(error);
            setIsSendingCode(false);
        }
    }

    async function handleConfirmClick(event) {
        event.preventDefault();
        setIsConfirming(true);

        try {
            await Auth.forgotPasswordSubmit(fields.email, fields.code, password);
            await API.updatePassword({ correo: fields.email, password});
            setConfirmed(true);
        } catch(e) {
            if (e.code === "CodeMismatchException") {
                alert("El código ingresado no es el correcto.")
            } else if (e.code === "ExpiredCodeException") {
                alert("El código ingresado expiró debe generar otro.")
            }else {
                alert(e.message)
            }
            setIsConfirming(false);
        }
    }

    function renderRequestCodeForm() {
        return (
            <form onSubmit={handleSendCodeClick}>
            <FormGroup bsSize="large" controlId="email">
            <ControlLabel>Email</ControlLabel>
            <FormControl
                autoFocus
                type="email"
                autoComplete="off"
                value={fields.email}
                onChange={handleFieldChange}
            />
            </FormGroup>
            <LoaderButton
                block
                type="submit"
                bsSize="large"
                className="button-orange"
                isLoading={isSendingCode}
                disabled={!validateCodeForm()}
            >
            Enviar Código
            </LoaderButton>
            </form>
        );
    }

    function renderConfirmationForm() {
        return (
            <form onSubmit={handleConfirmClick}>
            <FormGroup bsSize="large" controlId="code">
            <ControlLabel>Código de Confirmación</ControlLabel>
            <FormControl
                autoFocus
                type="tel"
                value={fields.code}
                onChange={handleFieldChange}
            />
            <HelpBlock>
            Por favor revise en su correo el código de confirmación
            </HelpBlock>
            </FormGroup>

            <hr />
            <FormGroup bsSize="large" controlId="password" validationState={passwordValido}>
            <ControlLabel>Contrasena nueva</ControlLabel>
            <FormControl
                type="password"
                value={password}
                onChange={validatePassword}
            /> 
            <FormControl.Feedback />
            <HelpBlock>La clave debe tener mínimo 8 caracteres y 1 número.</HelpBlock>
            </FormGroup>

            <FormGroup bsSize="large" controlId="confirmPassword" validationState={passwordIguales}>
            <ControlLabel>Confirme su contraseña</ControlLabel>
            <FormControl
                type="password"
                value={confirmPassword}
                onChange={validateConfirmPassword}
            /> 
            <FormControl.Feedback />
            <HelpBlock>{passwordMsj}</HelpBlock>
            </FormGroup>
            
            <LoaderButton
                block
                type="submit"
                bsSize="large"
                className="button-orange"
                isLoading={isConfirming}
                disabled={!validateResetForm()}
            >
                Confirmar
            </LoaderButton>
            </form>
        );
    }

    function renderSuccessMessage() {
        return (
            <div className="success">
            <Glyphicon glyph="ok" />
            <p>Su contraseña ha sido reestablecida.</p>
            <p>
                <Link to="/login">
                Haga click aqui para iniciar sesión con su nueva contraseña
                </Link>
            </p>
            </div>
        )
    }

    return (
        <div className="ResetPassword">
        {!codeSent
            ? renderRequestCodeForm()
            : !confirmed
                ? renderConfirmationForm()
                : renderSuccessMessage()
        }
        </div>
    )

}