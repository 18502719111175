import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, HelpBlock } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import API from "../api";
import "./ChangePassword.css";

export default function ChangePassword() {
    const history = useHistory();
    const [fields, handleFieldChange] = useFormFields({
        oldPassword: ""
    });
    const [isChanging, setIsChanging] = useState(false);

    const [password, setPassword] = useState("");
    const [ passwordValido, setPasswordValido ] = useState(null);

    const [confirmPassword, setConfirmPassword] = useState("");
    const [ passwordIguales, setPasswordIguales] = useState(null);
    const [ passwordMsj, setPasswordMsj ] = useState("");

    function validateForm() {
        return (
            fields.oldPassword.length > 0 &&
            password.length > 0 &&
            password === confirmPassword &&
            passwordValido === "success"
        );
    }

    function validatePassword(event){
        const letterNumber = /\d/;
        const password = event.target.value;
        setPassword(password);

        if (!password.length) {
            setPasswordValido(null);
            return;
        }
        if(!password.match(letterNumber)) {
            setPasswordValido('error');
            return;
        }

        password.length < 8 ? setPasswordValido('error')
                            : password.length >=8 ? setPasswordValido('success') : setPasswordValido(null);
    }

    function validateConfirmPassword(event) {
        const confirm = event.target.value;
        setConfirmPassword(confirm);
        
        if (password === confirm) {
            setPasswordMsj("");
            setPasswordIguales('success');
        } else {
            setPasswordMsj("Las contraseñas no coinciden");
            setPasswordIguales('error');
        }
        
    }

    async function handleChangeClick(event) {
        event.preventDefault();
        setIsChanging(true);

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const email = currentUser.attributes.email;
            await Auth.changePassword(currentUser, fields.oldPassword, password);
            await API.updatePassword({ correo: email, password });
            alert("Su contraseña ha sido actualizada")
            history.push("/");
        } catch(e) {
            if (e.code === "NotAuthorizedException") {
                alert("Usuario o contraseña incorrectos")
            } else {
                alert("Ocurrio un problema, intentelo nuevamente");
            }
            setIsChanging(false);
        }
    }

    return (
        <div className="ChangePassword">
        <form onSubmit={handleChangeClick}>
        <FormGroup bsSize="large" controlId="oldPassword">
        <ControlLabel>Contraseña actual</ControlLabel>
        <FormControl
            autoFocus
            type="password"
            value={fields.oldPassword}
            onChange={handleFieldChange}
        />
        </FormGroup>
        <hr />

        <FormGroup bsSize="large" controlId="password" validationState={passwordValido}>
        <ControlLabel>Contraseña nueva</ControlLabel>
        <FormControl
            type="password"
            value={password}
            onChange={validatePassword}
        />
        <FormControl.Feedback />
        <HelpBlock>La clave debe tener mínimo 8 caracteres y 1 número.</HelpBlock>
        </FormGroup>

        <FormGroup bsSize="large" controlId="confirmPassword"  validationState={passwordIguales}>
        <ControlLabel>Confirme su contraseña</ControlLabel>
        <FormControl
            type="password"
            value={confirmPassword}
            onChange={validateConfirmPassword}
        />
        <FormControl.Feedback />
        <HelpBlock>{passwordMsj}</HelpBlock>
        </FormGroup>

        <LoaderButton
            block
            type="submit"
            bsSize="large"
            className="button-orange"
            disabled={!validateForm()}
            isLoading={isChanging}
        >
        Cambiar contraseña
        </LoaderButton>
        </form>
        </div>
    );


}