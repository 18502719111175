import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, InputGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaTipoEvaluacion.css";

export default function ConsultaTipoEvaluacion() {
    const { id } = useParams();
    const history = useHistory();
    const [ tipoEvaluacion, setTipoEvaluacion ] = useState(null);
    const [ nombre, setNombre ] = useState("");
    const [ precio, setPrecio ] = useState(0);
    const [ porcentajeIva, setPorcentajeIva ] = useState(0);
    const [ url, setUrl ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    useEffect(()=> { 
        async function onLoad() {
            try {
                const tipoEvaluacion = await API.getTipoEvaluacionById(id);
                const { nombre, precio, url,  porcentajeIva } = tipoEvaluacion.data.data;
                setNombre(nombre);
                setPrecio(precio === undefined ? 0 : precio);
                setPorcentajeIva(porcentajeIva === undefined ? 0 : porcentajeIva);
                setUrl(url === undefined ? "" : url);
                setTipoEvaluacion(tipoEvaluacion);
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    function validateForm() {
        return nombre.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const payLoad = { nombre, url, precio, porcentajeIva, activo: true };
            await API.updateTipoEvaluacion(id, payLoad);
            history.push("/tipoEvaluacion");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleDelete(event) {
        event.preventDefault();
        const confirmed = window.confirm("¿Desea borrar este tipo de evaluación?");
        if (!confirmed) {
            return;
        }
        setIsDeleting(true);

        try {
            await API.deactivateTipoEvaluacion(id);
            history.push("/tipoEvaluacion");
        } catch(e) {
            onError(e);
            setIsDeleting(false);
        }
    } 

    return (
        <div className="TipoEvaluacion">
            {tipoEvaluacion && (
                <form onSubmit={handleSubmit}>
                <FormGroup controlId="id">
                <ControlLabel>Id</ControlLabel>
                <FormControl.Static>{id}</FormControl.Static>
                </FormGroup>

                <FormGroup controlId="nombre">
                <ControlLabel>Nombre</ControlLabel>
                <FormControl
                    autoFocus
                    type="text"
                    autoComplete="off"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                />
                </FormGroup>

                <FormGroup controlId="url">
                <ControlLabel>Url</ControlLabel>
                <InputGroup>
                <InputGroup.Addon>/</InputGroup.Addon>
                <FormControl
                    type="text"
                    autoComplete="off"
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                />
                </InputGroup>
                </FormGroup>

                <FormGroup controlId="precio">
                <ControlLabel>Precio</ControlLabel>
                <InputGroup>
                <InputGroup.Addon>$</InputGroup.Addon>
                <FormControl
                    autoComplete="off"
                    type="decimal"
                    step="1"
                    min="0"
                    max="100"
                    value={precio}
                    onChange={e => setPrecio(e.target.value)}
                />
                </InputGroup>
                </FormGroup>

                <FormGroup controlId="iva">
                <ControlLabel>Porcentaje de IVA</ControlLabel>
                <InputGroup>
                <FormControl
                    autoComplete="off"
                    type="decimal"
                    step="1"
                    min="0"
                    max="100"
                    value={porcentajeIva}
                    onChange={e => setPorcentajeIva(e.target.value)}
                />
                <InputGroup.Addon>%</InputGroup.Addon>
                </InputGroup>
                </FormGroup>

                <FormGroup controlId="total">
                <ControlLabel>Valor total</ControlLabel>
                <FormControl.Static>$ {(Number(precio)+ (Number(precio)*Number(porcentajeIva)/100)).toFixed(2)}</FormControl.Static>
                </FormGroup>

                <LoaderButton
                    block
                    type="submit"
                    bsSize="large"
                    className="button-orange"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Guardar
                </LoaderButton>
                <LoaderButton
                    block
                    bsSize="large"
                    bsStyle="danger"
                    onClick={handleDelete}
                    isLoading={isDeleting}    
                >
                    Borrar
                </LoaderButton>
                    
                </form> 

            )}
        </div>
    );
}