import React, { useState, useEffect } from "react";
import { PageHeader, FormGroup, FormControl, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import DatePicker from "react-date-picker";
import "./PersonaByLibro.css";

import ReactExport from "react-export-excel";

export default function PersonaByLibro() {
  const history = useHistory();
  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(new Date());
  const { isAuthenticated } = useAppContext();
  const [isLoadingRespuesta, setIsLoadingRespuesta] = useState(false);
  const [dataset, setDataSet] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
    }

    onLoad();
  }, [isAuthenticated]);

  async function handleDatePickerDesde(event) {
    setDesde(event);
  }

  async function handleDatePickerHasta(event) {
    setHasta(event);
  }

  function validateForm() {
    return desde !== null && hasta !== null;
  }

  async function consultar(event) {
    event.preventDefault();
    setIsLoadingRespuesta(true);
    try {
      const payload = {
        desde:
          desde.getFullYear() +
          "-" +
          (desde.getMonth() + 1) +
          "-" +
          desde.getDate(),
        hasta:
          hasta.getFullYear() +
          "-" +
          (hasta.getMonth() + 1) +
          "-" +
          hasta.getDate(),
      };
      const respuestas = await API.getUsuariosPorLibro(payload);
      const dataset = respuestas.data.data;
      setDataSet(dataset);
    } catch (e) {
      setDataSet([]);
    }
    setIsLoadingRespuesta(false);
  }

  function renderForm() {
    return (
      <div className="PersonaLibro">
        <PageHeader>Exportar Usuarios por Libro</PageHeader>
        <FormGroup controlId="desde">
          <Col sm={2}>
            <DatePicker
              locale="es"
              onChange={handleDatePickerDesde}
              value={desde}
            />
          </Col>
          <Col sm={2}>
            <DatePicker
              locale="es"
              onChange={handleDatePickerHasta}
              value={hasta}
            />
          </Col>
          <Col sm={3}>
            <LoaderButton
              block
              className="button-orange"
              onClick={consultar}
              isLoading={isLoadingRespuesta}
              disabled={!validateForm()}
            >
              Cargar Datos
            </LoaderButton>
          </Col>
        </FormGroup>

        {!isLoadingRespuesta && (
          <ExcelFile
            element={
              <LoaderButton className="button-orange">Descargar</LoaderButton>
            }
          >
            <ExcelSheet data={dataset} name={`Usuarios-Libro`}>
              <ExcelColumn key="persona" label="Persona" value="persona" />
              <ExcelColumn key="libro" label="Libro" value="libro" />
              <ExcelColumn
                key="codigo"
                label="Codigo descuento"
                value="codigo"
              />
              <ExcelColumn key="usado" label="Usado" value="usado" />
            </ExcelSheet>
          </ExcelFile>
        )}
      </div>
    );
  }

  return <div>{isAuthenticated ? renderForm() : history.push("/")}</div>;
}
