import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import { globalVars } from "../../constants";
import "./ConsultaNivelHabilidad.css";

export default function ConsultaNivelHabilidad() {
  const { id } = useParams();
  const history = useHistory();
  const [nivelHabilidad, setNivelHabilidad] = useState(null);
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [tipoHabilidad, setTipoHabilidad] = useState("");
  const [desdePuntos, setDesdePuntos] = useState(0);
  const [hastaPuntos, setHastaPuntos] = useState(0);
  const [peso, setPeso] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [tipoHabilidades, setTipoHabilidades] = useState([]);

  useEffect(() => {
    async function onLoad() {
      try {
        const tipoHabilidades = await API.getTipoHabilidadByTipoEvaluacion(
          globalVars.EVALUACION_PM_ID
        );
        setTipoHabilidades(tipoHabilidades.data.data);

        const nivelHabilidad = await API.getNivelHabilidadById(id);
        const {
          nombre,
          descripcion,
          tipoHabilidad,
          desdePuntos,
          hastaPuntos,
          peso,
        } = nivelHabilidad.data.data;
        setNombre(nombre);
        setDescripcion(descripcion);
        setTipoHabilidad(tipoHabilidad);
        setDesdePuntos(desdePuntos);
        setHastaPuntos(hastaPuntos);
        setPeso(peso);
        setNivelHabilidad(nivelHabilidad);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return nombre.length > 0 && tipoHabilidad;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (desdePuntos === "" || hastaPuntos === "" || peso === "") {
      alert("Ingrese un valor para los campos númericos");
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        nombre,
        descripcion,
        tipoHabilidad,
        desdePuntos,
        hastaPuntos,
        peso,
        activo: true,
      };
      await API.updateNivelHabilidad(id, payload);
      history.push("/nivelHabilidad");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();
    const confirmed = window.confirm("¿Desea borrar este nivel de habilidad?");
    if (!confirmed) {
      return;
    }

    setIsDeleting(true);
    try {
      await API.deactivateNivelHabilidad(id);
      history.push("/nivelHabilidad");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  return (
    <div className="NivelHabilidad">
      {nivelHabilidad && (
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="tipoHabilidad">
            <ControlLabel>Tipo de Habilidad</ControlLabel>
            <FormControl
              autoFocus
              componentClass="select"
              value={tipoHabilidad}
              onChange={(e) => setTipoHabilidad(e.target.value)}
            >
              <option value="">Elija un tipo de habilidad</option>
              {tipoHabilidades &&
                tipoHabilidades.map((tipoHabilidad) => {
                  return (
                    <option key={tipoHabilidad._id} value={tipoHabilidad._id}>
                      {tipoHabilidad.nombre}
                    </option>
                  );
                })}
            </FormControl>
          </FormGroup>
          <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl
              type="text"
              autoComplete="off"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="descripcion">
            <ControlLabel>Descripcion</ControlLabel>
            <FormControl
              componentClass="textarea"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="peso">
            <ControlLabel>Peso</ControlLabel>
            <InputGroup>
              <FormControl
                type="number"
                step="1"
                min="1"
                max="5"
                value={peso}
                onChange={(e) => setPeso(e.target.value)}
              />
              <InputGroup.Addon>1 al 5</InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          <FormGroup controlId="desdePuntos">
            <ControlLabel>Desde</ControlLabel>
            <InputGroup>
              <FormControl
                type="number"
                step="1"
                min="0"
                max="100"
                value={desdePuntos}
                onChange={(e) => setDesdePuntos(e.target.value)}
              />
              <InputGroup.Addon>Puntos</InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          <FormGroup controlId="hastaPuntos">
            <ControlLabel>Hasta</ControlLabel>
            <InputGroup>
              <FormControl
                type="number"
                step="1"
                min="0"
                max="100"
                value={hastaPuntos}
                onChange={(e) => setHastaPuntos(e.target.value)}
              />
              <InputGroup.Addon>Puntos</InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            className="button-orange"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Guardar
          </LoaderButton>
          <LoaderButton
            block
            bsSize="large"
            bsStyle="danger"
            isLoading={isDeleting}
            onClick={handleDelete}
          >
            Borrar
          </LoaderButton>
        </form>
      )}
    </div>
  );
}
