import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import CreaTipoEvaluacion from "./containers/tipoEvaluacion/CreaTipoEvaluacion";
import ConsultaTipoEvaluaciones from "./containers/tipoEvaluacion/ConsultaTipoEvaluaciones";
import TipoEvaluacion from "./containers/tipoEvaluacion/ConsultaTipoEvaluacion";
import CreaNivelAcuerdo from "./containers/nivelAcuerdo/CreaNivelAcuerdo";
import ConsultaNivelAcuerdos from "./containers/nivelAcuerdo/ConsultaNivelAcuerdos";
import ConsultaNivelAcuerdo from "./containers/nivelAcuerdo/ConsultaNivelAcuerdo";
import CreaTipoHabilidad from "./containers/tipoHabilidad/CreaTipoHabilidad";
import ConsultaTipoHabilidades from "./containers/tipoHabilidad/ConsultaTipoHabilidades";
import ConsultaTipoHabilidad from "./containers/tipoHabilidad/ConsultaTipoHabilidad";
import CreaNivelMadurez from "./containers/nivelMadurez/CreaNivelMadurez";
import ConsultaNivelesMadurez from "./containers/nivelMadurez/ConsultaNivelesMadurez";
import ConsultaNivelMadurez from "./containers/nivelMadurez/ConsultaNivelMadurez";
import CreaSemaforo from "./containers/semaforo/CreaSemaforo";
import ConsultaSemaforos from "./containers/semaforo/ConsultaSemaforos";
import ConsultaSemaforo from "./containers/semaforo/ConsultaSemaforo";
import CreaCriterio from "./containers/criterio/CreaCriterio";
import ConsultaCriterios from "./containers/criterio/ConsultaCriterios";
import ConsultaCriterio from "./containers/criterio/ConsultaCriterio";
import CreaNivelHabilidad from "./containers/nivelHabilidad/CreaNivelHabilidad";
import ConsultaNivelesHabilidad from "./containers/nivelHabilidad/ConsultaNivelesHabilidad";
import ConsultaNivelHabilidad from "./containers/nivelHabilidad/ConsultaNivelHabilidad";
import CreaProducto from "./containers/producto/CreaProducto";
import ConsultaProductos from "./containers/producto/ConsultaProductos";
import ConsultaProducto from "./containers/producto/ConsultaProducto";
import Settings from "./containers/settings/Settings";
import PerfilPM from "./containers/herramientas/PerfilPM";
import MadurezCronograma from "./containers/herramientas/MadurezCronograma";
import SaludProyecto from "./containers/herramientas/SaludProyecto";
import PerfilPMConductual from "./containers/herramientas/PerfilPMConductual";
import EstiloLiderazgoTareaPersona from "./containers/herramientas/EstiloLiderazgoTareaPersona";
import EstiloLiderazgo from "./containers/herramientas/EstiloLiderazgo";
import PracticasGerenciales from "./containers/herramientas/PracticaGerencial";
import ResolucionProblema from "./containers/herramientas/ResolucionProblema";
import Pago from "./containers/settings/Pago";
import PagoEnLinea from "./containers/settings/PagoEnLinea";
import Reverso from "./containers/settings/Reverso";
import AsignarDescuento from "./containers/descuentos/AsignarDescuento";
import CreaCurso from "./containers/curso/CreaCurso";
import ConsultaCursos from "./containers/curso/ConsultaCursos";
import ConsultaCurso from "./containers/curso/ConsultaCurso";
import SubirCertificado from "./containers/certificados/SubirCertificado";
import ConsultaCertificados from "./containers/certificados/ConsultaCertificados";
import ConsultaCertificado from "./containers/certificados/ConsultaCertificado";
import ImprimirCertificadoCorporativo from "./containers/certificados/ImprimirCertificadoCorporativo";
import ImprimirCertificadoWebinar from "./containers/certificados/ImprimirCertificadoWebinar";
import ImprimirCertificadoInternacional from "./containers/certificados/ImprimirCertificadoInternacional";
import ImprimirCertificadoWorkshop from "./containers/certificados/ImprimirCertificadoWorkshop";
import ExportarRespuestas from "./containers/consultas/RespuestasBruto";
import PersonaByLibro from "./containers/consultas/PersonaByLibro";
import EditHerramienta from "./containers/personas/EditaHerramienta";

import ResetPassword from "./containers/ResetPassword";
import ChangePassword from "./containers/ChangePassword";

import PersonaByHerramienta from "./containers/consultas/PersonaByHerramienta";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup/:email">
        <Signup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/password">
        <ChangePassword />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/tipoEvaluacion/new">
        <CreaTipoEvaluacion />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/tipoEvaluacion/:id">
        <TipoEvaluacion />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/tipoEvaluacion">
        <ConsultaTipoEvaluaciones />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/nivelAcuerdo/new">
        <CreaNivelAcuerdo />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/nivelAcuerdo/:id">
        <ConsultaNivelAcuerdo />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/nivelAcuerdo">
        <ConsultaNivelAcuerdos />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/tipoHabilidad/new">
        <CreaTipoHabilidad />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/tipoHabilidad/:id">
        <ConsultaTipoHabilidad />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/tipoHabilidad">
        <ConsultaTipoHabilidades />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/nivelMadurez/new">
        <CreaNivelMadurez />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/nivelMadurez/:id">
        <ConsultaNivelMadurez />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/nivelMadurez">
        <ConsultaNivelesMadurez />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/semaforo/new">
        <CreaSemaforo />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/semaforo/:id">
        <ConsultaSemaforo />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/semaforo">
        <ConsultaSemaforos />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/criterio/new">
        <CreaCriterio />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/criterio/:id">
        <ConsultaCriterio />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/criterio">
        <ConsultaCriterios />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/nivelHabilidad/new">
        <CreaNivelHabilidad />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/nivelHabilidad/:id">
        <ConsultaNivelHabilidad />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/nivelHabilidad">
        <ConsultaNivelesHabilidad />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/producto/new">
        <CreaProducto />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/producto/:id">
        <ConsultaProducto />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/producto">
        <ConsultaProductos />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/perfilDirectorProyecto">
        <PerfilPM />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/madurezCronograma">
        <MadurezCronograma />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/saludProyecto">
        <SaludProyecto />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/perfilDirectorProyectoConductual">
        <PerfilPMConductual />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/estiloLiderazgoTareaPersona">
        <EstiloLiderazgoTareaPersona />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/estiloLiderazgo">
        <EstiloLiderazgo />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/practicasGerenciales">
        <PracticasGerenciales />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/resolucionProblemas">
        <ResolucionProblema />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/adquirirHerramienta">
        <Pago />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/adquirirHerramientaEnLinea/:id">
        <PagoEnLinea />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/adquirirHerramientaEnLinea">
        <PagoEnLinea />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/devoluciones">
        <Reverso />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/descuento/new">
        <AsignarDescuento />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/curso/new">
        <CreaCurso />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/curso">
        <ConsultaCursos />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/curso/:id">
        <ConsultaCurso />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/certificados/upload">
        <SubirCertificado />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/certificados">
        <ConsultaCertificados />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/certificados/:id">
        <ConsultaCertificado />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/certificados/corporativo/:id">
        <ImprimirCertificadoCorporativo />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/certificados/webinar/:id">
        <ImprimirCertificadoWebinar />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/certificados/internacional/:id">
        <ImprimirCertificadoInternacional />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/certificados/workshop/:id">
        <ImprimirCertificadoWorkshop />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/consulta/usuarioPorHerramienta">
        <PersonaByHerramienta />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/consulta/exportarRespuesta">
        <ExportarRespuestas />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/consulta/usuarioPorLibro">
        <PersonaByLibro />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/persona/:id">
        <EditHerramienta />
      </AuthenticatedRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
