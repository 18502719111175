import React , { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import config from "../../config";
import { Elements, StripeProvider } from "react-stripe-elements";
import BillingForm from "../../components/BillingForm";
import "./Settings.css";

export default function Settings() {
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ stripe, setStripe ] = useState(null);

    useEffect(() => {
        setStripe(window.Stripe(config.STRIPE_KEY));
    }, []);

    async function billUser(details) {
        const payload = { cantidad: details.items, token: details.token };
        await API.billing(payload);
    }

    async function handleFormSubmit(items, { token, error }){
        if (error) {
            onError(error);
            return;
        }

        setIsLoading(true);

        try {
            await billUser({
                items,
                token: token.id
            });
                
            alert("Su pago se proceso con éxito");
            history.push("/")
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }

    return (
        <div className="Settings">
        <StripeProvider stripe={stripe}>
            <Elements>
            <BillingForm isLoading={isLoading} onSubmit={handleFormSubmit} />
            </Elements>
        </StripeProvider>
        </div>
    );
}