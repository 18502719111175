import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, ListGroup, ListGroupItem} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import "./ConsultaCursos.css";

export default function ConsultaCurso() {
    const [ cursos, setCursos ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const { isAuthenticated } = useAppContext();
    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        async function onLoad() {
            setIsLoading(true);
            try {
                const cursos = await API.getCursoActivo();
                setCursos(cursos.data.data);
            } catch(e) {
                onError(e);
            }
            setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated]);

    function renderCursoList(cursos) {
        return [{}].concat(cursos).map((curso, i) => 
            i !== 0 ? (
                <LinkContainer key={curso._id} to={`/curso/${curso._id}`}>
                    <ListGroupItem header={`${curso.nombre} - Modalidad: ${curso.tipoCurso.nombre}`}>
                        Desde: {curso.fechaInicio.split('T')[0]} Hasta: {curso.fechaFin ? curso.fechaFin.split('T')[0] : "N/A"}
                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to="/curso/new">
                    <ListGroupItem>
                        <h4>
                        <b>{"\uFF0B"}</b> Crear curso
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }


    function renderCurso() {
        return (
            <div className="Curso">
            <PageHeader>Cursos</PageHeader>
            <ListGroup>
            {!isLoading && renderCursoList(cursos)}
            </ListGroup>
            </div>
        );
    }

    return (
        <div>
        {isAuthenticated ? renderCurso() : history.push("/") }
        </div>
    )

}