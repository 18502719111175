import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem, FormGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaTipoHabilidades.css";

export default function ConsultaTipoHabilidad() {
    const [ tipoHabilidades, setTipoHabilidades] = useState([]);
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [ isLoadingTipoEvaluacion, setIsLoadingTipoEvaluacion ] = useState(true);
    const [ isLoadingTipoHabilidad, setIsLoadingTipoHabilidad ] = useState(true);
    const history = useHistory();

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }

            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);
            } catch(e) {
                onError(e);
            }

            setIsLoadingTipoEvaluacion(false);
            setIsLoadingTipoHabilidad(false);
        }

        onLoad();
    }, [isAuthenticated]);

    async function handleSelectTipoEvaluacion(event) {
        const tipoEvaluacion = event.target.value;

        setIsLoadingTipoHabilidad(true);
        try {
            const tipoHabilidades = await API.getTipoHabilidadByTipoEvaluacion(tipoEvaluacion);
            setTipoHabilidades(tipoHabilidades.data.data);
        } catch(e) {
            setTipoHabilidades([]);
        }
        setIsLoadingTipoHabilidad(false);
    }

    function renderTipoHabilidadList(tipoHabilidades) {
        return [{}].concat(tipoHabilidades).map((tipoHabilidad, i) => 
            i !== 0 ? (
                <LinkContainer key={tipoHabilidad._id} to={`/tipoHabilidad/${tipoHabilidad._id}`}>
                    <ListGroupItem header={tipoHabilidad.nombre}>
                        {tipoHabilidad.descripcion}
                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to={`/tipoHabilidad/new`}>
                    <ListGroupItem>
                        <h4>
                        <b>{"\uFF0B"}</b> Crear tipo de habilidad
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    function renderTipoHabilidades() {
        return (
            <div className="TipoHabilidades">
            <PageHeader>Tipos de habilidades</PageHeader>
            <FormGroup controlId="tipoEvaluacion">
            <FormControl 
                componentClass="select"
                placeholder="select"
                onChange={handleSelectTipoEvaluacion}
            >
                <option value="0">Elija un tipo de evaluación</option>
                {!isLoadingTipoEvaluacion && tipoEvaluaciones.map(tipoEvaluacion => {
                    return (
                        <option key={tipoEvaluacion._id} value={tipoEvaluacion._id}>
                            {tipoEvaluacion.nombre}
                        </option>
                    );
                })}
            </FormControl>
            </FormGroup>
            <ListGroup>
            {!isLoadingTipoHabilidad && renderTipoHabilidadList(tipoHabilidades)}
            </ListGroup>
            </div>
        );
    }

    return (
        <div>
        {isAuthenticated ? renderTipoHabilidades() : history.push("/") }
        </div>
    );
}