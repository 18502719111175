import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./ModalTerminos.css";

export class ModalTerminos extends React.Component {
    render() {
      return (
        <Modal
          {...this.props}
          // bsSize="large"
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">Términos y Condiciones</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            Estimado cliente y/o usuario, lea detenidamente el presente documento, dado que al ingresar o adquirir cualquiera de nuestros productos o servicios por medio del presente sitio web, el cliente y/o usuario admite haber leído y entendido estos Términos de Uso y está de acuerdo en sujetarse a los mismos y cumplir con todas las leyes y reglamentos aplicables que forman parte de la Legislación de Ecuador.
            <br/><br/>
            Cliente y/o usuario le informamos que estos términos y condiciones se pueden actualizar y cualquier cambio se ejecutará inmediatamente mediante la actualización de esta página web. Recuerde revisar nuestros términos y condiciones actuales cada vez que vaya a realizar un pago.
            <br/><br/>
            DIPROMACOM S.A en su línea de negocio ESCUELA DE PROYECTOS agradece al cliente y/o usuario que no utilice este servicio en línea si no está de acuerdo y no acepta los términos y condiciones carácter obligatorio y vinculante que se detallan a continuación:
            </p>

            <br/>
            <h4>1. LAS PARTES</h4>
            <p>CLIENTE Y/O USUARIO: Persona natural o jurídica que accede a esta página web <a href="http://www.escueladeproyectos.dipromacom.net">www.escueladeproyectos.dipromacom.net</a>
            <br/><br/>
            DIPROMACOM S.A en su línea de negocio ESCUELA DE PROYECTOS asumirá de buena fe que el cliente y/o usuario que está efectuando la compra del producto o servicio constituye directamente al cliente y / o usuario o con su representa legal debidamente facultado para efectuar este tipo de operaciones y ninguna responsabilidad deberá asumir DIPROMACOM S.A en su línea de negocio ESCUELA DE PROYECTOS por falsificación personal que realice cualquier cliente y/o usuario. 
            </p>

            <br/>
            <h4>2. REGISTRO Y USO DEL SITIO</h4>
            <p>
            El Cliente que desee adquirir algún producto, hacer uso de los servicios en línea, beneficios especiales, promociones, opciones de pago, entre otros; tiene la opción de registrarse como Usuario de ESCUELA DE PROYECTOS, para lo cual deberá completar previamente un proceso de registro con el cual podrá inscribir una dirección de correo personal y generar una contraseña de acceso permanente.
            <br/><br/>
            Al realizar la inscripción se le solicitará al Cliente completar campos de registro, mismos que contendrán datos obligatorios, tales como: Información de contacto, número de identificación, fecha de nacimiento, número de teléfono y correo electrónico, entre otros.
            <br/><br/>
            Para poder hacer realizar una compra de nuestros productos y servicios a través del Sitio web, el Cliente debe ser mayor de edad y estar en capacidad legal para contratar según las estipulaciones vigentes de la ley ecuatoriana. Caso contrario no deberá efectuar ninguna transacción en este sitio.
            <br/><br/>
            La confidencialidad de la contraseña y correo personal del Cliente es de uso personal y de su entera responsabilidad, así como la información a la que se tenga acceso mediante el uso de la misma, Por tal razón su entrega a terceros no involucra responsabilidad de DIPROMACOM S.A en su línea de negocio ESCUELA DE PROYECTOS, en caso de mala utilización y/o divulgación de información del Cliente que no mantenga la confidencialidad de dicha información.
            <br/><br/>
            El Usuario se compromete a no utilizar ningún dispositivo, software, ni datos para obstruir el funcionamiento correcto del Sitio y de sus contenidos y de las actividades realizadas en el mismo.
            </p>

            <br/>
            <h4>3. CONDICIONES DE PAGO</h4>
            <p>
            DIPROMACOM S.A en su línea de negocio ESCUELA DE PROYECTOS, no se hace responsable si el pago es rechazado o negado por el emisor de la tarjeta por cualquier circunstancia. Si el emisor de la tarjeta rechaza el pago DIPROMACOM S.A en su línea de negocio ESCUELA DE PROYECTOS no tiene ninguna obligación de informar sobre este hecho al titular de la tarjeta, al estudiante o cliente. 
            </p>

            <br/>
            <h4>4. MÉTODOS DE PAGO</h4>
            <p>
            El pago se efectuará única y exclusivamente por los siguientes medios:
            <br/><br/>
            1.  Tarjetas crédito<br/>
            2.  Tarjetas débito
            <br/><br/>
            Para su seguridad de nuestros clientes, todos los datos de la tarjeta de crédito se ingresan directamente en la página web de Paymentez, Ecuador, sitio al que será direccionado al hacer clic en el botón “Pagar”, una vez seleccionado el producto o servicio que desee adquirir. Esta página web cuenta con seguridad PCI DSS, el cual brinda seguridad para que sus datos privados sean encriptados previo al envío.
            </p>

            <br/>
            <h4>5. PROTECCION DE DATOS</h4>
            <p>
            Al momento de la inscripción en el sitio, el Cliente declara que conoce y autoriza de manera libre, previa, voluntaria, expresa y debidamente informada para recolectar, registrar, procesar, compilar, intercambiar, actualizar y disponer para los fines propios e inherentes a la prestación del servicio, de los datos personales suministrados al momento de registro en el sitio
            <br/><br/>
            Sus datos personales no serán vendidos o cedidos a terceros, serán tratados con la debida confidencialidad.
            </p>

            <br/>
            <h4>6. COMENTARIOS</h4>
            <p>
            DIPROMACOM S.A en su línea de negocio ESCUELA DE PROYECTOS se reserva el derecho de publicar o abstenerse de publicar cualquiera comentario realizado por un cliente y/o usuario de nuestra página web dirigido a nuestros productos o servicios. Estableciendo que DIPROMACOM S.A en su línea de negocio ESCUELA DE PROYECTOS podrá eliminar un comentario efectuado por un cliente y/o usuario de la página web mencionada. <a href="http://www.escueladeproyectos.dipromacom.net">www.escueladeproyectos.dipromacom.net</a>
            </p>


          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onHide}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
  