import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  PageHeader,
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import API from "../api";
import "./Login.css";

export default function Login() {
  const history = useHistory();
  const { userHasAuthenticated, setToken, setRole, setHerramientas } =
    useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  const [urlParams, setUrlParams] = useState("");

  useEffect(() => {
    async function onLoad() {
      const url = window.location.href;
      const params = url.split("?");
      if (params[1] !== undefined) setUrlParams(params[1]);
    }

    onLoad();
  }, []);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    let login = false;
    try {
      const result = await Auth.signIn(fields.email, fields.password);
      login = true;

      const loginResult = await API.login(
        {},
        {
          auth: {
            username: result.username,
            password: result.signInUserSession.accessToken.jwtToken,
          },
        }
      );

      setToken(loginResult.data.token);
      setRole(loginResult.data.role);
      setHerramientas(loginResult.data.herramientas);
      userHasAuthenticated(true);

      // window.heap.identify(loginResult.data.token);
      // window.heap.addUserProperties({ 'role': loginResult.data.role });
    } catch (e) {
      if (login) {
        await Auth.signOut();
        userHasAuthenticated(false);
      }

      setIsLoading(false);
      if (e.code === "NotAuthorizedException") {
        alert("Usuario o contraseña incorrectos");
      } else if (e.code === "UserNotConfirmedException") {
        history.push(`/signup/${fields.email}`);
      } else {
        alert("Ocurrio un problema, intentelo nuevamente");
      }
    }
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <PageHeader>Iniciar Sesión</PageHeader>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            autoComplete="off"
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Contraseña</ControlLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <Link to="/login/reset">Olvide mi contraseña</Link>

        <LoaderButton
          block
          className="button-orange"
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Iniciar Sesión
        </LoaderButton>

        <br />
        <br />
        <p>
          <b>NOTA: </b>
        </p>
        {urlParams !== "" ? (
          <Link to={"/signup?" + urlParams}>
            Si no posees un usuario, puedes crear uno aquí
          </Link>
        ) : (
          <Link to="/signup">
            Si no posees un usuario, puedes crear uno aquí
          </Link>
        )}
      </form>
    </div>
  );
}
