import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, Form, Col } from "react-bootstrap";
import { onError } from "../../libs/errorLib";
import { formatDate } from "../../libs/dateLib";
import API from "../../api";
import ReactTable from "react-table-v6";
import LoaderButton from "../../components/LoaderButton";

import "react-table-v6/react-table.css";

import "./ConsultaCertificado.css";

export default function ConsultaCurso() {
    const { id } = useParams();
    const history = useHistory();
    const [ nombre, setNombre ] = useState("");
    const [ descripcion, setDescripcion ] = useState("");
    const [ detalle, setDetalle ] = useState([]);
    const [ formattedDate, setFormattedDate ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    const columns = [
        {
            Header: "Nombre",
            accessor: "nombre",
            filterable: true
        },
        {
            Header: "Apellido",
            accessor: "apellido",
            filterable: true
        },
        {
            Header: "Email",
            accessor: "email",
            filterable: true
        },
        {
            Header: "Curso",
            accessor: "curso.nombre",
            filterable: true
        },
        {
            Heder: "Action",
            Cell: function(props) {
                const value = props.original;
                return (
                    <a href={`${value.curso.tipoCurso.url}/${value._id}`} className="center-text">
                    <div>Imprimir</div>
                    </a>
                )
            }
        }
    ]


    useEffect(() => {

        async function onLoad() {
            setIsLoading(true);
            try {
                const certificado = await API.getCertificadoById(id);
                const { nombre, descripcion, fecha, detalle } = certificado.data.data;
                setNombre(nombre);
                setDescripcion(descripcion);
                setFormattedDate(formatDate(fecha));
                setDetalle(detalle);
            } catch(e) {
                onError(e);
            }
            setIsLoading(false);
        }

        onLoad();
    }, [id]);

    async function handleDelete(event) {
        event.preventDefault();
        const confirmed = window.confirm("¿Desea desactivar este grupo de certificados?");
        if (!confirmed) {
            return;
        }
        setIsDeleting(true);

        try {
            await API.desactivateCertificadoById(id);
            history.push("/certificados");
        } catch(e) {
            onError(e);
            setIsDeleting(false);
        }
    }

    return (
        <div className="Certificado">
            <Form horizontal>
            <FormGroup controlId="nombre">
            <Col componentClass={ControlLabel} sm={2}>
            Nombre
            </Col>
            <Col sm={4}>
            <FormControl.Static>{nombre}</FormControl.Static>
            </Col>
            {' '}
            <Col componentClass={ControlLabel} sm={2}>
            Fecha de subida
            </Col>
            <Col sm={4}>
            <FormControl.Static>{formattedDate}</FormControl.Static>
            </Col>
            </FormGroup>

            <FormGroup controlId="descripcion">
            <Col componentClass={ControlLabel} sm={2}>
            Descripción
            </Col>
            <Col sm={10}>
            <FormControl.Static>{descripcion}</FormControl.Static>
            </Col>
            </FormGroup> 

            <>
            {
                detalle.length > 0 &&
                    <ReactTable 
                        data={detalle}
                        columns={columns}
                        loading={isLoading}
                        defaultPageSize={20}
                        showPageSizeOptions={true}
                        minRows={0}
                    />
            }
            </>

            <br />
            <LoaderButton
                block
                bsSize="large"
                bsStyle="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
            >
                Desactivar certificados
            </LoaderButton>

            </Form>
        </div>
    );
}