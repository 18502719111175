import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, Checkbox } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaCriterio.css";

export default function ConsultaTipoHabilidad() {
    const { id } = useParams();
    const history = useHistory();
    const [ criterio, setCriterio ] = useState(null);
    const [ descripcion, setDescripcion ] = useState("");
    const [ requeridoParaExcelencia, setRequeridoParaExcelencia ] = useState(false);
    const [ tipoHabilidad, setTipoHabilidad ] = useState("");
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const [ tipoHabilidades, setTipoHabilidades ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);
    const [ tipoEvaluacion, setTipoEvaluacion ] = useState("");

    useEffect(() => {
        async function onLoad() {
            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);

                const criterio = await API.getCriterioById(id);
                const { descripcion, tipoHabilidad: tipoHabilidadId, requeridoParaExcelencia } = criterio.data.data;
                setDescripcion(descripcion);
                setTipoHabilidad(tipoHabilidadId);
                setCriterio(criterio);
                setRequeridoParaExcelencia(requeridoParaExcelencia);

                const tipoHabilidadObj = await API.getTipoHabilidadById(tipoHabilidadId);
                const tipoEvaluacionId = tipoHabilidadObj.data.data.tipoEvaluacion;
                setTipoEvaluacion(tipoEvaluacionId);

                const tipoHabilidadesArr = await API.getTipoHabilidadByTipoEvaluacion(tipoEvaluacionId);
                setTipoHabilidades(tipoHabilidadesArr.data.data);
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    function validateForm() {
        return (descripcion.length > 0 && tipoHabilidad);
    }

    async function handleSelecTipoEvaluacion(event) {
        const tipoEvaluacion = event.target.value;

        setTipoEvaluacion(tipoEvaluacion);
        try {
            const tipoHabilidades = await API.getTipoHabilidadByTipoEvaluacion(tipoEvaluacion);
            setTipoHabilidades(tipoHabilidades.data.data);
        } catch(e) {
            setTipoHabilidades([]);
        }
        setTipoHabilidad("");
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const payload = { descripcion, tipoHabilidad, requeridoParaExcelencia, activo: true };
            await API.updateCriterio(id, payload);
            history.push("/criterio");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleDelete(event) {
        event.preventDefault();
        const confirmed = window.confirm("¿Desea borrar este criterio?");
        if (!confirmed) {
            return;
        }
        setIsDeleting(true);

        try {
            await API.deactivateCriterio(id);
            history.push("/criterio");
        } catch(e) {
            onError(e);
            setIsDeleting(false);
        }
    }

    function handleCheck(event) {
        let required = requeridoParaExcelencia;
        required = !required;
        setRequeridoParaExcelencia(required);
    }

    return (
        <div className="Criterio">
            { criterio && (
            <form onSubmit={handleSubmit}>
            <FormGroup controlId="tipoEvaluacion">
            <ControlLabel>Tipo de Evaluación</ControlLabel>
            <FormControl
                autoFocus
                componentClass="select"
                placeholder="select"
                value={tipoEvaluacion}
                onChange={handleSelecTipoEvaluacion}
            >
                <option value="">Elija un tipo de evaluación</option>
                {
                    tipoEvaluaciones && tipoEvaluaciones.map((tipoEvaluacion) => {
                        return (
                            <option
                                key={tipoEvaluacion._id}
                                value={tipoEvaluacion._id}
                            >
                            {tipoEvaluacion.nombre}
                            </option>
                        )
                    })
                }
            </FormControl>
            </FormGroup>
            <FormGroup controlId="tipoHabilidad">
            <ControlLabel>Tipo de Habilidad</ControlLabel>
            <FormControl
                componentClass="select"
                placeholder="select"
                value={tipoHabilidad}
                onChange={ e => setTipoHabilidad(e.target.value) }
            >
                <option value="">Elija un tipo de habilidad</option>
                {
                    tipoHabilidades && tipoHabilidades.map((tipoHabilidad) => {
                        return (
                            <option
                                key={tipoHabilidad._id}
                                value={tipoHabilidad._id}
                            >
                            {tipoHabilidad.nombre}
                            </option>
                        )
                    })
                }
            </FormControl>
            </FormGroup>
            <FormGroup controlId="descripcion">
            <ControlLabel>Descripción</ControlLabel>
            <FormControl
                componentClass="textarea"
                value={descripcion}
                onChange={ e => setDescripcion(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="requeridoParaExcelencia">
            <Checkbox
                checked={requeridoParaExcelencia}
                onChange={handleCheck}
            >Requerido para Excelencia</Checkbox>
            </FormGroup>

            <LoaderButton
                block
                bsSize="large"
                className="button-orange"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Guardar
            </LoaderButton>
            <LoaderButton
                block
                bsSize="large"
                bsStyle="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
            >
                Borrar
            </LoaderButton>
            </form>
            )}
        </div>
    );
}