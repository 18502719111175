import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, Col } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import DatePicker from "react-date-picker";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import "./ConsultaCurso.css";

export default function ConsultaCurso() {
    const { id } = useParams();
    const history = useHistory();
    const [ nombre, setNombre ] = useState("");
    const [ tipoCursos, setTipoCursos ] = useState([]);
    const [ tipoCurso, setTipoCurso] = useState("");
    const [ modalidad, setModalidad ] = useState("");
    const [ fechaInicio, setFechaInicio ] = useState(new Date());
    const [ fechaFin, setFechaFin ] = useState(new Date());
    const [ duraciones, setDuraciones ] = useState([]);
    const [ duracion, setDuracion ] = useState(0);
    const [ unidad, setUnidad ] = useState("");
    const [ tipo, setTipo ] = useState("");
    const [ leyenda1, setLeyenda1 ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);
    const [ selected, setSelected ] = useState([]);

    useEffect(() => {

        async function onLoad() {
            try {
                const tipoCursos = await API.getTipoCursoActivo();
                setTipoCursos(tipoCursos.data.data);

                const curso = await API.getCursoById(id);
                const { nombre, tipoCurso, fechaInicio, fechaFin, modalidad, duracion, leyenda1 } = curso.data.data;
                setNombre(nombre);
                setTipoCurso(tipoCurso);
                setModalidad(modalidad);
                setFechaInicio(new Date(fechaInicio));
                setFechaFin(null);

                if (fechaFin) {
                    setFechaFin(new Date(fechaFin));
                }
                
                if (leyenda1) {
                    setLeyenda1(leyenda1);
                }


                const duracionArr = duracion.map(item => {
                    item.value = item.duracion + item.unidad + item.tipo;
                    return item;
                })
                setDuraciones(duracionArr);
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);


    function validateForm() {
        return (nombre && modalidad && duraciones.length)
    }

    function validateAgregar() {
        return (duracion && unidad && tipo);
    }

    function validateQuitar() {
        return (duraciones.length);
    }

    function agregarDuracion(event) {
        event.preventDefault();

        let item = {};
        item.value = duracion + unidad + tipo; 
        let duracionArr = duraciones.length ? duraciones : [];

        let exist = duracionArr.filter(duracion => duracion.value === item.value);
        if (exist.length > 0) {
            return;
        }

        item.duracion = duracion;
        item.unidad = unidad;
        item.tipo = tipo;
        duracionArr.push(item);
        setDuraciones(duracionArr);

        setDuracion(0);
        setUnidad("");
        setTipo("");
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);
        try {
            const payload = {
                nombre, tipoCurso, fechaInicio, fechaFin, modalidad, duracion: duraciones, activo: true, leyenda1
            };
            await API.updateCurso(id, payload);
            history.push("/curso");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }

    }

    async function handleDelete(event) {
        event.preventDefault();
        const confirmed = window.confirm("¿Desea borrar este curso?")
        if (!confirmed) {
            return;
        }

        setIsDeleting(true);
        try {
            await API.deactivateCurso(id);
            history.push("/curso");
        } catch(e) {
            onError(e);
            setIsDeleting(false);
        }
    }
    
    function onMultipleChange(event) {
        const options = event.target.selectedOptions;
        const selected = [...options].map(option => option.value);
        setSelected(selected);
    }

    function quitarDuracion(event) {
        const duracionArr = [];
        for (let i = 0; i < duraciones.length; i++) {
            const duracion = duraciones[i];
            let agregar = true;
            for (let j = 0; j < selected.length; j++) {
                const select = selected[j];
                if (duracion.value === select) {
                    agregar = false;
                    break;
                }
            }

            if (agregar) {
                duracionArr.push(duracion);
            }
        }

        setDuraciones(duracionArr);
    }

    return (
        <div className="Curso">
            <form onSubmit={handleSubmit}>
            <FormGroup controlId="id">
            <ControlLabel>Id</ControlLabel>
            <FormControl.Static>{id}</FormControl.Static>
            </FormGroup>

            <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl
                autoFocus
                type="text"
                autoComplete="off"
                value={nombre}
                onChange={ e => setNombre(e.target.value)}
            />
            </FormGroup>

            <FormGroup controlId="tipoCurso">
            <ControlLabel>Tipo de Curso</ControlLabel>
            <FormControl
                componentClass="select"
                value={tipoCurso}
                onChange={ e => setTipoCurso(e.target.value)}
            >
                <option value="">Elija un tipo de curso</option>
                {
                    tipoCursos.map(tipoCurso => {
                        return (
                            <option
                                key={tipoCurso._id}
                                value={tipoCurso._id}
                            >
                                {tipoCurso.nombre}
                            </option>
                        )
                    })
                }
            </FormControl>
            </FormGroup>

            <FormGroup controlId="modalidad">
            <ControlLabel>Modalidad</ControlLabel>
            <FormControl
                componentClass="select"
                value={modalidad}
                onChange={ e => setModalidad(e.target.value)}
            >
                <option value="">Elija un tipo de modalidad</option>
                <option value="online">Online</option>
                <option value="presencial">On live</option>
            </FormControl>
            </FormGroup>

            <FormGroup controlId="fechaInicio">
            <ControlLabel>Fecha de Inicio</ControlLabel>
            <br />
            <DatePicker
                locale="es"
                onChange={ e => setFechaInicio(e)}
                value={fechaInicio}
            />
            </FormGroup>

            <FormGroup controlId="fechaFin">
            <ControlLabel>Fecha de Fin</ControlLabel>
            <br />
            <DatePicker
                locale="es"
                onChange={ e => setFechaFin(e)}
                value={fechaFin}
            />
            </FormGroup>

            <hr />
            <FormGroup controlId="duracion" className="inline-form-group">
                <Col componentClass={ControlLabel} sm={1}>
                    Duración
                </Col>
                <Col sm={11}>
                <FormControl
                    type="number"
                    step="1"
                    min="0"
                    max="100"
                    value={duracion}
                    onChange={ e => setDuracion(e.target.value)}
                />
                </Col>
            </FormGroup>

            <FormGroup controlId="unidad" className="inline-form-group">
                <Col componentClass={ControlLabel} sm={1}>
                    Tiempo
                </Col>
                <Col sm={11}>
                <FormControl
                    componentClass="select"
                    value={unidad}
                    onChange={ e => setUnidad(e.target.value)}
                >
                    <option value="">Elija una opción</option>
                    <option value="horas">Horas</option>
                    <option value="dias">Dias</option>
                    <option value="semanas">Semanas</option>
                    <option value="meses">Meses</option>
                </FormControl>
                </Col>
            </FormGroup>

            <FormGroup controlId="tipo" className="inline-form-group">
                <Col componentClass={ControlLabel} sm={1}>
                    Tipo
                </Col>
                <Col sm={11}>
                <FormControl
                    componentClass="select"
                    value={tipo}
                    onChange={ e => setTipo(e.target.value)}
                >
                    <option value="">Elija una opción</option>
                    <option value="virtual">Virtual</option>
                    <option value="de clases en vivo">De esfuerzo</option>
                    <option value="de acompañamiento">De acompañamiento</option>
                </FormControl>
                </Col>
            </FormGroup>


            <LoaderButton
                block
                bsSize="large"
                className="button-orange"
                isLoading={isLoading}
                disabled={!validateAgregar()}
                onClick={agregarDuracion}
            >
                Agregar duración
            </LoaderButton>

            <br />
            {
                duraciones.length > 0 &&
                <FormGroup controlId="duraciones">
                <ControlLabel>Duración del curso</ControlLabel>
                <FormControl componentClass="select" 
                    multiple 
                    className="multiple"
                    onChange={onMultipleChange}
                >
                {
                    duraciones.map((item, i) => (
                        <option key={item.value} value={item.value}>
                            {item.duracion} {item.unidad} {item.tipo}
                        </option>
                    ))
                }
                </FormControl>
                </FormGroup>
            }

            <LoaderButton
                block
                bsSize="large"
                bsStyle="danger"
                isLoading={isLoading}
                disabled={!validateQuitar()}
                onClick={quitarDuracion}
            >
                Quitar duración
            </LoaderButton>

            <br />
            <FormGroup controlId="nombre">
            <ControlLabel>Leyenda 1</ControlLabel>
            <FormControl
                type="text"
                autoComplete="off"
                value={leyenda1}
                onChange={ e => setLeyenda1(e.target.value)}
            />
            </FormGroup>

            <hr />
            <LoaderButton
                block
                type="submit"
                bsSize="large"
                className="button-orange"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Guardar
            </LoaderButton>

            <LoaderButton
                block
                bsSize="large"
                bsStyle="danger"
                isLoading={isDeleting}
                onClick={handleDelete}
            >
                Borrar
            </LoaderButton>
            </form>
        </div>
    );

}