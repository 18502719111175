import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
} from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import "./CreaCriterio.css";

export default function CreaCriterio() {
  const history = useHistory();
  const [descripcion, setDescripcion] = useState("");
  const [requeridoParaExcelencia, setRequeridoParaExcelencia] = useState(false);
  const [tipoEvaluaciones, setTipoEvaluaciones] = useState([]);
  const [tipoHabilidad, setTipoHabilidad] = useState("");
  const [tipoHabilidades, setTipoHabilidades] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    async function onLoad() {
      try {
        const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
        setTipoEvaluaciones(tipoEvaluaciones.data.data);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [isAuthenticated]);

  function validateForm() {
    return descripcion.length > 0 && tipoHabilidad;
  }

  async function handleSelectTipoEvaluacion(event) {
    const tipoEvaluacion = event.target.value;

    try {
      const tipoHabilidades = await API.getTipoHabilidadByTipoEvaluacion(
        tipoEvaluacion
      );
      setTipoHabilidades(tipoHabilidades.data.data);
    } catch (e) {
      setTipoHabilidades([]);
    }
    setTipoHabilidad("");
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const payload = {
        descripcion,
        tipoHabilidad,
        requeridoParaExcelencia,
        activo: true,
      };
      await API.createCriterio(payload);
      //history.push("/criterio");
      setDescripcion("");
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function handleCheck(event) {
    let required = requeridoParaExcelencia;
    required = !required;
    setRequeridoParaExcelencia(required);
  }

  return (
    <div className="NewCriterio">
      {tipoEvaluaciones && (
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="tipoEvaluacion">
            <ControlLabel>Tipo de Evaluación</ControlLabel>
            <FormControl
              autoFocus
              placeholder="select"
              componentClass="select"
              onChange={handleSelectTipoEvaluacion}
            >
              <option value="">Elija un tipo de evaluación</option>
              {tipoEvaluaciones.map((tipoEvaluacion) => {
                return (
                  <option key={tipoEvaluacion._id} value={tipoEvaluacion._id}>
                    {tipoEvaluacion.nombre}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
          <FormGroup controlId="tipoHabilidad">
            <ControlLabel>Tipo de Habilidad</ControlLabel>
            <FormControl
              placeholder="select"
              componentClass="select"
              onChange={(e) => setTipoHabilidad(e.target.value)}
            >
              <option value="">Elija un tipo de habilidad</option>
              {tipoHabilidades.map((tipoHabilidad) => {
                return (
                  <option key={tipoHabilidad._id} value={tipoHabilidad._id}>
                    {tipoHabilidad.nombre}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
          <FormGroup controlId="descripcion">
            <ControlLabel>Descripción</ControlLabel>
            <FormControl
              componentClass="textarea"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="requeridoParaExcelencia">
            <Checkbox checked={requeridoParaExcelencia} onChange={handleCheck}>
              Requerido para Excelencia
            </Checkbox>
          </FormGroup>

          <LoaderButton
            block
            type="submit"
            bsSize="large"
            className="button-orange"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Guardar
          </LoaderButton>
        </form>
      )}
    </div>
  );
}
