export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "evaluaciones-bucket"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://lz9z5nwqx8.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_abUrqTM1v",
        APP_CLIENT_ID: "2dt14nohfd8l5qj53vqb0ijp6m",
        IDENTITY_POOL_ID: "us-east-1:a7c7ac01-75fe-48de-bd93-d3c5712c906f"
    },
    STRIPE_KEY: "pk_test_2iDygKcz6vjXtdNnIw4JIdaq00tfHcM27m"
}