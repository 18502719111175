import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  PageHeader,
  Form,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Table,
  Checkbox,
} from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import { globalVars } from "../../constants";
import "./EstiloLiderazgoTareaPersona.css";
import { messages } from "../../constants";

export default function EstiloLiderazgoTareaPersona() {
  const history = useHistory();
  const [criterios, setCriterios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { isAuthenticated, setIsLoadingOverlay, token } = useAppContext();
  const [criteriosChecked, setCriteriosChecked] = useState({});
  const [empresa, setEmpresa] = useState("");
  const [cargo, setCargo] = useState("");
  const [profesion, setProfesion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [pais, setPais] = useState("");
  const [genero, setGenero] = useState("M");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [cantidad, setCantidad] = useState("0");
  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  useEffect(() => {
    async function onLoad() {
      setIsLoadingOverlay(true);
      try {
        //Obtengo los datos personales
        const payload = {
          tipoEvaluacion: globalVars.ESTILO_LIDERAZGO_TAREA_PERSONA_ID,
        };
        const persona = await API.getPermission(payload, {
          headers: { Authorization: token },
        });
        const {
          empresa,
          cargo,
          profesion,
          nombre,
          apellido,
          correo,
          telefono,
          pais,
          genero,
          cantidad,
        } = persona.data.data;

        setEmpresa(empresa === undefined ? "" : empresa);
        setCargo(cargo === undefined ? "" : cargo);
        setProfesion(profesion === undefined ? "" : profesion);
        setTelefono(telefono === undefined ? "" : telefono);
        setNombre(nombre === undefined ? "" : nombre);
        setApellido(apellido === undefined ? "" : apellido);
        setCorreo(correo === undefined ? "" : correo);
        setCantidad(cantidad === undefined ? "" : cantidad);
        setPais(pais === undefined ? "" : pais);
        setGenero(genero === undefined ? "M" : genero);

        const criterios = await API.getCriterioAgrupado(
          globalVars.ESTILO_LIDERAZGO_TAREA_PERSONA_ID
        );
        setCriterios(criterios.data.data);
        let criteriosChecked = prepareCriterioChecked(criterios.data.data);
        setCriteriosChecked(criteriosChecked);
      } catch (e) {
        if (e.response.status === 403) {
          history.push("/adquirirHerramienta");
        }
        onError(e);
        history.push("/");
      }
      setIsLoading(false);
      setIsLoadingData(false);
      setIsLoadingOverlay(false);
      setChecked(false);
    }

    onLoad();
  }, [isAuthenticated, history, token, setIsLoadingOverlay]);

  function prepareCriterioChecked(criterios) {
    //Genero un array con formato:
    //[ { id: "1", tipoHabilidad: "1", requeridoExcelencia: true }, { id: "2", tipoHabilidad: "1", requeridoExcelencia: false } ]
    let criteriosChecked = {};
    let result = criterios
      .map((tipoHabilidad) =>
        tipoHabilidad.criterios.map((criterio) => {
          return {
            id: criterio.id,
            tipoHabilidad: tipoHabilidad._id._id,
            requeridoExcelencia: criterio.requeridoExcelencia,
          };
        })
      )
      .flat();

    //Genero un Objecto con formato:
    //{ "1": { checked: false, tipoHabilidad: "1", requeridoExcelencia: true },
    //  "2": { checked: false, tipoHabilidad: "1", requeridoExcelencia: false } }
    criteriosChecked = result.reduce((map, obj) => {
      map[obj.id] = {
        checked: false,
        tipoHabilidad: obj.tipoHabilidad,
        requeridoExcelencia: obj.requeridoExcelencia,
      };
      return map;
    }, {});
    return criteriosChecked;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const confirmed = window.confirm(
      "¿Desea terminar y procesar la evaluación del Estilo de Liderazgo?"
    );
    if (!confirmed) {
      return;
    }

    setIsLoadingOverlay(true);
    setIsLoading(true);

    try {
      const payload = {
        tipoEvaluacion: globalVars.EVALUACION_PM_ID,
        nombre,
        apellido,
        correo,
        telefono,
        empresa,
        cargo,
        profesion,
        pais,
        genero,
        activo: true,
        evaluacionDetalle: criteriosChecked,
      };

      const result = await API.saveLiderazoTareaPersona(payload, {
        headers: { Authorization: token },
      });
      window.location.href = globalVars.URL + result.data.file;
    } catch (e) {
      onError(e);
    }
    setCriteriosChecked(prepareCriterioChecked(criterios));
    setIsLoading(false);
    setIsLoadingOverlay(false);
  }

  function handleCheck(event, id) {
    const criterioCheck = criteriosChecked;
    const key = id;
    const value = criterioCheck[key];
    const checked = value.checked;
    criterioCheck[key] = {
      checked: checked === true ? false : true,
      tipoHabilidad: value.tipoHabilidad,
      requeridoExcelencia: value.requeridoExcelencia,
    };
    setCriteriosChecked(criterioCheck);
  }

  function renderTable() {
    return (
      <div className="EstiloLiderazgoTareaPersona">
        <PageHeader>
          Cuestionario de Estilo de Liderazgo (tarea - persona)
        </PageHeader>
        {
          <p>
            <b>Atención: </b>{" "}
            {cantidad > 1
              ? `Tiene ${cantidad} herramientas de Estilo de Liderazo enfocado en tareas / personas.`
              : `Tiene ${cantidad} herramienta del Estilo de Liderazo enfocado en tareas / personas.`}
          </p>
        }
        <br />
        {!isLoadingData && (
          <Form horizontal onSubmit={handleSubmit}>
            <FormGroup controlId="empresa">
              <Col componentClass={ControlLabel} sm={2}>
                Empresa
              </Col>
              <Col sm={8}>
                <FormControl
                  type="text"
                  autoComplete="off"
                  value={empresa}
                  onChange={(e) => setEmpresa(e.target.value)}
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="cargo">
              <Col componentClass={ControlLabel} sm={2}>
                Cargo
              </Col>
              <Col sm={8}>
                <FormControl
                  type="text"
                  autoComplete="off"
                  value={cargo}
                  onChange={(e) => setCargo(e.target.value)}
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="profesion">
              <Col componentClass={ControlLabel} sm={2}>
                Profesión
              </Col>
              <Col sm={8}>
                <FormControl
                  type="text"
                  autoComplete="off"
                  value={profesion}
                  onChange={(e) => setProfesion(e.target.value)}
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="telefono">
              <Col componentClass={ControlLabel} sm={2}>
                Teléfono
              </Col>
              <Col sm={8}>
                <FormControl
                  type="number"
                  autoComplete="off"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="pais">
              <Col componentClass={ControlLabel} sm={2}>
                País
              </Col>
              <Col sm={8}>
                <FormControl
                  type="text"
                  autoComplete="off"
                  value={pais}
                  onChange={(e) => setPais(e.target.value)}
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="genero">
              <Col componentClass={ControlLabel} sm={2}>
                Género
              </Col>
              <Col sm={8}>
                <FormControl
                  componentClass="select"
                  placeholder="Elija su género"
                  value={genero}
                  onChange={(e) => setGenero(e.target.value)}
                >
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </FormControl>
              </Col>
            </FormGroup>
            {!isLoading && (
              <>
                <br />
                <p>
                  <b>Instrucciones:</b> Dar visto en el criterio en el cual
                  usted se considera competente.
                </p>
                <br />
                <Table hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Criterios</th>
                      <th>SI/NO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {criterios.map(({ _id, criterios }, i) => {
                      const headerRow = [
                        <tr key={_id._id}>
                          <td colSpan="3" className="orange">
                            <b>{_id.nombre}</b>
                          </td>
                        </tr>,
                      ];

                      const criterioRow = criterios.map((criterio, j) => {
                        return (
                          <tr key={criterio.id}>
                            <td>{j + 1}</td>
                            <td>{criterio.descripcion}</td>
                            <td>
                              <Checkbox
                                bsClass="checkbox-large"
                                value={criteriosChecked[criterio.id].checked}
                                onChange={(e) => handleCheck(e, criterio.id)}
                              />
                            </td>
                          </tr>
                        );
                      });

                      return headerRow.concat(criterioRow);
                    })}
                  </tbody>
                </Table>

                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />{" "}
                    {messages.TERMS_AND_CONDICTIONS}
                  </label>
                </div>

                <LoaderButton
                  block
                  type="submit"
                  className="button-orange"
                  bsSize="large"
                  isLoading={isLoading}
                  disabled={!isChecked}
                >
                  Enviar
                </LoaderButton>

                <br />
                <p>
                  <b>Desarrollado por: </b>
                  <br />
                  Nathalie Rosero, PMP, PMO-CP
                  <br />
                  José Luis González, PMP, PMI-RMP, PMO-CP
                </p>
              </>
            )}
          </Form>
        )}
      </div>
    );
  }

  return <div>{isAuthenticated ? renderTable() : history.push("/")}</div>;
}
