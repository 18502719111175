import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import "./CreaTipoHabilidad.css";

export default function CreaTipoHabilidad() {
    const history = useHistory();
    const [ nombre, setNombre ] = useState("");
    const [ descripcion, setDescripcion ] = useState("");
    const [ tipoEvaluacion, setTipoEvaluacion ] = useState("");
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const { isAuthenticated } = useAppContext();


    useEffect(()=> {
        async function onLoad() {
            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, [isAuthenticated]);

    function validateForm() {
        return (nombre.length > 0 && tipoEvaluacion);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            const payload = { nombre, descripcion, tipoEvaluacion, activo: true };
            await API.createTipoHabilidad(payload);
            history.push("/tipoHabilidad");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }


    return (
        <div className="NewTipoHabilidad">
        {tipoEvaluaciones && (
            <form onSubmit={handleSubmit} >
            <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl 
                autoFocus
                type="text"
                autoComplete="off"
                value={nombre}
                onChange={ e => setNombre(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="descripcion">
            <ControlLabel>Descripcion</ControlLabel>
            <FormControl 
                value={descripcion}
                componentClass="textarea"
                onChange={ e => setDescripcion(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="tipoEvaluacion">
            <ControlLabel>Tipo de Evaluación</ControlLabel>
            <FormControl 
                placeholder="select"
                componentClass="select"
                onChange={ e=> setTipoEvaluacion(e.target.value)}>
                <option value="">Elija un tipo de evaluación</option>
                { 
                    tipoEvaluaciones.map((tipoEvaluacion) => {
                        return (
                            <option 
                                key={tipoEvaluacion._id}
                                value={tipoEvaluacion._id}
                            >
                                {tipoEvaluacion.nombre}
                            </option>);
                    })
                }
            </FormControl>
            </FormGroup>
            <LoaderButton
                block
                className="button-orange"
                type="submit"
                bsSize="large"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Guardar
            </LoaderButton>
            </form>
        )}
        </div>
    );

}