import React, { useState, useEffect } from "react";
import { PageHeader, FormGroup, FormControl } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import  ReactTable from "react-table-v6";

import "react-table-v6/react-table.css";
import "./PersonaByHerramienta.css";

export default function PersonaByHerramienta() {
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const [ tipoEvaluacion, setTipoEvaluacion ] = useState("");
    const [ personas, setPersonas ] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [ isLoadingTipoEvaluacion, setIsLoadingTipoEvaluacion ] = useState(true);
    const [ isLoadingPersonas, setIsLoadingPersonas ] = useState(false);
    const history = useHistory();

    const columns = [
        {
            Header: "Nombre",
            accessor: "nombre",
            filterable: true
        },
        {
            Header: "Apellido",
            accessor: "apellido",
            filterable: true
        },
        {
            Header: "Email",
            accessor: "correo",
            filterable: true
        },
        {
            Header: "Cantidad",
            accessor: "herramientas",
            filterable: false,
            Cell: props => {
                const herramientas = props.value;

                if (tipoEvaluacion === "all" ) {
                    if (!herramientas.length) {
                        return "No posee herramientas"
                    }

                    if (herramientas.length > 0) {
                        const disponibilidad = herramientas.map(item => <div>{`${item.tipoEvaluacion.nombre}: ${item.cantidad}`}</div>);
                        return disponibilidad;
                    }
                } else {
                    if (herramientas.length === 1) {
                        return herramientas[0].cantidad;
                    } 
                }

            }
        },
        {
            Header: "Action",
            Cell: function(props) {
                const value = props.original;
                console.log(value);
                return (
                    <a href={`/persona/${value._id}`} className="center-text">
                    <div>Editar</div>
                    </a>
                )
            }
        }
    ];

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }

            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);
            } catch(e) {
                onError(e);
            }
            setIsLoadingTipoEvaluacion(false);
        }

        onLoad();
    }, [isAuthenticated]);

    async function handleSelectTipoEvaluacion(event) {
        const tipoEvaluacion = event.target.value;
        setTipoEvaluacion(tipoEvaluacion);

        setIsLoadingPersonas(true);
        try {
            const personas = await API.getPersonasByHerramienta(tipoEvaluacion);
            setPersonas(personas.data.data);
            console.log(personas.data.data);
        } catch(e) {
            setPersonas([]);
        }
        setIsLoadingPersonas(false);
    }

    function renderForm() {
        return (
            <div className="Persona">
            <PageHeader>Consulta de Usuarios</PageHeader>
            <FormGroup controlId="tipoEvaluacion">
            <FormControl
                componentClass="select"
                placeholder="select"
                onChange={handleSelectTipoEvaluacion}
            >
                <option value="0">Elija un tipo de evaluación</option>
                {!isLoadingTipoEvaluacion && tipoEvaluaciones.map(tipoEvaluacion => {
                    return (
                        <option key={tipoEvaluacion._id} value={tipoEvaluacion._id}>
                            {tipoEvaluacion.nombre}
                        </option>
                    );
                })}
                <option value="all">Todos</option>
            </FormControl>
            </FormGroup>
            <>
            { personas.length > 0 &&
                <ReactTable
                    data={personas}
                    columns={columns}
                    loading={isLoadingPersonas}
                    defaultPageSize={20}
                    showPageSizeOptions={true}
                    minRows={0}
                />
            }
            </>
            </div>
        );
    }

    return (
        <div>
        {isAuthenticated ? renderForm() : history.push("/") }
        </div>
    );
}