import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import LoadingOverlay from "react-loading-overlay";
import API from "./api";
import "./App.css";

function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);
  const [token, setToken] = useState("");
  const [role, setRole] = useState("");
  const [herramientas, setHerramientas] = useState([]);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const result = await Auth.currentAuthenticatedUser();
      const loginResult = await API.login(
        {},
        {
          auth: {
            username: result.username,
            password: result.signInUserSession.accessToken.jwtToken,
          },
        }
      );

      setToken(loginResult.data.token);
      setRole(loginResult.data.role);
      setHerramientas(loginResult.data.herramientas);
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        //alert(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    setRole("");
    setHerramientas([]);
    history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App container">
        <LoadingOverlay
          active={isLoadingOverlay}
          spinner
          text="Cargando..."
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(79, 85, 97, 0.5)", //#4f5561
            }),
            spinner: (base) => ({
              ...base,
              width: "250px",
              "& svg circle": {
                stroke: "rgba(225, 123, 52, 1)", //#E17B34
              },
            }),
            wrapper: {
              width: "100%",
              overflow: "visible",
            },
          }}
        >
          <Navbar fluid collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/">
                  <img src="/groupx110.png" alt="Escuela de Proyectos" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
                {(() => {
                  if (isAuthenticated) {
                    let menu;
                    if (role === "A") {
                      menu = [].concat(
                        <React.Fragment key="1">
                          <NavDropdown
                            eventKey={1}
                            title="Admin"
                            id="basic-nav-dropdown"
                          >
                            <LinkContainer to="/tipoEvaluacion">
                              <MenuItem eventKey={1.1}>
                                Tipo de Evaluación
                              </MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/tipoHabilidad">
                              <MenuItem eventKey={1.2}>
                                Tipo de Habilidad
                              </MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/criterio">
                              <MenuItem eventKey={1.3}>Criterios</MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <LinkContainer to="/nivelHabilidad">
                              <MenuItem eventKey={1.4}>
                                Nivel de Habilidad
                              </MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/nivelMadurez">
                              <MenuItem eventKey={1.5}>
                                Nivel de Madurez
                              </MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <LinkContainer to="/semaforo">
                              <MenuItem eventKey={1.6}>Semáforo</MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <LinkContainer to="/descuento/new">
                              <MenuItem eventKey={1.7}>
                                Asignar código de descuento
                              </MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <LinkContainer to="/curso">
                              <MenuItem eventKey={1.8}>Cursos</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/certificados">
                              <MenuItem eventKey={1.9}>Certificados</MenuItem>
                            </LinkContainer>
                            <MenuItem divider />
                            <LinkContainer to="/producto">
                              <MenuItem eventKey={1.1}>Productos</MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/devoluciones">
                              <MenuItem eventKey={1.11}>Devoluciones</MenuItem>
                            </LinkContainer>
                          </NavDropdown>
                        </React.Fragment>
                      );
                    }

                    menu = [menu].concat(
                      <React.Fragment key="2">
                        <NavDropdown
                          eventKey={2}
                          title="Mis Herramientas"
                          id="basic-nav-dropdown"
                        >
                          {herramientas.map((herramienta, i) => {
                            return (
                              <LinkContainer to={`/${herramienta.url}`} key={i}>
                                <MenuItem
                                  eventKey={`2.${i}`}
                                  className={
                                    !herramienta.disponible && "disabled"
                                  }
                                >
                                  {herramienta.nombre}
                                </MenuItem>
                              </LinkContainer>
                            );
                          })}
                        </NavDropdown>
                        <LinkContainer to="/adquirirHerramientaEnLinea">
                          <NavItem>Adquiere tu Herramienta</NavItem>
                        </LinkContainer>
                      </React.Fragment>
                    );

                    if (role === "A") {
                      menu = [menu].concat(
                        <React.Fragment key="4">
                          <NavDropdown
                            eventKey={4}
                            title="Consultas"
                            id="basic-nav-dropdown"
                          >
                            <LinkContainer to="/consulta/usuarioPorHerramienta">
                              <MenuItem eventKey={4.1}>
                                Usuario por Herramienta
                              </MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/consulta/exportarRespuesta">
                              <MenuItem eventKey={4.2}>
                                Exportar Respuestas
                              </MenuItem>
                            </LinkContainer>
                            <LinkContainer to="/consulta/usuarioPorLibro">
                              <MenuItem eventKey={4.3}>
                                Exportar Usuarios por Libro
                              </MenuItem>
                            </LinkContainer>
                          </NavDropdown>
                        </React.Fragment>
                      );
                    }

                    menu = [menu].concat(
                      <React.Fragment key="5">
                        <NavDropdown
                          eventKey={5}
                          title="Mi perfil"
                          id="basic-nav-dropdown"
                        >
                          <LinkContainer to="/password">
                            <MenuItem eventKey={5.1}>
                              Modificar contraseña
                            </MenuItem>
                          </LinkContainer>
                          <MenuItem eventKey={5.2} onClick={handleLogout}>
                            Cerrar Sesión
                          </MenuItem>
                        </NavDropdown>
                      </React.Fragment>
                    );

                    return menu;
                  } else {
                    return (
                      <>
                        <LinkContainer to="/signup">
                          <NavItem>Crea tu cuenta</NavItem>
                        </LinkContainer>
                        <LinkContainer to="/login">
                          <NavItem>Iniciar sesión</NavItem>
                        </LinkContainer>
                      </>
                    );
                  }
                })()}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <AppContext.Provider
            value={{
              isAuthenticated,
              userHasAuthenticated,
              setIsLoadingOverlay,
              token,
              setToken,
              setRole,
              setHerramientas,
            }}
          >
            <Routes />
          </AppContext.Provider>
          <div className="footer center">
            <p>
              © DIPROMACOM {new Date().getFullYear()}. Todos los derechos
              reservados.
              <br />
              Desarrollado por JS
            </p>
          </div>
        </LoadingOverlay>
      </div>
    )
  );
}

export default App;
