import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem, FormGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaCriterios.css";

export default function ConsultaCriterios() {
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const [ tipoHabilidades, setTipoHabilidades] = useState([]);
    const [ criterios, setCriterios ] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [ isLoadingTipoEvaluacion, setIsLoadingTipoEvaluacion ] = useState(true);
    const [ isLoadingTipoHabilidad, setIsLoadingTipoHabilidad ] = useState(true);
    const [ isLoadingCriterios, setIsLoadingCriterios ] = useState(true);
    const history = useHistory();

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }

            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);

                const tipoEvaluacion = tipoEvaluaciones.data.data.length > 0 ?
                                            tipoEvaluaciones.data.data[0]._id : "0";
                const tipoHabilidades = await API.getTipoHabilidadByTipoEvaluacion(tipoEvaluacion);
                setTipoHabilidades(tipoHabilidades.data.data);

                const tipoHabilidad = tipoHabilidades.data.data.length > 0 ?
                                            tipoHabilidades.data.data[0]._id : "0";
                const criterios = await API.getCriterioByTipoHabilidad(tipoHabilidad);
                setCriterios(criterios.data.data);
            } catch(e) {
                onError(e);
            }
            setIsLoadingTipoEvaluacion(false);
            setIsLoadingTipoHabilidad(false);
            setIsLoadingCriterios(false);
        }

        onLoad();
    }, [isAuthenticated]);

    async function handleSelectTipoEvaluacion(event) {
        const tipoEvaluacion = event.target.value;

        setIsLoadingTipoHabilidad(true);
        setIsLoadingCriterios(true);
        try {
            const tipoHabilidades = await API.getTipoHabilidadByTipoEvaluacion(tipoEvaluacion);
            setTipoHabilidades(tipoHabilidades.data.data);

            const tipoHabilidad = tipoHabilidades.data.data.length > 0 ?
                                    tipoHabilidades.data.data[0]._id : "0";
            const criterios = await API.getCriterioByTipoHabilidad(tipoHabilidad);
            setCriterios(criterios.data.data);
        } catch(e) {
            setTipoHabilidades([]);
            setCriterios([]);
        }
        setIsLoadingTipoHabilidad(false);
        setIsLoadingCriterios(false);
    }

    async function handleSelectTipoHabilidad(event) {
        const tipoHabilidad = event.target.value;

        setIsLoadingCriterios(true);
        try {
            const criterios = await API.getCriterioByTipoHabilidad(tipoHabilidad);
            setCriterios(criterios.data.data);
        } catch(e) {
            setCriterios([]);
        }
        setIsLoadingCriterios(false);
    }

    function renderCriteriosList(criterios) {
        return [{}].concat(criterios).map((criterio, i) => 
            i !== 0 ? (
                <LinkContainer key={criterio._id} to={`/criterio/${criterio._id}`}>
                    <ListGroupItem>
                        {i}. {criterio.descripcion}
                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to={`/criterio/new`}>
                    <ListGroupItem>
                        <h4>
                        <b>{"\uFF0B"}</b> Crear criterio
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    function renderCriterios() {
        return (
            <div className="Criterios">
            <PageHeader>Criterios</PageHeader>
            <FormGroup controlId="tipoEvaluacion">
            <FormControl 
                componentClass="select"
                placeholder="select"
                onChange={handleSelectTipoEvaluacion}
            >
                {/*<option value="0">Elija un tipo de evaluación</option>*/}
                {!isLoadingTipoEvaluacion && tipoEvaluaciones.map(tipoEvaluacion => {
                    return (
                        <option key={tipoEvaluacion._id} value={tipoEvaluacion._id}>
                            {tipoEvaluacion.nombre}
                        </option>
                    );
                })}
            </FormControl>
            </FormGroup>
            <FormGroup controlId="tipoHabilidad">
            <FormControl 
                componentClass="select"
                placeholder="select"
                onChange={handleSelectTipoHabilidad}
            >
                {/*<option value="0">Elija un tipo de habilidad</option>*/}
                {!isLoadingTipoHabilidad && tipoHabilidades.map(tipoHabilidad => {
                    return (
                        <option key={tipoHabilidad._id} value={tipoHabilidad._id}>
                            {tipoHabilidad.nombre}
                        </option>
                    );
                })}
            </FormControl>
            </FormGroup>
            <ListGroup>
            {!isLoadingCriterios && renderCriteriosList(criterios)}
            </ListGroup>
            </div>
        );
    }

    return (
        <div>
        {isAuthenticated ? renderCriterios() : history.push("/") }
        </div>
    );
}