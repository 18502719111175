export function onError(error) {
    let errorInfo = {};
    let message = error.toString();

    if (!(error instanceof Error) && error.message) {
        errorInfo = error;
        message = error.message;
    } else if (error.response && error.response.data 
        && error.response.data.message && error.response.data.message.message) {
        message = error.response.data.message.message;
        errorInfo = error.response;
    } else if (error.response && error.response.data 
        && error.response.data.message) {
        message = error.response.data.message;
        errorInfo = error.response;
    }
    
    console.log(JSON.stringify(errorInfo));
    alert(message);
}