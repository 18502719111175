import React from "react";
import { FormGroup, FormControl, Col, ControlLabel } from "react-bootstrap";

export default function EvaluationForm(props) {
  function handleChange(event) {
    props.onChange(event);
  }

  return (
    <>
      <FormGroup controlId="empresa">
        <Col componentClass={ControlLabel} sm={2}>
          Empresa
        </Col>
        <Col sm={8}>
          <FormControl
            type="text"
            autoComplete="off"
            value={props.empresa}
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="cargo">
        <Col componentClass={ControlLabel} sm={2}>
          Cargo
        </Col>
        <Col sm={8}>
          <FormControl
            type="text"
            autoComplete="off"
            value={props.cargo}
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="profesion">
        <Col componentClass={ControlLabel} sm={2}>
          Profesión
        </Col>
        <Col sm={8}>
          <FormControl
            type="text"
            autoComplete="off"
            value={props.profesion}
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="telefono">
        <Col componentClass={ControlLabel} sm={2}>
          Teléfono
        </Col>
        <Col sm={8}>
          <FormControl
            type="number"
            autoComplete="off"
            value={props.telefono}
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="pais">
        <Col componentClass={ControlLabel} sm={2}>
          País
        </Col>
        <Col sm={8}>
          <FormControl
            type="text"
            autoComplete="off"
            value={props.pais}
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="genero">
        <Col componentClass={ControlLabel} sm={2}>
          Género
        </Col>
        <Col sm={8}>
          <FormControl
            componentClass="select"
            placeholder="Elija su género"
            value={props.genero}
            onChange={(e) => handleChange(e)}
          >
            <option value="M">Masculino</option>
            <option value="F">Femenino</option>
          </FormControl>
        </Col>
      </FormGroup>
    </>
  );
}
