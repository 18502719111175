import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, Form, Radio, Table } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { globalVars } from "../../constants";
import EvaluationForm from "../../components/EvaluationForm";
import API from "../../api";
import "./MadurezCronograma.css";
//import { Auth } from "aws-amplify";

export default function MadurezCronograma() {
  const history = useHistory();
  const [criterios, setCriterios] = useState([]);
  const [criteriosChecked, setCriteriosChecked] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { isAuthenticated, setIsLoadingOverlay, token } = useAppContext();
  const [empresa, setEmpresa] = useState("");
  const [cargo, setCargo] = useState("");
  const [profesion, setProfesion] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [cantidad, setCantidad] = useState("0");

  useEffect(() => {
    async function onLoad() {
      setIsLoadingOverlay(true);

      try {
        const payload = { tipoEvaluacion: globalVars.MADUREZ_CRONOGRAMA_ID };
        const persona = await API.getPermission(payload, {
          headers: { Authorization: token },
        });
        const {
          empresa,
          cargo,
          profesion,
          nombre,
          apellido,
          correo,
          telefono,
          cantidad,
        } = persona.data.data;

        setEmpresa(empresa === undefined ? "" : empresa);
        setCargo(cargo === undefined ? "" : cargo);
        setProfesion(profesion === undefined ? "" : profesion);
        setNombre(nombre === undefined ? "" : nombre);
        setApellido(apellido === undefined ? "" : apellido);
        setCorreo(correo === undefined ? "" : correo);
        setTelefono(telefono === undefined ? "" : telefono);
        setCantidad(cantidad === undefined ? "" : cantidad);

        const criterios = await API.getCriterioAgrupado(
          globalVars.MADUREZ_CRONOGRAMA_ID
        );
        setCriterios(criterios.data.data);
        let criteriosChecked = prepareCriterioChecked(criterios.data.data);
        setCriteriosChecked(criteriosChecked);
      } catch (e) {
        if (e.response && e.response.status === 403) {
          history.push("/adquirirHerramienta");
        }
        onError(e);
        history.push("/");
      }
      setIsLoading(false);
      setIsLoadingData(false);
      setIsLoadingOverlay(false);
    }

    onLoad();
  }, [isAuthenticated, history, token, setIsLoadingOverlay]);

  function prepareCriterioChecked(criterios) {
    //Genero un array con formato:
    //[ { id: "1", tipoHabilidad: "1" }, { id: "2", tipoHabilidad: "1" }]
    let criteriosChecked = {};
    let criterioArray = criterios
      .map((tipoHabilidad) =>
        tipoHabilidad.criterios.map((criterio) => {
          return {
            id: criterio.id,
            tipoHabilidad: tipoHabilidad._id._id,
          };
        })
      )
      .flat();

    //Genero un Objeto con formato:
    //{ "1": { value: 0, tipoHabilidad: "1" },
    //  "2": { value: 0, tipoHabilidad: "1" } }
    criteriosChecked = criterioArray.reduce((map, obj) => {
      map[obj.id] = { value: 0, tipoHabilidad: obj.tipoHabilidad };
      return map;
    }, {});
    return criteriosChecked;
  }

  function Change(event) {
    switch (event.target.id) {
      case "empresa":
        setEmpresa(event.target.value);
        break;

      case "cargo":
        setCargo(event.target.value);
        break;

      case "profesion":
        setProfesion(event.target.value);
        break;

      case "telefono":
        setTelefono(event.target.value);
        break;

      default:
        break;
    }
  }

  function handleCheck(event, id) {
    const criterioChecked = criteriosChecked;
    const key = id;
    const value = event.target.value;
    criterioChecked[key].value = Number(value);
    setCriteriosChecked(criteriosChecked);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const confirmed = window.confirm(
      "¿Desea terminar y procesar la evaluación de Madurez del Cronograma"
    );
    if (!confirmed) {
      return;
    }

    setIsLoadingOverlay(true);
    setIsLoading(true);
    try {
      const payload = {
        nombre,
        apellido,
        correo,
        telefono,
        empresa,
        cargo,
        profesion,
        activo: true,
        evaluacionDetalle: criteriosChecked,
      };
      const result = await API.saveMadurezCronograma(payload, {
        headers: { Authorization: token },
      });
      window.location.href = globalVars.URL + result.data.file;
    } catch (e) {
      onError(e);
    }
    setCriteriosChecked(prepareCriterioChecked(criterios));
    setIsLoading(false);
    setIsLoadingOverlay(false);

    // await Auth.currentSession()
    // Auth.currentUserInfo();
    // Auth.currentAuthenticatedUser();
  }

  function renderTable() {
    return (
      <div className="MadurezCronograma">
        <PageHeader>Nivel de Madurez del Cronograma</PageHeader>
        <p>
          <b>Atención: </b>{" "}
          {cantidad > 1
            ? `Tiene ${cantidad} herramientas de Madurez Cronograma disponibles.`
            : `Tiene ${cantidad} herramienta de Madurez Cronograma disponible.`}
        </p>
        <br />
        {!isLoadingData && (
          <Form horizontal onSubmit={handleSubmit}>
            <EvaluationForm
              empresa={empresa}
              cargo={cargo}
              profesion={profesion}
              telefono={telefono}
              onChange={Change}
            />
            {!isLoading && (
              <>
                <br />
                <p>
                  <b>Instrucciones:</b> Seleccione el nivel apropiado para cada
                  criterio donde Muy de Acuerdo tendría un peso de 4 y Muy en
                  Desacuerdo un peso de 0.
                </p>
                <br />
                <Table hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Criterio</th>
                      <th>Muy de Acuerdo</th>
                      <th className="header-center">De acuerdo</th>
                      <th className="header-center">Indiferente</th>
                      <th className="header-center">En desacuerdo</th>
                      <th className="header-center">Muy en desacuerdo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      criterios.map(({ _id, criterios }, i) => {
                        const headerRow = [
                          <tr key={_id._id}>
                            <td colSpan="7">
                              <b>{_id.nombre}</b>
                            </td>
                          </tr>,
                        ];

                        const criterioRow = criterios.map((criterio, j) => {
                          return (
                            <tr key={criterio.id}>
                              <td>{j + 1}</td>
                              <td>{criterio.descripcion}</td>
                              <td>
                                <Radio
                                  value={4}
                                  name={criterio.descripcion}
                                  bsClass="radio-large"
                                  onClick={(e) => handleCheck(e, criterio.id)}
                                />
                              </td>
                              <td>
                                <Radio
                                  value={3}
                                  name={criterio.descripcion}
                                  bsClass="radio-large"
                                  onClick={(e) => handleCheck(e, criterio.id)}
                                />
                              </td>
                              <td>
                                <Radio
                                  value={2}
                                  name={criterio.descripcion}
                                  bsClass="radio-large"
                                  onClick={(e) => handleCheck(e, criterio.id)}
                                />
                              </td>
                              <td>
                                <Radio
                                  value={1}
                                  name={criterio.descripcion}
                                  bsClass="radio-large"
                                  onClick={(e) => handleCheck(e, criterio.id)}
                                />
                              </td>
                              <td>
                                <Radio
                                  value={0}
                                  name={criterio.descripcion}
                                  bsClass="radio-large"
                                  onClick={(e) => handleCheck(e, criterio.id)}
                                />
                              </td>
                            </tr>
                          );
                        });

                        return headerRow.concat(criterioRow);
                      })}
                  </tbody>
                </Table>
                <LoaderButton
                  block
                  type="submit"
                  className="button-orange"
                  bsSize="large"
                  isLoading={isLoading}
                >
                  Enviar
                </LoaderButton>

                <br />
                <p>
                  <b>Desarrollado por: </b>
                  <br />
                  Nathalie Rosero, PMP, PMO-CP
                  <br />
                  José Luis González, PMP, PMI-RMP, PMO-CP
                </p>
              </>
            )}
          </Form>
        )}
      </div>
    );
  }

  return <div>{isAuthenticated ? renderTable() : history.push("/")}</div>;
}
