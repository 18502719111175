import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  PageHeader,
  Form,
  Col,
  Radio,
  Checkbox,
} from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { globalVars } from "../../constants";
import { onError } from "../../libs/errorLib";
import { ModalTerminos } from "./ModalTerminos";
import "./Pago.css";

export default function Pago() {
  const { id } = useParams();
  const history = useHistory();
  const [paymentId, setPaymentId] = useState("");
  const [orderReference, setOrderReference] = useState("");
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [tipoProductos, setTipoProductos] = useState([]);
  const [tipoProducto, setTipoProducto] = useState("");
  const [tipoEvaluaciones, setTipoEvaluaciones] = useState([]);

  const { isAuthenticated, setIsLoadingOverlay, token, setHerramientas } =
    useAppContext();
  const [tipoEvaluacion, setTipoEvaluacion] = useState("");
  const [codigoDescuento, setCodigoDescuento] = useState("");
  const [precio, setPrecio] = useState(0);
  const [total, setTotal] = useState(0);
  const [isDiferido, setIsDiferido] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingDescuento, setIsLoadingDescuento] = useState(false);
  const [aceptaTerminos, setAceptaTerminos] = useState(false);
  const response = React.createRef();
  const [showTerminos, setShowTerminos] = useState(false);

  const [iva, setIva] = useState(0);
  const [porcentajeIva, setPorcentajeIva] = useState(0);

  useEffect(() => {
    async function onLoad() {
      setIsLoadingData(true);
      try {
        const tipoProductos = await API.getTipoProductoActivo();
        setTipoProductos(tipoProductos.data.data);

        let tipoProducto = tipoProductos.data.data[0]._id;
        setTipoProducto(tipoProducto);

        let tipoEvaluacion;
        if (id !== undefined) {
          console.log(id);
          const params = id.split("&");
          const tipo = params[0].split("=")[1];
          if (tipo === "H") {
            tipoProducto = globalVars.TIPO_PRODUCTO_HERRAMIENTA_ID;
            setTipoProducto(tipoProducto);
          } else if (tipo === "C") {
            tipoProducto = globalVars.TIPO_PRODUCTO_CURSO_ID;
            setTipoProducto(tipoProducto);
          } else if (tipo === "L") {
            tipoProducto = globalVars.TIPO_PRODUCTO_LIBRO_ID;
            setTipoProducto(tipoProducto);
          }

          tipoEvaluacion = params[1];
          setTipoEvaluacion(tipoEvaluacion);
        }

        let tipoEvaluaciones;
        if (tipoProducto === globalVars.TIPO_PRODUCTO_HERRAMIENTA_ID) {
          tipoEvaluaciones = await API.getTipoEvaluacionActivo();
        } else {
          tipoEvaluaciones = await API.getProductoByTipoProductoId(
            tipoProducto
          );
        }
        setTipoEvaluaciones(tipoEvaluaciones.data.data);

        if (id !== undefined) {
          const precio = Number(
            tipoEvaluaciones.data.data.filter(
              (item) => item._id === tipoEvaluacion
            )[0].precio
          );
          const porcentajeIva = Number(
            tipoEvaluaciones.data.data.filter(
              (item) => item._id === tipoEvaluacion
            )[0].porcentajeIva
          );
          const iva = (precio * porcentajeIva) / 100;
          const total = precio + iva;
          setPrecio(precio);
          setPorcentajeIva(porcentajeIva);
          setIva(iva);
          setTotal(total);
        }

        const paymentOrder = await API.generateOrder(
          {},
          { headers: { Authorization: token } }
        );
        const { paymentId, orderReference, userId, email, phone } =
          paymentOrder.data.data;
        setPaymentId(paymentId);
        setOrderReference(orderReference);
        setUserId(userId);
        setEmail(email);
        setPhone(phone);
      } catch (e) {
        onError(e);
      }
      setIsLoadingData(false);
    }
    onLoad();

    const script = document.createElement("script");
    script.src = "/checkout.js";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [isAuthenticated, token]);

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoadingOverlay(true);
    setIsLoading(true);

    try {
      const detalle = { tipoEvaluacion };
      const payload = { detalle: [detalle], codigo: codigoDescuento };
      const result = await API.saveCompra(payload, {
        headers: { Authorization: token },
      });
      setHerramientas(result.data.herramientas);

      switch (tipoEvaluacion) {
        case globalVars.EVALUACION_PM_ID:
          history.push("/perfilDirectorProyecto");
          break;

        default:
          history.push("/");
          break;
      }
    } catch (e) {
      onError(e);
    }
    setIsLoading(false);
    setIsLoadingOverlay(false);
  }

  async function handleAplicarDescuento(event) {
    event.preventDefault();

    setIsLoadingOverlay(true);
    setIsLoadingDescuento(true);

    try {
      const detalle = { tipoEvaluacion, tipoProducto };
      const payload = { codigo: codigoDescuento, detalle: [detalle] };
      const result = await API.aplicarDescuento(payload, {
        headers: { Authorization: token },
      });
      const { subtotal, descuentoValor, iva, total } = result.data.data;
      const precio = Number(subtotal) - Number(descuentoValor);
      setPrecio(precio);
      setIva(iva);
      setTotal(total);
    } catch (e) {
      onError(e);
    }

    setIsLoadingDescuento(false);
    setIsLoadingOverlay(false);
  }

  function validateForm() {
    // return tipoEvaluacion && aceptaTerminos;
    return tipoEvaluacion && aceptaTerminos && total <= 0;
  }

  function validateAplicarDescuento() {
    return tipoEvaluacion && codigoDescuento.length > 0;
  }

  function handleCheck(event, id) {
    setTipoEvaluacion(id);
    const evaluacion = tipoEvaluaciones.filter((item) => item._id === id);
    const precio = Number(evaluacion[0].precio);
    const porcentajeIva = Number(evaluacion[0].porcentajeIva);
    const iva = (precio * porcentajeIva) / 100;
    const total = precio + iva;

    setPrecio(precio);
    setPorcentajeIva(porcentajeIva);
    setIva(iva);
    setTotal(total);
  }

  function handleCheckTerminos(event) {
    const terminos = aceptaTerminos ? false : true;
    setAceptaTerminos(terminos);
  }

  function handleCheckDiferido(event) {
    const diferido = isDiferido ? false : true;
    setIsDiferido(diferido);
  }

  async function sendResponse(event) {
    setIsLoadingOverlay(true);
    try {
      //const responseObject = {};
      const responseObject = JSON.parse(response.current.value);

      const detalle = { tipoEvaluacion, tipoProducto };
      const payload = {
        detalle: [detalle],
        codigo: codigoDescuento,
        response: responseObject,
      };
      const result = await API.compra(paymentId, payload, {
        headers: { Authorization: token },
      });
      setIsLoadingOverlay(false);
      const message = result.data.message;
      alert(message);

      if (tipoProducto === globalVars.TIPO_PRODUCTO_CURSO_ID) {
        history.push("/");
        return;
      } else if (tipoProducto === globalVars.TIPO_PRODUCTO_LIBRO_ID) {
        history.push("/");
        return;
      }

      setHerramientas(result.data.herramientas);
      switch (tipoEvaluacion) {
        case globalVars.EVALUACION_PM_ID:
          history.push("/perfilDirectorProyecto");
          break;

        case globalVars.SALUD_PROYECTO_ID:
          history.push("/saludProyecto");
          break;

        case globalVars.MADUREZ_CRONOGRAMA_ID:
          history.push("/madurezCronograma");
          break;

        default:
          break;
      }
    } catch (e) {
      setIsLoadingOverlay(false);
      if (e.response && e.response.status === 400) {
        history.push("/");
      }
      onError(e);
    }
  }

  async function compraSinBotonPago(event) {
    setIsLoadingOverlay(true);
    try {
      const responseObject = {};
      //const responseObject = JSON.parse(response.current.value);

      const detalle = { tipoEvaluacion, tipoProducto };
      const payload = {
        detalle: [detalle],
        codigo: codigoDescuento,
        response: responseObject,
      };
      const result = await API.compraSinBotonPago(paymentId, payload, {
        headers: { Authorization: token },
      });
      const { message, herramientas, libroBase64 } = result.data;
      setIsLoadingOverlay(false);
      alert(`${message}`);

      if (tipoProducto === globalVars.TIPO_PRODUCTO_CURSO_ID) {
        history.push("/");
        return;
      } else if (tipoProducto === globalVars.TIPO_PRODUCTO_LIBRO_ID) {
        if (libroBase64) {
          history.push("/");
          const libroNombre = tipoEvaluaciones.find(
            (o) => o._id === tipoEvaluacion
          );
          openPDF(libroBase64, libroNombre.nombre);
        }
        return;
      }

      setHerramientas(herramientas);
      switch (tipoEvaluacion) {
        case globalVars.EVALUACION_PM_ID:
          history.push("/perfilDirectorProyecto");
          break;

        case globalVars.SALUD_PROYECTO_ID:
          history.push("/saludProyecto");
          break;

        case globalVars.MADUREZ_CRONOGRAMA_ID:
          history.push("/madurezCronograma");
          break;

        case globalVars.EVALUACION_PM_CONDUCTUAL_ID:
          history.push("/perfilDirectorProyectoConductual");
          break;

        case globalVars.ESTILO_LIDERAZGO_TAREA_PERSONA_ID:
          history.push("/estiloLiderazgoTareaPersona");
          break;

        case globalVars.ESTILO_LIDERAZGO_ID:
          history.push("/estiloLiderazgo");
          break;

        case globalVars.PRACTICA_GERENCIAL_ID:
          history.push("/practicasGerenciales");
          break;

        case globalVars.RESOLUCION_PROBLEMA_ID:
          history.push("/resolucionProblemas");
          break;

        default:
          break;
      }
    } catch (e) {
      setIsLoadingOverlay(false);
      if (e.response && e.response.status === 400) {
        history.push("/");
      }
      onError(e);
    }
  }

  async function handleSelectTipoProducto(event) {
    const tipoProducto = event.target.value;
    setTipoProducto(tipoProducto);
    setTipoEvaluacion("");
    setPrecio(0);
    setPorcentajeIva(0);
    setIva(0);
    setTotal(0);
    getProductoByTipoProducto(tipoProducto);
  }

  async function getProductoByTipoProducto(tipoProducto) {
    let tipoProductos;
    if (tipoProducto === globalVars.TIPO_PRODUCTO_HERRAMIENTA_ID) {
      tipoProductos = await API.getTipoEvaluacionActivo();
    } else {
      tipoProductos = await API.getProductoByTipoProductoId(tipoProducto);
    }
    setTipoEvaluaciones(tipoProductos.data.data);
  }

  function closeModal() {
    setShowTerminos(false);
  }

  function openModal() {
    setShowTerminos(true);
  }

  function openPDF(base64String, fileName) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName || "download.pdf");

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Cleanup
    document.body.removeChild(link);

    // Open the book
    window.open(url, "_blank");
    window.URL.revokeObjectURL(url);
  }

  return (
    <div className="Pago">
      <PageHeader>Tienda En Línea</PageHeader>
      <Form>
        {!isLoadingData && (
          <>
            <FormGroup controlId="tipoProducto">
              <ControlLabel>Tipo de Producto</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                value={tipoProducto}
                onChange={handleSelectTipoProducto}
              >
                {tipoProductos &&
                  tipoProductos.map((tipoProducto) => {
                    return (
                      <option key={tipoProducto._id} value={tipoProducto._id}>
                        {tipoProducto.nombre}
                      </option>
                    );
                  })}
              </FormControl>
            </FormGroup>

            <FormGroup controlId="tipoEvaluacion">
              <ControlLabel>Elija el producto que desea adquirir</ControlLabel>
              {tipoEvaluaciones.map((tipo) => {
                return (
                  <Radio
                    name="group"
                    key={tipo._id}
                    onChange={(e) => handleCheck(e, tipo._id)}
                    checked={tipo._id === tipoEvaluacion ? true : false}
                  >
                    {tipo.nombre}
                  </Radio>
                );
              })}
            </FormGroup>

            <FormGroup>
              <ControlLabel>Valor de la herramienta</ControlLabel>
              <FormControl.Static>
                $ {Number(total).toFixed(2)}
              </FormControl.Static>
            </FormGroup>

            <FormGroup control="codigoDescuento">
              <ControlLabel>Ingrese su código de descuento</ControlLabel>
            </FormGroup>

            <FormGroup controlId="descuento">
              <Col sm={5}>
                <FormControl
                  type="text"
                  autoComplete="off"
                  value={codigoDescuento}
                  onChange={(e) => setCodigoDescuento(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <LoaderButton
                  block
                  className="button-orange"
                  onClick={handleAplicarDescuento}
                  isLoading={isLoadingDescuento}
                  disabled={!validateAplicarDescuento()}
                >
                  Aplicar descuento
                </LoaderButton>
              </Col>
            </FormGroup>

            <br />
            <br />
            <FormGroup>
              <ControlLabel>Valor a pagar</ControlLabel>
              <FormControl.Static>
                $ {Number(total).toFixed(2)}
              </FormControl.Static>
            </FormGroup>

            {/* Se comento porque se desactiva el boton de pagos */
            /*<Checkbox onChange={handleCheckDiferido} checked={isDiferido}>
              ¿Desea diferir su pago?
            </Checkbox> */}

            <Checkbox onChange={handleCheckTerminos} checked={aceptaTerminos}>
              Al hacer clic acepta estar de acuerdo con los{" "}
              <a onClick={openModal}>términos y condiciones</a>
            </Checkbox>

            <input type="hidden" className="userId" value={userId} />
            <input type="hidden" className="userEmail" value={email} />
            <input type="hidden" className="userPhone" value={phone} />
            <input
              type="hidden"
              className="orderTaxableAmount"
              value={precio}
            />
            <input type="hidden" className="orderVat" value={iva} />
            <input
              type="hidden"
              className="orderTaxPercentage"
              value={porcentajeIva}
            />
            <input type="hidden" className="orderAmount" value={total} />
            <input
              type="hidden"
              className="orderReference"
              value={orderReference}
            />
            <input type="hidden" className="isDiferido" value={isDiferido} />
            <button
              type="button"
              id="btnSendResponse"
              className="button-send"
              onClick={sendResponse}
            >
              Enviar respuesta
            </button>
            <button
              type="button"
              id="btnCompraSinBotonPago"
              className="button-send"
              onClick={compraSinBotonPago}
            >
              Enviar respuesta
            </button>
          </>
        )}

        <LoaderButton
          block
          bsSize="large"
          // className="js-payment-checkout button-orange"

          className="button-orange"
          onClick={compraSinBotonPago}
          type="button"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Pagar
        </LoaderButton>

        <ModalTerminos show={showTerminos} onHide={closeModal} />
        <input type="hidden" id="response" ref={response} />
      </Form>
    </div>
  );
}
