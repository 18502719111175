import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  pdf,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useAppContext } from "../../libs/contextLib";
import { PageHeader } from "react-bootstrap";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import { globalVars } from "../../constants";
import LoaderButton from "../../components/LoaderButton";
import "./ImprimirCertificadoCorporativo.css";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  pageBackground: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
    zindex: "-1",
  },
  estudianteHolder: {
    position: "absolute",
    left: "120px",
    top: "230px",
    width: "590px",
    // border: '2pt',
    // borderColor: "#000",
    // borderStyle: 'solid',
    textAlign: "center",
    zindex: "0",
  },
  estudianteFont: {
    fontSize: 36,
    letterSpacing: "-1pt",
    transform: "scaleY(1.2)",
  },
  modalidadHolder: {
    position: "absolute",
    left: "120px",
    top: "280px",
    width: "590px",
    textAlign: "center",
    zindex: "0",
  },
  modalidadFont: {
    fontSize: 16,
    letterSpacing: "-1pt",
    transform: "scaleY(1.2)",
  },
  cursoHolder: {
    position: "absolute",
    left: "120px",
    top: "310px",
    width: "590px",
    textAlign: "center",
    zindex: "0",
  },
  cursoFont: {
    fontSize: 22,
    letterSpacing: "-1pt",
    transform: "scaleY(1.8)",
    color: "#b18c3b",
  },
  duracionHolder: {
    position: "absolute",
    left: "120px",
    top: "360px",
    width: "590px",
    textAlign: "center",
    zindex: "0",
  },
  duracionFont: {
    fontSize: 16,
    letterSpacing: "-1pt",
    transform: "scaleY(1.2)",
  },
  lugarHolder: {
    position: "absolute",
    left: "120px",
    top: "380px",
    width: "590px",
    textAlign: "center",
    zindex: "0",
  },
  lugarFont: {
    fontSize: 16,
    letterSpacing: "-1pt",
    transform: "scaleY(1.2)",
  },
});

export default function ImrprimirCertificado() {
  const { id } = useParams();
  const history = useHistory();
  const [estudiante, setEstudiante] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [curso, setCurso] = useState("");
  const [duracion, setDuracion] = useState("");
  const [fecha, setFecha] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, token, setIsLoadingOverlay } = useAppContext();

  const MyDocument = (prop) => (
    <Document title={`Certificado corporativo de ${prop.estudiante}`}>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Image
          src="/certificado-coporativo-backup-20210616.png"
          style={styles.pageBackground}
        />
        <View style={styles.estudianteHolder}>
          <Text style={styles.estudianteFont}>
            {prop.estudiante.toUpperCase()}
          </Text>
        </View>

        <View style={styles.modalidadHolder}>
          <Text style={styles.modalidadFont}>
            Aprobó exitosamente el curso, en modalidad {prop.modalidad}
          </Text>
        </View>

        <View style={styles.cursoHolder}>
          <Text style={styles.cursoFont}>{prop.curso}</Text>
        </View>

        <View style={styles.duracionHolder}>
          <Text style={styles.duracionFont}>Duración: {prop.duracion}</Text>
        </View>

        <View style={styles.lugarHolder}>
          <Text style={styles.lugarFont}>
            En la ciudad de Guayaquil, {prop.fecha}
          </Text>
        </View>
      </Page>
    </Document>
  );

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      try {
        const payload = { tipoCurso: globalVars.CERTIFICADO_CORPORATIVO_ID };
        const certificado = await API.getCertificadoByDetalleId(id, payload, {
          headers: { Authorization: token },
        });
        const detalle = certificado.data.data;
        setEstudiante(detalle.nombre + " " + detalle.apellido);
        setModalidad(detalle.curso.modalidad);
        setCurso(detalle.curso.nombre);

        const curso = detalle.curso.duracion;
        let duracion1 = `${curso[0].duracion} ${curso[0].unidad}  ${curso[0].tipo}`;
        let duracion2 =
          curso.length > 1
            ? `${curso[1].duracion} ${curso[1].unidad}  ${curso[1].tipo}`
            : "";
        let duracion =
          curso.length > 1 ? duracion1 + " y " + duracion2 : duracion1;
        setDuracion(duracion);

        let fechaDesde = new Date(detalle.curso.fechaInicio);
        let fecha = `el ${fechaDesde.getDate()} del ${getMonthName(
          fechaDesde.getMonth()
        )}`;
        if (detalle.curso.fechaFin) {
          let fechaHasta = new Date(detalle.curso.fechaFin);
          fecha = `en las fechas del ${fechaDesde.getDate()} de ${getMonthName(
            fechaDesde.getMonth()
          )} al ${fechaHasta.getDate()} de ${getMonthName(
            fechaHasta.getMonth()
          )} del ${fechaHasta.getFullYear()}`;
        }
        setFecha(fecha);
      } catch (e) {
        if (e.response && e.response.status === 403) {
          history.push("/");
        }
        onError(e);
      }
      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, id, token, history]);

  function getMonthName(index) {
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return meses[index];
  }

  async function enviarCorreo(event) {
    event.preventDefault();

    setIsLoadingOverlay(true);
    setIsLoading(true);
    try {
      const blob = pdf(
        MyDocument({ estudiante, modalidad, curso, duracion, fecha })
      );
      const formData = new FormData();
      formData.append("certificado", await blob.toBlob());
      const result = await API.enviarCertificado(id, formData, {
        headers: { Authorization: token },
      });
      alert(result.data.message);
    } catch (e) {
      if (e.response && e.response.status === 403) {
        history.push("/");
      }
      onError(e);
    }
    setIsLoading(false);
    setIsLoadingOverlay(false);
  }

  const MemoPdf = React.memo(() => (
    <PDFViewer>
      <MyDocument
        estudiante={estudiante}
        modalidad={modalidad}
        curso={curso}
        duracion={duracion}
        fecha={fecha}
      />
    </PDFViewer>
  ));

  return (
    <div className="ImprimirCertificado">
      <PageHeader>Certificado Corporativo</PageHeader>
      <LoaderButton
        block
        bsSize="large"
        className="button-orange"
        isLoading={isLoading}
        onClick={enviarCorreo}
      >
        Enviar por correo
      </LoaderButton>

      {!isLoading && <MemoPdf></MemoPdf>}
    </div>
  );
}
