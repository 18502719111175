import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, InputGroup,
        PageHeader, Form, Col } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import { useAppContext } from "../../libs/contextLib";
import { globalVars } from "../../constants";
import "./EditaHerramienta.css";

export default function EditaHerramienta() {
    const { id } = useParams();
    const history = useHistory();
    const [ persona, setPersona ] = useState(null);
    const [ nombre, setNombre ] = useState("");
    const [ apellido, setApellido ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ descripcion, setDescripcion ] = useState("");
    const [ perfilPM, setPerfilPM ] = useState(0);
    const [ saludProyecto, setSaludProyecto] = useState(0);
    const [ madurezCronograma, setMadurezCronograma ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);
    const [ nuevoPerfilPm, setNuevoPerfilPM] = useState(0);
    const [ nuevoSaludProyecto, setNuevoSaludProyecto ] = useState(0);
    const [ nuevoMadurezCronograma, setNuevoMadurezCronograma ] = useState(0);
    const { token } = useAppContext();

    useEffect(()=>{
        async function onLoad() {
            setIsLoadingData(true);
            try {
                const persona = await API.getPersonaById(id);
                const { nombre, apellido, correo, herramientas } = persona.data.data;
                setPersona(persona.data.data);
                setNombre(nombre);
                setApellido(apellido);
                setEmail(correo);

                herramientas.forEach(herramienta => {
                    if (herramienta.tipoEvaluacion === globalVars.EVALUACION_PM_ID) {
                        setPerfilPM(herramienta.cantidad);
                    } else if (herramienta.tipoEvaluacion === globalVars.MADUREZ_CRONOGRAMA_ID) {
                        setMadurezCronograma(herramienta.cantidad);
                    } else if (herramienta.tipoEvaluacion === globalVars.SALUD_PROYECTO_ID) {
                        setSaludProyecto(herramienta.cantidad);
                    }
                });
            } catch(e) {
                onError(e);
            }
            setIsLoadingData(false);
        }

        onLoad();
    }, [id]);


    function validateForm() {
        return (nuevoPerfilPm > 0 ||
                nuevoSaludProyecto > 0 || 
                nuevoMadurezCronograma > 0);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            let detalle = [];
            if (nuevoPerfilPm > 0) {
                detalle.push({
                    tipoEvaluacion: globalVars.EVALUACION_PM_ID,
                    cantidad: Number(nuevoPerfilPm)
                });
            }

            if (nuevoMadurezCronograma > 0) {
                detalle.push({
                    tipoEvaluacion: globalVars.MADUREZ_CRONOGRAMA_ID,
                    cantidad: Number(nuevoMadurezCronograma)
                });
            }

            if (nuevoSaludProyecto > 0) {
                detalle.push({
                    tipoEvaluacion: globalVars.SALUD_PROYECTO_ID,
                    cantidad: Number(nuevoSaludProyecto)
                });
            }


            const payload = {
                cliente: id, descripcion, detalle
            };
            console.log(payload);
            const result = await API.darDeBaja(payload, { headers: { "Authorization": token }});
            alert(result.data.message);
            history.push("/consulta/usuarioPorHerramienta");
        } catch(e) {
            onError(e);
        }
        setIsLoading(false);
    }

    return (
        <div className="EditaHerramienta">
            <PageHeader>Stock de Herramientas</PageHeader>
            { !isLoadingData && (
            <Form onSubmit={handleSubmit} horizontal>
                
            <FormGroup controlId="persona">
            <Col componentClass={ControlLabel} sm={3}>
            Perfil del Director de Proyecto
            </Col>
            <Col sm={3}>
            <FormControl.Static>{perfilPM}</FormControl.Static>
            </Col>
            <Col componentClass={ControlLabel} sm={1}>
            Cliente
            </Col>
            <Col sm={3}>
            <FormControl.Static>{nombre} {apellido}</FormControl.Static> 
            </Col>
            </FormGroup>

            <FormGroup controlId="perfilPM">
            <Col componentClass={ControlLabel} sm={3}>
            Salud del Proyecto
            </Col>
            <Col sm={3}>
            <FormControl.Static>{saludProyecto}</FormControl.Static>
            </Col>
            <Col componentClass={ControlLabel} sm={1}>
            Correo
            </Col>
            <Col sm={3}>
            <FormControl.Static>{email}</FormControl.Static> 
            </Col>
            </FormGroup>

            <FormGroup controlId="perfilPM">
            <Col componentClass={ControlLabel} sm={3}>
            Madurez del Cronograma
            </Col>
            <Col sm={3}>
            <FormControl.Static>{madurezCronograma}</FormControl.Static>
            </Col>
            </FormGroup>

            <br />
            <b>Cantidad a dar de baja</b>
            <hr />
            <FormGroup controlId="nuevoPerfilPM">
                <Col componentClass={ControlLabel} sm={3}>
                Perfil del Director de Proyecto
                </Col>
                <Col sm={2}>
                <FormControl
                    type="number"
                    step="1"
                    min="0"
                    max={perfilPM}
                    value={nuevoPerfilPm}
                    onChange={ e => setNuevoPerfilPM(e.target.value)}
                >
                </FormControl>
                </Col>
            </FormGroup>

            <FormGroup controlId="nuevoSaludProyecto">
                <Col componentClass={ControlLabel} sm={3}>
                Saludo del Proyecto
                </Col>
                <Col sm={2}>
                <FormControl
                    type="number"
                    step="1"
                    min="0"
                    max={saludProyecto}
                    value={nuevoSaludProyecto}
                    onChange={ e => setNuevoSaludProyecto(e.target.value)}
                >
                </FormControl>
                </Col>
            </FormGroup>

            <FormGroup controlId="nuevoMadurezCronograma">
                <Col componentClass={ControlLabel} sm={3}>
                Madurez del Cronograma
                </Col>
                <Col sm={2}>
                <FormControl
                    type="number"
                    step="1"
                    min="0"
                    max={madurezCronograma}
                    value={nuevoMadurezCronograma}
                    onChange={ e => setNuevoMadurezCronograma(e.target.value)}
                >
                </FormControl>
                </Col>
            </FormGroup>

            <hr />
            <FormGroup controlId="descripcion">
            <ControlLabel>Descripción</ControlLabel>
            <FormControl
                componentClass="textarea"
                value={descripcion}
                onChange={ e=> setDescripcion(e.target.value)}
            />
            </FormGroup>
            
            <LoaderButton
                block
                bsSize="large"
                className="button-orange"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Dar de Baja
            </LoaderButton>
            </Form>
            )}
        </div>
    )
}