import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import { useHistory } from "react-router-dom";
import API from "../../api";
import "./ConsultaTipoEvaluaciones.css"

export default function ConsultaTipoEvaluaciones() {
    const [tipoEvaluaciones, setTipoEvaluaciones] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useEffect(()=>{
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }

            try {
                const result = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(result.data.data);    
            } catch(e) {
                onError(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated]);

    function renderTipoEvaluacionesList(tipoEvaluaciones) {
        return [{}].concat(tipoEvaluaciones).map((tipoEvaluacion, i) => 
            i !==0 ? (
                <LinkContainer key={tipoEvaluacion._id} to={`/tipoEvaluacion/${tipoEvaluacion._id}`}>
                    <ListGroupItem header={tipoEvaluacion.nombre.trim().split("\n")[0]}>
                        {"Creado: " + new Date(tipoEvaluacion.createdAt).toLocaleString()}
                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to="/tipoEvaluacion/new">
                    <ListGroupItem>
                    <h4>
                    <b>{"\uFF0B"}</b> Crear tipo de evaluación
                    </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    function renderTipoEvaluaciones() {
        return (
            <div className="TipoEvaluaciones">
            <PageHeader>Tipos de Evaluación</PageHeader>
            <ListGroup>
            {!isLoading && renderTipoEvaluacionesList(tipoEvaluaciones)}
            </ListGroup>
            </div>
        );
    }

    return (
        <div>
        {isAuthenticated
            ? renderTipoEvaluaciones() 
            : history.push("/")}
        </div>
    );
}