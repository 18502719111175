import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  Checkbox,
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaProducto.css";

export default function ConsultaProducto() {
  const { id } = useParams();
  const history = useHistory();
  const [producto, setProducto] = useState(null);
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [tipoProducto, setTipoProducto] = useState("");
  const [url, setUrl] = useState("");
  const [precio, setPrecio] = useState(0);
  const [porcentajeIva, setPorcentajeIva] = useState(0);
  const [crearMoodle, setCrearMoodle] = useState(false);
  const [tipoProductos, setTipoProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    async function onLoad() {
      try {
        const tipoProductos = await API.getTipoProductoActivo();
        setTipoProductos(tipoProductos.data.data);

        const producto = await API.getProductoById(id);
        const {
          nombre,
          descripcion,
          url,
          precio,
          porcentajeIva,
          crearMoodle,
          tipoProducto,
        } = producto.data.data;
        setNombre(nombre);
        setDescripcion(descripcion);
        setTipoProducto(tipoProducto);
        setUrl(url);
        setPrecio(precio);
        setPorcentajeIva(porcentajeIva === undefined ? 0 : porcentajeIva);
        setProducto(producto);
        setCrearMoodle(crearMoodle === undefined ? false : crearMoodle);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return (
      nombre.length > 0 &&
      descripcion.length > 0 &&
      url.length > 0 &&
      tipoProducto
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (precio === "") {
      alert("Ingrese el precio del producto");
    }

    setIsLoading(true);
    try {
      const payload = {
        nombre,
        descripcion,
        url,
        precio,
        porcentajeIva,
        crearMoodle,
        tipoProducto,
      };
      await API.updateProducto(id, payload);
      history.push("/producto");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();
    const confirmed = window.confirm("¿Dese borrar este producto?");
    if (!confirmed) {
      return;
    }

    setIsDeleting(true);
    try {
      await API.deactivateProducto(id);
      history.push("/producto");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  function handleCheck(event) {
    let moodle = crearMoodle;
    moodle = !moodle;
    setCrearMoodle(moodle);
  }

  function handleSelectTipoProducto(event) {
    const tipoProducto = event.target.value;
    setTipoProducto(tipoProducto);
  }

  return (
    <div className="Producto">
      {producto && (
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="id">
            <ControlLabel>Id</ControlLabel>
            <FormControl.Static>{id}</FormControl.Static>
          </FormGroup>

          <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              autoComplete="off"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </FormGroup>

          <FormGroup controlId="descripcion">
            <ControlLabel>Descripcion</ControlLabel>
            <FormControl
              componentClass="textarea"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
          </FormGroup>

          <FormGroup controlId="tipoProductos">
            <ControlLabel>Tipo de Producto</ControlLabel>
            <FormControl
              placeholder="select"
              componentClass="select"
              value={tipoProducto}
              onChange={handleSelectTipoProducto}
            >
              <option value="">Elija un tipo de producto</option>
              {tipoProductos.map((tipoProducto) => {
                return (
                  <option key={tipoProducto._id} value={tipoProducto._id}>
                    {tipoProducto.nombre}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>

          <FormGroup controlId="url">
            <ControlLabel>Url</ControlLabel>
            <FormControl
              type="text"
              autoComplete="off"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </FormGroup>

          <FormGroup controlId="precio">
            <ControlLabel>Precio</ControlLabel>
            <InputGroup>
              <InputGroup.Addon>$</InputGroup.Addon>
              <FormControl
                type="decimal"
                step="1"
                min="0"
                max="100"
                autoComplete="off"
                value={precio}
                onChange={(e) => setPrecio(e.target.value)}
              />
            </InputGroup>
          </FormGroup>

          <FormGroup controlId="iva">
            <ControlLabel>IVA</ControlLabel>
            <InputGroup>
              <FormControl
                type="decimal"
                step="1"
                min="0"
                max="100"
                autoComplete="off"
                value={porcentajeIva}
                onChange={(e) => setPorcentajeIva(e.target.value)}
              />
              <InputGroup.Addon>%</InputGroup.Addon>
            </InputGroup>
          </FormGroup>

          <FormGroup controlId="total">
            <ControlLabel>Valor total</ControlLabel>
            <FormControl.Static>
              ${" "}
              {(
                Number(precio) +
                (Number(precio) * Number(porcentajeIva)) / 100
              ).toFixed(2)}
            </FormControl.Static>
          </FormGroup>

          <FormGroup controlId="crearMoodle">
            <Checkbox checked={crearMoodle} onChange={handleCheck}>
              Crear usuario de Moodle
            </Checkbox>
          </FormGroup>

          <LoaderButton
            block
            bsSize="large"
            className="button-orange"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Guardar
          </LoaderButton>

          <LoaderButton
            block
            bsSize="large"
            bsStyle="danger"
            onClick={handleDelete}
            isLoading={isLoading}
          >
            Borrar
          </LoaderButton>
        </form>
      )}
    </div>
  );
}
