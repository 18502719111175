import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, InputGroup, Checkbox } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import "./CreaProducto.css";

export default function CreaProducto() {
    const history = useHistory();
    const [ nombre, setNombre ] = useState("");
    const [ descripcion, setDescripcion ] = useState("");
    const [ tipoProducto, setTipoProducto ] = useState("");
    const [ url, setUrl ] = useState("");
    const [ precio, setPrecio] = useState(0);
    const [ porcentajeIva, setPorcentajeIva ] = useState(0);
    const [ crearMoodle, setCrearMoodle ] = useState(false);
    const [ tipoProductos, setTipoProductos ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const { isAuthenticated } = useAppContext();

    useEffect(()=> {
        async function onLoad() {
            try {
                const tipoProductos = await API.getTipoProductoActivo();
                setTipoProductos(tipoProductos.data.data);
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, []);

    function validateForm() {
        return (nombre.length > 0 &&
                descripcion.length > 0 &&
                url.length > 0 );
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (precio === "") {
            alert("Ingrese el precio del producto");
            return;
        }

        setIsLoading(true);
        try {
            const payload = { nombre, descripcion, url, precio, porcentajeIva, activo: true, crearMoodle, tipoProducto };
            await API.createProducto(payload);
            history.push("/");
        } catch(e) {
            onError(e);
            isLoading(false);
        }
    }

    function handleCheck(event) {
        let moodle = crearMoodle;
        moodle = !moodle;
        setCrearMoodle(moodle);
    }

    function handleSelectTipoProducto(event) {
        const tipoProducto = event.target.value;
        setTipoProducto(tipoProducto);
    }

    return (
        <div className="NewProducto">
            <form onSubmit={handleSubmit}>
            <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl 
                autoFocus
                type="text"
                autoComplete="off"
                value={nombre}
                onChange={ e => setNombre(e.target.value)}
            />
            </FormGroup>

            <FormGroup controlId="descripcion">
            <ControlLabel>Descripción</ControlLabel>
            <FormControl 
                componentClass="textarea"
                value={descripcion}
                onChange={ e => setDescripcion(e.target.value)}
            />
            </FormGroup>

            <FormGroup controlId="tipoProductos">
            <ControlLabel>Tipo de Producto</ControlLabel>
            <FormControl
                placeholder="select"
                componentClass="select"
                onChange={handleSelectTipoProducto}
            >
                {
                    tipoProductos.map((tipoProducto) => {
                        return (
                            <option
                                key={tipoProducto._id}
                                value={tipoProducto._id}
                            >
                                {tipoProducto.nombre}
                            </option>
                        )
                    })
                }
            </FormControl>
            </FormGroup>

            <FormGroup controlId="url">
            <ControlLabel>Url</ControlLabel>
            <FormControl 
                type="text"
                autoComplete="off"
                value={url}
                onChange={ e => setUrl(e.target.value)}
            />
            </FormGroup>

            <FormGroup controlId="precio">
            <ControlLabel>Precio</ControlLabel>
            <InputGroup>
            <InputGroup.Addon>$</InputGroup.Addon>
            <FormControl 
                type="decimal"
                step="1"
                min="0"
                max="100"
                autoComplete="off"
                value={precio}
                onChange={ e => setPrecio(e.target.value) }
            />
            </InputGroup>
            </FormGroup>

            <FormGroup controlId="iva">
            <ControlLabel>IVA</ControlLabel>
            <InputGroup>
            <FormControl
                type="decimal"
                step="1"
                min="0"
                max="100"
                autoComplete="off"
                value={porcentajeIva}
                onChange={e => setPorcentajeIva(e.target.value)}
            />
            <InputGroup.Addon>%</InputGroup.Addon>
            </InputGroup>
            </FormGroup>

            <FormGroup controlId="total">
            <ControlLabel>Valor total</ControlLabel>
            <FormControl.Static>$ {(Number(precio)+ (Number(precio)*Number(porcentajeIva)/100)).toFixed(2)}</FormControl.Static>
            </FormGroup>

            <FormGroup controlId="crearMoodle">
            <Checkbox
                checked={crearMoodle}
                onChange={handleCheck}
            >Crear usuario de Moodle</Checkbox>
            </FormGroup>

            <LoaderButton
                block
                type="submit"
                bsSize="large"
                className="button-orange"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Guardar
            </LoaderButton>
            </form>
        </div>
    );

}

