import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, InputGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaSemaforo.css";

export default function ConsultaSemaforo() {
    const { id } = useParams();
    const history = useHistory();
    const [ semaforo, setSemaforo ] = useState(null);
    const [ nombre, setNombre ] = useState("");
    const [ tipoEvaluacion, setTipoEvaluacion ] = useState("");
    const [ desdePorcentaje, setDesdePorcentaje ] = useState(0);
    const [ hastaPorcentaje, setHastaPorcentaje ] = useState(0);
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    useEffect(() => {
        async function onLoad() {
            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);

                const semaforo = await API.getSemaforoById(id);
                const { nombre, tipoEvaluacion, desdePorcentaje, hastaPorcentaje } = semaforo.data.data;
                setNombre(nombre);
                setTipoEvaluacion(tipoEvaluacion);
                setDesdePorcentaje(desdePorcentaje);
                setHastaPorcentaje(hastaPorcentaje);
                setSemaforo(semaforo);
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    function validateForm() {
        return (nombre.length > 0 && tipoEvaluacion);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (desdePorcentaje === "" || hastaPorcentaje === "") {
            alert("Ingrese un valor numérico para los rangos de valores");
            return;
        }

        setIsLoading(true);
        try {
            const payload = { nombre, tipoEvaluacion, desdePorcentaje, hastaPorcentaje, activo: true };
            await API.updateSemaforo(id, payload);
            history.push("/semaforo");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleDelete(event) {
        event.preventDefault();
        const confirmed = window.confirm("¿Desea borrar este semáforo?");
        if (!confirmed) {
            return;
        }
        
        setIsDeleting(true);
        try {
            await API.deactivateSemaforo(id);
            history.push("/semaforo");
        } catch(e) {
            onError(e);
            setIsDeleting(false);
        }
    }

    /*
    async function handleKeyDown(event) {
        event.preventDefault();
        let currentValue = event.target.value;
        let length = event.target.value.length;

        if (event.keyCode === 8 ) {
            event.target.value = length > 0 ? currentValue.substring(0, length -1) : "";
        } else if (!isNaN(Number(event.key))) {
            event.target.value = currentValue === "0" ? event.key : currentValue += event.key;                       
        }
    } */

    return (
        <div className="Semaforo">
            { semaforo && (
            <form onSubmit={handleSubmit}>
            <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl
                autoFocus
                type="text"
                autoComplete="off"
                value={nombre}
                onChange={ e => setNombre(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="tipoEvaluacion">
            <ControlLabel>Tipo de Evaluación</ControlLabel>
            <FormControl
                componentClass="select"
                placeholder="select"
                value={tipoEvaluacion}
                onChange={ e => setTipoEvaluacion(e.target.value) }
            >
                <option value="">Elija un tipo de evaluación</option>
                {
                    tipoEvaluaciones && tipoEvaluaciones.map((tipoEvaluacion) => {
                        return (
                            <option
                                key={tipoEvaluacion._id}
                                value={tipoEvaluacion._id}
                            >
                            {tipoEvaluacion.nombre}
                            </option>
                        )
                    })
                }
            </FormControl>
            </FormGroup>
            <FormGroup controlId="desdePorcentaje">
            <ControlLabel>Desde</ControlLabel>
            <InputGroup>
            <FormControl
                type="number"
                step="1"
                min="0"
                max="100"
                value={desdePorcentaje}
                onChange= { e => setDesdePorcentaje(e.target.value) }
            />
            <InputGroup.Addon>%</InputGroup.Addon>
            </InputGroup>
            </FormGroup>
            <FormGroup controlId="hastaPorcentaje">
            <ControlLabel>Hasta</ControlLabel>
            <InputGroup>
            <FormControl
                type="number"
                step="1"
                min="0"
                max="100"
                value={hastaPorcentaje}
                onChange= { e => setHastaPorcentaje(e.target.value) }
            />
            <InputGroup.Addon>%</InputGroup.Addon>
            </InputGroup>
            </FormGroup>
            <LoaderButton
                block
                bsSize="large"
                className="button-orange"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Guardar
            </LoaderButton>
            <LoaderButton
                block
                bsSize="large"
                bsStyle="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
            >
                Borrar
            </LoaderButton>
            </form>
            )}
        </div>
    );
}