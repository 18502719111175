import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaTipoHabilidad.css";

export default function ConsultaTipoHabilidad() {
    const { id } = useParams();
    const history = useHistory();
    const [ tipoHabilidad, setTipoHabilidad ] = useState(null);
    const [ nombre, setNombre ] = useState("");
    const [ descripcion, setDescripcion ] = useState("");
    const [ tipoEvaluacion, setTipoEvaluacion ] = useState("");
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    useEffect(() => {
        async function onLoad() {
            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);

                const tipoHabilidad = await API.getTipoHabilidadById(id);
                const { nombre, descripcion, tipoEvaluacion } = tipoHabilidad.data.data;
                setNombre(nombre);
                setDescripcion(descripcion);
                setTipoEvaluacion(tipoEvaluacion);
                setTipoHabilidad(tipoHabilidad);
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    function validateForm() {
        return (nombre.length > 0 && tipoEvaluacion);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const payload = { nombre, descripcion, tipoEvaluacion, activo: true };
            await API.updateTipoHabilidad(id, payload);
            history.push("/tipoHabilidad");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleDelete(event) {
        event.preventDefault();
        const confirmed = window.confirm("¿Desea borrar este tipo de habilidad?");
        if (!confirmed) {
            return;
        }
        setIsDeleting(true);

        try {
            await API.deactivateTipoHabilidad(id);
            history.push("/tipoHabilidad");
        } catch(e) {
            onError(e);
            setIsDeleting(false);
        }
    }

    return (
        <div className="TipoHabilidad">
        { tipoHabilidad && (
            <form onSubmit={handleSubmit}>
            <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl
                autoFocus
                type="text"
                autoComplete="off"
                value={nombre}
                onChange={ e => setNombre(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="descripcion">
            <ControlLabel>Descripcion</ControlLabel>
            <FormControl
                componentClass="textarea"
                value={descripcion}
                onChange={ e => setDescripcion(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="tipoEvaluacion">
            <ControlLabel>Tipo de Evaluación</ControlLabel>
            <FormControl
                componentClass="select"
                placeholder="select"
                value={tipoEvaluacion}
                onChange={ e => setTipoEvaluacion(e.target.value) }
            >
                <option value="">Elija un tipo de evaluación</option>
                {
                    tipoEvaluaciones && tipoEvaluaciones.map((tipoEvaluacion) => {
                        return (
                            <option
                                key={tipoEvaluacion._id}
                                value={tipoEvaluacion._id}
                            >
                            {tipoEvaluacion.nombre}
                            </option>
                        )
                    })
                }
            </FormControl>
            </FormGroup>
            <LoaderButton
                block
                bsSize="large"
                className="button-orange"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Guardar
            </LoaderButton>
            <LoaderButton
                block
                bsSize="large"
                bsStyle="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
            >
                Borrar
            </LoaderButton>
            </form>
        )}
        </div>
    );
}