import React from "react";
import "./Home.css";


export default function Home() {
    return (
        <div className="Home">
            <div className="lander">
            <img src="/groupx3.png" alt="Escuela de Proyectos" />
            </div>
        </div>
    );
}