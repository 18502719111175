import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import { useAppContext } from "../../libs/contextLib";
import "./ConsultaCertificados.css";

export default function ConsultaCertificados() {
    const [ certificados, setCertificados ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const { isAuthenticated } = useAppContext();
    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        async function onLoad() {
            setIsLoading(true);
            try {
                const certificados = await API.getCertificadosSubidos();
                setCertificados(certificados.data.data);
            } catch(e) {
                onError(e);
            }
            setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated]);


    function renderCertificadosList() {
        return [{}].concat(certificados).map((certificado, i) =>
            i !== 0 ? (
                <LinkContainer key={certificado._id} to={`/certificados/${certificado._id}`}>
                    <ListGroupItem header={certificado.nombre}>
                        {`Fecha de subida: ${certificado.fecha.split("T")[0]} - ${certificado.descripcion}`}
                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to="/certificados/upload">
                    <ListGroupItem>
                        <h4>
                        <b>{"\uFF0B"}</b> Subir archivo de certificados
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    function renderCertificados() {
        return (
            <div className="Certificado">
            <PageHeader>Certificados</PageHeader>
            <ListGroup>
            {!isLoading && renderCertificadosList(certificados)}
            </ListGroup>
            </div>
        )
    }

    return (
        <div>
        {isAuthenticated ? renderCertificados() : history.push("/") }
        </div>
    )

}