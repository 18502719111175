import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, PageHeader, Form, Col,
         Radio } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { globalVars } from "../../constants";
import { onError } from "../../libs/errorLib";
import "./Pago.css";

export default function Pago() {
    const history = useHistory();
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const { isAuthenticated, setIsLoadingOverlay, token, setHerramientas } = useAppContext();
    const [ tipoEvaluacion, setTipoEvaluacion ] = useState("");
    const [ codigoDescuento, setCodigoDescuento ] = useState("");
    const [ precio, setPrecio ] = useState(0);
    const [ total, setTotal ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);
    const [ isLoadingDescuento, setIsLoadingDescuento ] = useState(false);

    useEffect(()=> {
        async function onLoad() {
            setIsLoadingData(true);
            try {
                const tipoEvaluacion = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluacion.data.data);
            } catch(e) {
                onError(e);
            }
            setIsLoadingData(false);
        }
        onLoad();

    }, [isAuthenticated]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoadingOverlay(true);
        setIsLoading(true);

        try {
            const detalle = { tipoEvaluacion, tipoProducto: globalVars.TIPO_PRODUCTO_HERRAMIENTA_ID };
            const payload = { detalle: [detalle], codigo: codigoDescuento };
            const result = await API.saveCompra(payload, { headers: { "Authorization": token  }});
            setHerramientas(result.data.herramientas);

            switch (tipoEvaluacion) {
                case globalVars.EVALUACION_PM_ID:
                    history.push("/perfilDirectorProyecto");
                    break;

                case globalVars.SALUD_PROYECTO_ID:
                    history.push("/saludProyecto");
                    break;
                
                case globalVars.MADUREZ_CRONOGRAMA_ID:
                    history.push("/madurezCronograma");
                    break;
            
                default:
                    history.push("/");
                    break;
            }
        } catch(e) {
            onError(e);
        }
        setIsLoading(false);
        setIsLoadingOverlay(false);
    }

    async function handleAplicarDescuento(event) {
        event.preventDefault();

        setIsLoadingOverlay(true);
        setIsLoadingDescuento(true);

        try {
            const detalle = { tipoEvaluacion, tipoProducto: globalVars.TIPO_PRODUCTO_HERRAMIENTA_ID };
            const payload = { codigo: codigoDescuento, detalle: [detalle] };
            const result = await API.aplicarDescuento(payload, { headers: { "Authorization": token  }});
            const { total } = result.data.data;
            setTotal(Number(total));
        } catch(e) {
            onError(e);
        }

        setIsLoadingDescuento(false);
        setIsLoadingOverlay(false);
    }

    function validateForm() {
        return (tipoEvaluacion && total === 0);
    }

    function validateAplicarDescuento() {
        return (tipoEvaluacion && codigoDescuento.length > 0);
    }

    function handleCheck(event, id) {
        setTipoEvaluacion(id);
        const evaluacion = tipoEvaluaciones.filter(item =>  item._id === id );
        setPrecio(Number(evaluacion[0].precio));
        setTotal(Number(evaluacion[0].precio));
    }

    return (
        <div className="Pago">
            <PageHeader>Adquiere tu Herramienta</PageHeader>

            { !isLoadingData && (
            <Form  onSubmit={handleSubmit}>
            <FormGroup controlId="tipoEvaluacion">
            <ControlLabel>Elija el tipo de evaluación</ControlLabel>
            {
                tipoEvaluaciones.map(tipo => {
                    return (
                        <Radio name="group" key={tipo._id}
                            onClick={ e=> handleCheck(e, tipo._id)}
                        >
                            {tipo.nombre}
                        </Radio>
                    )
                })
            }

            </FormGroup>

            <FormGroup>
            <ControlLabel>Valor de la herramienta</ControlLabel>
            <FormControl.Static>$ {precio.toFixed(2)}</FormControl.Static>
            </FormGroup>

            <FormGroup control="codigoDescuento">
            <ControlLabel>Ingrese su código de descuento</ControlLabel>
            </FormGroup>

            <FormGroup controlId="descuento">
                <Col sm={5}>
                <FormControl 
                    type="text" 
                    autoComplete="off" 
                    value={codigoDescuento} 
                    onChange={ e => setCodigoDescuento(e.target.value) }
                />
                </Col>
               <Col sm={3}>
                <LoaderButton
                    block
                    className="button-orange"
                    onClick={handleAplicarDescuento}
                    isLoading={isLoadingDescuento}
                    disabled={!validateAplicarDescuento()}
                >
                    Aplicar descuento
                </LoaderButton>
               </Col>
            </FormGroup>

            <br/><br />
            <FormGroup>
            <ControlLabel>Valor a pagar</ControlLabel>
            <FormControl.Static>$ {total.toFixed(2)}</FormControl.Static>
            </FormGroup>

            <LoaderButton
                block
                bsSize="large"
                className="button-orange"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Adquirir
            </LoaderButton>
            </Form>
            )}

        </div>

    )

}
