const url = "https://api.escueladeproyectos.dipromacom.net:8443";
// const url = "http://localhost:8001";

export const globalVars = {
  API: `${url}/api`,
  EVALUACION_PM_ID: "5ebdfe906979ed44465ce655",
  MADUREZ_CRONOGRAMA_ID: "5ebe0019222ef0602bf484f6",
  SALUD_PROYECTO_ID: "5ebdffc8222ef0602bf484f4",
  EVALUACION_PM_CONDUCTUAL_ID: "652315648c3bcc75410938b1",
  ESTILO_LIDERAZGO_TAREA_PERSONA_ID: "65245f1bc1ad6fef358dce33",
  ESTILO_LIDERAZGO_ID: "65246786ef896d0bf2c46b92",
  PRACTICA_GERENCIAL_ID: "655386af101945efe04b8762",
  RESOLUCION_PROBLEMA_ID: "6554cdb178bdf9d4931c5947",
  PERSONA_ID: "5ecf0ed7e49dc90963b3d5e6",
  URL: `${url}/pdf-evaluaciones/`,
  MAX_ATTACHMENT_SIZE: 5000000,
  CERTIFICADO_CORPORATIVO_ID: "5f4549288c62f429c84af41a",
  CERTIFICADO_WEBINAR_ID: "5f4549598c62f429c84af41b",
  CERTIFICADO_INTERNACIONAL_ID: "5f45496c8c62f429c84af41c",
  CERTIFICADO_WORKSHOP_ID: "5f9266369384067d518b8c63",
  TIPO_PRODUCTO_HERRAMIENTA_ID: "5f873f9529c31a35d667c621",
  TIPO_PRODUCTO_CURSO_ID: "5f873f8029c31a35d667c620",
  TIPO_PRODUCTO_LIBRO_ID: "66215abd81ac795dc2fafe40",
};

export const messages = {
  TERMS_AND_CONDICTIONS:
    "Autorizo el uso de los datos e información suministrada en la encuesta para uso investigativo anónimo y proceso de contratación en direccion de proyectos",
};
