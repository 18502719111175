import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import { globalVars } from "../../constants"
import API from "../../api";
import "./SubirCertificado.css";


export default function GenerarCerticados() {
    const file = useRef(null);
    const history = useHistory();
    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [isLoading, setIsLoading ] = useState(false);
    const { token } = useAppContext();

    function validateForm() {
        return  nombre.length > 0 &&
                descripcion.length > 0;
    }

    function handleFileChange(event) {
        file.current = event.target.files[0];
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (file.current && file.current.size > globalVars.MAX_ATTACHMENT_SIZE) {
            alert("Por favor suba un archivo de menor tamaño");
            return;
        }

        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append('nombre', nombre);
            formData.append('descripcion', descripcion);
            formData.append('listado', file.current);
            await API.subirCertificados(formData, { headers: { "Authorization": token  }});
            history.push("/certificados");
        } catch(e) {
            onError(e);
        }
        setIsLoading(false);
    }

    return (
        <div className="GenerarCertificados">
        <form onSubmit={handleSubmit}>
            <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl
                type="text"
                autoComplete="off"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
            />
            </FormGroup>

            <FormGroup controlId="descripcion">
            <ControlLabel>Descripción</ControlLabel>
            <FormControl
                componentClass="textarea"
                value={descripcion}
                onChange={e => setDescripcion(e.target.value)}
            />
            </FormGroup>

            <FormGroup controlId="file">
                <ControlLabel>Listado</ControlLabel>
                <FormControl onChange={handleFileChange} type="file" />
            </FormGroup>

            <LoaderButton
                block
                type="submit"
                bsSize="large"
                className="button-orange"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Generar
            </LoaderButton>
        </form>
        </div>
    );
}