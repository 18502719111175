import React, { useState, useEffect} from "react";
import { useParams, useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaNivelAcuerdo.css";

export default function ConsultaNivelAcuerdo() {
    const { id } = useParams();
    const history = useHistory();
    const [ nombre, setNombre ] = useState("");
    const [ peso, setPeso ] = useState(0);
    const [ nivelAcuerdo, setNivelAcuerdo ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    useEffect(() => {
        async function onLoad() {
            try {
                const nivelAcuerdo = await API.getNivelAcuerdoById(id);
                const { nombre, peso } = nivelAcuerdo.data.data;
                setNombre(nombre);
                setPeso(peso);
                setNivelAcuerdo(nivelAcuerdo);
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    function validateForm(){
        return (nombre.length > 0 && peso >= 0);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (peso === "") {
            alert("Ingrese un valor numérico el peso");
            return;
        }

        setIsLoading(true);
        try {
            const payload = { nombre, peso, activo: true };
            await API.updateNivelAcuerdo(id, payload);
            history.push("/nivelAcuerdo");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleDelete(event) {
        event.preventDefault();
        
        const confirmed = window.confirm("¿Desea borrar el nivel de acuerdo?");
        if (!confirmed) {
            return;
        }

        setIsDeleting(true);
        try {
            await API.deactivateNivelAcuerdo(id);
            history.push("/nivelAcuerdo");
        } catch(e) {
            onError(e);
            setIsDeleting(true);
        }
    }

    return (
        <div className="NivelAcuerdo">
        { nivelAcuerdo && (
            <form onSubmit={handleSubmit}>
            <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl 
                autoFocus
                type="text"
                autoComplete="off"
                value={nombre}
                onChange={ e => setNombre(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="peso">
            <ControlLabel>Peso</ControlLabel>
            <FormControl
                type="number"
                step="1"
                min="0"
                max="5"
                value={peso}
                onChange= { e => setPeso(e.target.value) }
            />
            </FormGroup>
            <LoaderButton
                block
                type="submit"
                bsSize="large"
                bsStyle="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Guardar
            </LoaderButton>
            <LoaderButton
                block
                bsSize="large"
                bsStyle="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
            >
                Borrar
            </LoaderButton>
            </form>
        )}
        </div>
    )
}