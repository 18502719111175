import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, InputGroup } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import API from "../../api";
import "./CreaSemaforo.css";

export default function CreaSemaforo() {
    const history = useHistory();
    const [ nombre, setNombre ] = useState("");
    const [ tipoEvaluacion, setTipoEvaluacion ] = useState("");
    const [ desdePorcentaje, setDesdePorcentaje ] = useState(0);
    const [ hastaPorcentaje, setHastaPorcentaje ] = useState(0);
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const { isAuthenticated } = useAppContext();


    useEffect(()=> {
        async function onLoad() {
            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, [isAuthenticated]);

    function validateForm() {
        return (nombre.length > 0 && tipoEvaluacion);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (desdePorcentaje === "" || hastaPorcentaje === "") {
            alert("Ingrese un valor numérico para los rangos de valores");
            return;
        }
        
        setIsLoading(true);
        try {
            const payload = { nombre, tipoEvaluacion, desdePorcentaje, hastaPorcentaje, activo: true };
            await API.createSemaforo(payload);
            history.push("/semaforo");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }

    return (
        <div className="NewSemaforo">
        {tipoEvaluaciones && (
            <form onSubmit={handleSubmit} >
            <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl 
                autoFocus
                type="text"
                autoComplete="off"
                value={nombre}
                onChange={ e => setNombre(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="tipoEvaluacion">
            <ControlLabel>Tipo de Evaluación</ControlLabel>
            <FormControl 
                placeholder="select"
                componentClass="select"
                onChange={ e=> setTipoEvaluacion(e.target.value)}>
                <option value="">Elija un tipo de evaluación</option>
                { 
                    tipoEvaluaciones.map((tipoEvaluacion) => {
                        return (
                            <option 
                                key={tipoEvaluacion._id}
                                value={tipoEvaluacion._id}
                            >
                                {tipoEvaluacion.nombre}
                            </option>);
                    })
                }
            </FormControl>
            </FormGroup>
            <FormGroup controlId="desdePorcentaje">
            <ControlLabel>Desde</ControlLabel>
            <InputGroup>
            <FormControl
                type="number"
                step="1"
                min="0"
                max="100"
                value={desdePorcentaje}
                onChange= { e => setDesdePorcentaje(e.target.value) }
            />
            <InputGroup.Addon>%</InputGroup.Addon>
            </InputGroup>
            </FormGroup>
            <FormGroup controlId="hastaPorcentaje">
            <ControlLabel>Hasta</ControlLabel>
            <InputGroup>
            <FormControl
                type="number"
                step="1"
                min="0"
                max="100"
                value={hastaPorcentaje}
                onChange= { e => setHastaPorcentaje(e.target.value) }
            />
            <InputGroup.Addon>%</InputGroup.Addon>
            </InputGroup>
            </FormGroup>
            <LoaderButton
                block
                type="submit"
                bsSize="large"
                className="button-orange"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Guardar
            </LoaderButton>
            </form>
        )}
        </div>
    );

}