import React, { useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { CardElement, injectStripe } from "react-stripe-elements";
import LoaderButton from "./LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./BillingForm.css";

function BillingForm({ isLoading, onSubmit, ...props }) {
    const [ fields, handleFieldChange ] = useFormFields({
        name: "",
        items: ""
    });

    const [ isProcessing, setIsProcessing ] = useState(false);
    const [ isCardComplete, setIsCardComplete ] = useState(false);

    isLoading = isProcessing || isLoading;

    function validateForm() {
        return (
            fields.name !== "" &&
            fields.items !== "" &&
            isCardComplete
        );
    }

    async function handleSubmitClick(event) {
        event.preventDefault();

        setIsProcessing(true);

        const { token, error } = await props.stripe.createToken({ name: fields.name });

        setIsProcessing(false);

        onSubmit(fields.items, { token, error });

    }

    return (
        <form className="BillingForm" onSubmit={handleSubmitClick}>
        <FormGroup bsSize="large" controlId="items">
        <ControlLabel>Encuestas</ControlLabel>
        <FormControl
            min="0"
            type="number"
            value={fields.items}
            onChange={handleFieldChange}
            placeholder="Ingrese el número de encuestas a pagar"
        />
        </FormGroup>
        <hr />
        <FormGroup bsSize="large" controlId="name">
        <ControlLabel>Nombre del tarjeta habiente</ControlLabel>
        <FormControl
            type="text"
            autoComplete="off"
            value={fields.name}
            onChange={handleFieldChange}
            placeholder="Nombre en la tarjeta"
        />
        </FormGroup>
        <ControlLabel>Detalle de la tarjeta</ControlLabel>
        <CardElement
            className="card-field"
            onChange={ e => setIsCardComplete(e.complete) }
            style={
                { base: { fontSize: "18px", fontFamily: '"Open Sans", sans-serif'}}
            }
            hidePostalCode={true}
        />
        <LoaderButton
            block
            type="submit"
            bsSize="large"
            isLoading={isLoading}
            disabled={!validateForm()}
        >
            Pagar
        </LoaderButton>
        </form>
    );
}

export default injectStripe(BillingForm);