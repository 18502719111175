import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem, FormGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaNivelesMadurez.css";

export default function ConsultaNivelesMadurez() {
    const [ nivelesMadurez, setNivelesMadurez ] = useState([]);
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [ isLoadingTipoEvaluacion, setIsLoadingTipoEvaluacion ] = useState(true);
    const [ isLoadingNivelMadurez, setIsLoadingNivelMadurez ] = useState(true);
    const history = useHistory();

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }

            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);
            } catch(e) {
                onError(e);
            }

            setIsLoadingTipoEvaluacion(false);
            setIsLoadingNivelMadurez(false);
        }

        onLoad();
    }, [isAuthenticated]);

    async function handleSelectTipoEvaluacion(event) {
        const tipoEvaluacion = event.target.value;

        setIsLoadingNivelMadurez(true);
        try {
            const nivelesMadurez = await API.getNivelMadurezByTipoEvaluacion(tipoEvaluacion);
            setNivelesMadurez(nivelesMadurez.data.data);
        } catch(e) {
            setNivelesMadurez([]);
        }
        setIsLoadingNivelMadurez(false);
    }

    function renderNivelMadurezList(nivelesMadurez) {
        return [{}].concat(nivelesMadurez).map((nivelMadurez, i) => 
            i !== 0 ? (
                <LinkContainer key={nivelMadurez._id} to={`/nivelMadurez/${nivelMadurez._id}`}>
                    <ListGroupItem header={nivelMadurez.nombre}>
                        Desde: {nivelMadurez.desdePuntos} Hasta: {nivelMadurez.hastaPuntos}
                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to={`/nivelMadurez/new`}>
                    <ListGroupItem>
                        <h4>
                        <b>{"\uFF0B"}</b> Crear nivel de madurez
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    function renderNivelesMadurez() {
        return (
            <div className="NivelesMadurez">
            <PageHeader>Niveles de Madurez</PageHeader>
            <FormGroup controlId="tipoEvalucion">
            <FormControl 
                componentClass="select"
                placeholder="select"
                onChange={handleSelectTipoEvaluacion}
            >
                <option value="0">Elija un tipo de evaluación</option>
                {!isLoadingTipoEvaluacion && tipoEvaluaciones.map(tipoEvaluacion => {
                    return (
                        <option key={tipoEvaluacion._id} value={tipoEvaluacion._id}>
                            {tipoEvaluacion.nombre}
                        </option>
                    );
                })}
            </FormControl>
            </FormGroup>
            <ListGroup>
            {!isLoadingNivelMadurez && renderNivelMadurezList(nivelesMadurez)}
            </ListGroup>
            </div>
        );
    }

    return (
        <div>
        {isAuthenticated ? renderNivelesMadurez() : history.push("/") }
        </div>
    );
}