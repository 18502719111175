import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, InputGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaNivelMadurez.css";

export default function ConsultaNivelMadurez() {
    const { id } = useParams();
    const history = useHistory();
    const [ nivelMadurez, setNivelMadurez ] = useState(null);
    const [ nombre, setNombre ] = useState("");
    const [ paradigma, setParadigma ] = useState("");
    const [ descripcion, setDescripcion ] = useState("");
    const [ tipoEvaluacion, setTipoEvaluacion ] = useState("");
    const [ desdePuntos, setDesdePuntos ] = useState(0);
    const [ hastaPuntos, setHastaPuntos ] = useState(0);
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    useEffect(() => {
        async function onLoad() {
            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);

                const nivelMadurez = await API.getNivelMadurezById(id);
                const { nombre, descripcion, tipoEvaluacion, desdePuntos, hastaPuntos, paradigma } = nivelMadurez.data.data;
                setNombre(nombre);
                setDescripcion(descripcion);
                setTipoEvaluacion(tipoEvaluacion);
                setDesdePuntos(desdePuntos);
                setHastaPuntos(hastaPuntos);
                setNivelMadurez(nivelMadurez);
                setParadigma(paradigma);
            } catch(e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    function validateForm() {
        return (nombre.length > 0 && tipoEvaluacion);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        
        if (desdePuntos === "" || hastaPuntos === "") {
            alert("Ingrese un valor numérico para los rangos de valores");
            return;
        }

        setIsLoading(true);
        try {
            const payload = { nombre, descripcion, tipoEvaluacion, desdePuntos, hastaPuntos, paradigma, activo: true };
            await API.updateNivelMadurez(id, payload);
            history.push("/nivelMadurez");
        } catch(e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleDelete(event) {
        event.preventDefault();
        const confirmed = window.confirm("¿Desea borrar este nivel de madurez?");
        if (!confirmed) {
            return;
        }
        setIsDeleting(true);

        try {
            await API.deactivateNivelMadurez(id);
            history.push("/nivelMadurez");
        } catch(e) {
            onError(e);
            setIsDeleting(false);
        }
    }

    return (
        <div className="NivelMadurez">
            { nivelMadurez && (
            <form onSubmit={handleSubmit}>
            <FormGroup controlId="nombre">
            <ControlLabel>Nombre</ControlLabel>
            <FormControl
                autoFocus
                type="text"
                autoComplete="off"
                value={nombre}
                onChange={ e => setNombre(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="paradigma">
            <ControlLabel>Paradigma</ControlLabel>
            <FormControl
                autoFocus
                type="text"
                autoComplete="off"
                value={paradigma}
                onChange={ e => setParadigma(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="descripcion">
            <ControlLabel>Descripcion</ControlLabel>
            <FormControl 
                value={descripcion}
                componentClass="textarea"
                onChange={ e => setDescripcion(e.target.value) }
            />
            </FormGroup>
            <FormGroup controlId="tipoEvaluacion">
            <ControlLabel>Tipo de Evaluación</ControlLabel>
            <FormControl
                componentClass="select"
                placeholder="select"
                value={tipoEvaluacion}
                onChange={ e => setTipoEvaluacion(e.target.value) }
            >
                <option value="">Elija un tipo de evaluación</option>
                {
                    tipoEvaluaciones && tipoEvaluaciones.map((tipoEvaluacion) => {
                        return (
                            <option
                                key={tipoEvaluacion._id}
                                value={tipoEvaluacion._id}
                            >
                            {tipoEvaluacion.nombre}
                            </option>
                        )
                    })
                }
            </FormControl>
            </FormGroup>
            <FormGroup controlId="desdePuntos">
            <ControlLabel>Desde</ControlLabel>
            <InputGroup>
            <FormControl
                type="number"
                step="1"
                min="0"
                max="1000"
                value={desdePuntos}
                onChange= { e => setDesdePuntos(e.target.value) }
            />
            <InputGroup.Addon>Puntos</InputGroup.Addon>
            </InputGroup>
            </FormGroup>
            <FormGroup controlId="hastaPuntos">
            <ControlLabel>Hasta</ControlLabel>
            <InputGroup>
            <FormControl
                type="number"
                step="1"
                min="0"
                max="1000"
                value={hastaPuntos}
                onChange= { e => setHastaPuntos(e.target.value) }
            />
            <InputGroup.Addon>Puntos</InputGroup.Addon>
            </InputGroup>
            </FormGroup>
            <LoaderButton
                block
                bsSize="large"
                className="button-orange"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Guardar
            </LoaderButton>
            <LoaderButton
                block
                bsSize="large"
                bsStyle="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
            >
                Borrar
            </LoaderButton>
            </form>
            )}
        </div>
    );
}