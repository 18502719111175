import React, { useState, useEffect } from "react";
import {
  PageHeader,
  ListGroup,
  ListGroupItem,
  FormGroup,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaProductos.css";

export default function ConsultaProductos() {
  const [productos, setProductos] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoadingProductos, setIsLoadingProductos] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const productos = await API.getProductoActivo();
        console.log(productos.data.data);
        setProductos(productos.data.data);
      } catch (e) {
        onError(e);
      }
      setIsLoadingProductos(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function renderProductoList(productos) {
    return [{}].concat(productos).map((producto, i) =>
      i !== 0 ? (
        <LinkContainer key={producto._id} to={`/producto/${producto._id}`}>
          <ListGroupItem header={producto.nombre}>
            {producto.descripcion}
          </ListGroupItem>
        </LinkContainer>
      ) : (
        <LinkContainer key="new" to={`/producto/new`}>
          <ListGroupItem>
            <h4>
              <b>{"\uFF0B"}</b> Crear Producto
            </h4>
          </ListGroupItem>
        </LinkContainer>
      )
    );
  }

  function renderProducto() {
    return (
      <div className="Productos">
        <PageHeader>Productos</PageHeader>
        {!isLoadingProductos && renderProductoList(productos)}
      </div>
    );
  }

  return <div>{isAuthenticated ? renderProducto() : history.push("/")}</div>;
}
