import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { useAppContext } from "../../libs/contextLib";
import API from "../../api";
import { globalVars } from "../../constants";
import "./AsignarDescuento.css";

export default function AsignarDescuento() {
  const history = useHistory();
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [codigo, setCodigo] = useState("");
  const [porcentaje, setPorcentaje] = useState("");
  const [tipoEvaluacion, setTipoEvaluacion] = useState("");
  const [tipoEvaluaciones, setTipoEvaluaciones] = useState([]);
  const [tipoProductos, setTipoProductos] = useState([]);
  const [tipoProducto, setTipoProducto] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAppContext();

  useEffect(() => {
    async function onLoad() {
      try {
        const tipoProductos = await API.getTipoProductoActivo();
        setTipoProductos(tipoProductos.data.data);

        const tipoProducto = tipoProductos.data.data[0]._id;
        setTipoProducto(tipoProducto);

        let tipoEvaluaciones;
        if (tipoProducto === globalVars.TIPO_PRODUCTO_HERRAMIENTA_ID) {
          tipoEvaluaciones = await API.getTipoEvaluacionActivo();
        } else {
          tipoEvaluaciones = await API.getProductoByTipoProductoId(
            tipoProducto
          );
        }
        setTipoEvaluaciones(tipoEvaluaciones.data.data);

        const result = await API.getCodigoDescuento({
          headers: { Authorization: token },
        });
        const codigo = result.data.data.codigo;
        setCodigo(codigo);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [token]);

  function validateForm() {
    return (
      nombre.length > 0 &&
      apellido.length > 0 &&
      correo.length > 0 &&
      codigo.length > 0 &&
      tipoProducto &&
      tipoEvaluacion &&
      porcentaje
    );
  }

  async function handleSelectTipoProducto(event) {
    const tipoProducto = event.target.value;
    setTipoProducto(tipoProducto);
    setTipoEvaluacion("");

    let tipoEvaluaciones;
    if (tipoProducto === globalVars.TIPO_PRODUCTO_HERRAMIENTA_ID) {
      tipoEvaluaciones = await API.getTipoEvaluacionActivo();
    } else {
      tipoEvaluaciones = await API.getProductoByTipoProductoId(tipoProducto);
    }
    setTipoEvaluaciones(tipoEvaluaciones?.data?.data);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (porcentaje === "") {
      alert("Ingrese un valor para el porcentaje de descuento");
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        nombre,
        apellido,
        correo,
        codigo,
        porcentaje,
        tipoProducto,
        herramienta: tipoEvaluacion,
      };
      await API.saveDescuentoNewUser(payload, {
        headers: { Authorization: token },
      });
      alert(
        "Descuento generado. Dentro de unos segundos el cliente recibirá la confirmación."
      );
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="AsignarDescuento">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="nombre">
          <ControlLabel>Nombre</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            autoComplete="off"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
        </FormGroup>

        <FormGroup controlId="apellido">
          <ControlLabel>Apellido</ControlLabel>
          <FormControl
            type="text"
            autoComplete="off"
            value={apellido}
            onChange={(e) => setApellido(e.target.value)}
          />
        </FormGroup>

        <FormGroup controlId="apellido">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            autoComplete="off"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
          />
        </FormGroup>

        <FormGroup controlId="codigo">
          <ControlLabel>Código de descuento</ControlLabel>
          <FormControl.Static>{codigo}</FormControl.Static>
        </FormGroup>

        <FormGroup controlId="tipoProductos">
          <ControlLabel>Tipo de Producto</ControlLabel>
          <FormControl
            placeholder="select"
            componentClass="select"
            onChange={handleSelectTipoProducto}
          >
            {tipoProductos.map((tipoProducto) => {
              return (
                <option key={tipoProducto._id} value={tipoProducto._id}>
                  {tipoProducto.nombre}
                </option>
              );
            })}
          </FormControl>
        </FormGroup>

        <FormGroup controlId="tipoEvaluacion">
          <ControlLabel>Producto</ControlLabel>
          <FormControl
            placeholder="select"
            componentClass="select"
            onChange={(e) => setTipoEvaluacion(e.target.value)}
          >
            <option value="">Elija un producto</option>
            {tipoEvaluaciones.map((tipoEvaluacion) => {
              return (
                <option key={tipoEvaluacion._id} value={tipoEvaluacion._id}>
                  {tipoEvaluacion.nombre}
                </option>
              );
            })}
          </FormControl>
        </FormGroup>

        <FormGroup controlId="desdePuntos">
          <ControlLabel>Porcentaje de descuento</ControlLabel>
          <InputGroup>
            <FormControl
              type="number"
              step="1"
              min="0"
              max="100"
              value={porcentaje}
              onChange={(e) => setPorcentaje(e.target.value)}
            />
            <InputGroup.Addon>%</InputGroup.Addon>
          </InputGroup>
        </FormGroup>

        <LoaderButton
          block
          type="submit"
          bsSize="large"
          className="button-orange"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Asignar código
        </LoaderButton>
      </form>
    </div>
  );
}
