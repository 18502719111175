import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem, FormGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import API from "../../api";
import { onError } from "../../libs/errorLib";
import "./ConsultaSemaforos.css";

export default function ConsultaSemaforos() {
    const [ semaforos, setSemaforos ] = useState([]);
    const [ tipoEvaluaciones, setTipoEvaluaciones ] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [ isLoadingTipoEvaluacion, setIsLoadingTipoEvaluacion ] = useState(true);
    const [ isLoadingSemaforo, setIsLoadingSemaforo ] = useState(true);
    const history = useHistory();

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }

            try {
                const tipoEvaluaciones = await API.getTipoEvaluacionActivo();
                setTipoEvaluaciones(tipoEvaluaciones.data.data);
            } catch(e) {
                onError(e);
            }

            setIsLoadingTipoEvaluacion(false);
            setIsLoadingSemaforo(false);
        }

        onLoad();
    }, [isAuthenticated]);

    async function handleSelectTipoEvaluacion(event) {
        const tipoEvaluacion = event.target.value;

        setIsLoadingSemaforo(true);
        try {
            const semaforos = await API.getSemaforoByTipoEvaluacion(tipoEvaluacion);
            setSemaforos(semaforos.data.data);
        } catch(e) {
            setSemaforos([]);
        }
        setIsLoadingSemaforo(false);
    }

    function renderSemaforoList(semaforos) {
        return [{}].concat(semaforos).map((semaforo, i) => 
            i !== 0 ? (
                <LinkContainer key={semaforo._id} to={`/semaforo/${semaforo._id}`}>
                    <ListGroupItem header={semaforo.nombre}>
                        Desde: {semaforo.desdePorcentaje}% Hasta: {semaforo.hastaPorcentaje}%
                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to={`/semaforo/new`}>
                    <ListGroupItem>
                        <h4>
                        <b>{"\uFF0B"}</b> Crear semáforo
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    function renderSemaforo() {
        return (
            <div className="Semaforos">
            <PageHeader>Semáforos</PageHeader>
            <FormGroup controlId="tipoEvalucion">
            <FormControl 
                componentClass="select"
                placeholder="select"
                onChange={handleSelectTipoEvaluacion}
            >
                <option value="0">Elija un tipo de evaluación</option>
                {!isLoadingTipoEvaluacion && tipoEvaluaciones.map(tipoEvaluacion => {
                    return (
                        <option key={tipoEvaluacion._id} value={tipoEvaluacion._id}>
                            {tipoEvaluacion.nombre}
                        </option>
                    );
                })}
            </FormControl>
            </FormGroup>
            <ListGroup>
            {!isLoadingSemaforo && renderSemaforoList(semaforos)}
            </ListGroup>
            </div>
        );
    }

    return (
        <div>
        {isAuthenticated ? renderSemaforo() : history.push("/") }
        </div>
    );
}